<template>
  <span>
    <Tippy
      v-if="datetime"
      :content="dayjs(datetime).format('DD/MM/YYYY HH:mm:ss')"
    >
      {{ convertedDateAgo }}
    </Tippy>
    <Tippy v-else :content="i18n.t('services.no_data')"> - </Tippy>
  </span>
</template>

<script>
import dayjs from "dayjs";

require("dayjs/locale/it");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default {
  name: "DateAgo",
  props: {
    datetime: {
      type: [String, Object],
      required: true,
    },
    locale: {
      type: String,
      default: "it",
    },
    refresh: {
      type: [Number, Boolean],
      default: 5,
    },
  },
  data() {
    return {
      convertedDateAgo: "",
      intervalId: null,
      dayjs,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.reloadTime();
      if (this.refresh) {
        const refreshTime = this.refresh === true ? 60 : this.refresh;
        this.intervalId = setInterval(this.reloadTime, this.refresh * 1000);
      }
    });
  },
  destroyed() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    reloadTime() {
      console.log("Reload time");
      if (this.datetime)
        this.convertedDateAgo = dayjs().locale(this.locale).to(this.datetime);
      else this.convertedDateAgo = "-";
    },
  },
};
</script>
