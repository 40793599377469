<template>
  <div class="intro-y">
    <div
      class="box py-4 mr-1 mb-3 items-center cursor-pointer bg-gray-400 rounded-none"
    >
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip_summary.start_day") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(total.start.message_datetime).format("DD/MM/YYYY") }}
        </span>
      </div>
      <div class="font-medium flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip_summary.start_time") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(total.start.message_datetime).format("HH:mm") }}
        </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip_summary.end_day") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(total.end.message_datetime).format("DD/MM/YYYY") }}
        </span>
      </div>
      <div class="font-medium flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip_summary.end_time") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(total.end.message_datetime).format("HH:mm") }}
        </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2 text-right">
          {{ i18n.t("services.eyegeo.trip.start") }}
        </span>
        <span class="px-2 text-right"> {{ first_position }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2 text-right">
          {{ i18n.t("services.eyegeo.trip.end") }}
        </span>
        <span class="px-2 text-right"> {{ last_position }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.duration") }}
        </span>
        <span class="px-2 text-right"> {{ duration }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.stop_duration") }}
        </span>
        <span class="px-2 text-right"> {{ stop_duration }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2"> {{ i18n.t("services.eyegeo.trip.miliage") }} </span>
        <span class="px-2 text-right"> {{ miliage }} </span>
      </div>
      <!--<div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2"> {{ i18n.t("services.eyegeo.trip.total") }} </span>
        <span class="px-2 text-right">
          {{ trips ? trips.length : "N/a" }}
        </span>
      </div>-->
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref, computed } from "vue";
import { useI18n } from "vue3-i18n";
import dayjs from "dayjs";
import { geoReverseSearch } from "@/services/geocoder";

export default defineComponent({
  props: {
    trips: {
      type: Object,
      required: true,
    },
    vehicle: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const duration = ref(0);
    const stop_duration = ref(0);
    const miliage = ref(0);
    const total = ref(null);
    const last_position = ref(null);
    const first_position = ref(null);

    watch(() => props, init, { deep: true });

    async function init() {
      const has_eyegeo_geocoding = props.vehicle.active_agreements.find(
        (o) => o.agreement_id == 25
      );

      total.value = {
        start: null,
        end: null,
        stop_duration: 9999,
        duration: 9999,
        miliage: 9999,
      };

      if (props.trips) {
        total.value.start = props.trips[props.trips.length - 1].start;
        total.value.end = props.trips[0].end;
        total.value.duration = props.trips.reduce(function (p, c) {
          return p + parseInt(c.duration);
        }, 0);
        total.value.stop_duration = props.trips.reduce(function (p, c) {
          return p + parseInt(c.stop_duration);
        }, 0);
        total.value.miliage = props.trips.reduce(function (p, c) {
          return p + parseInt(c.miliage);
        }, 0);
      }

      if (total.value.start) {
        if (total.value.start_position) {
          first_position.value = total.value.start_position;
        } else {
          if (has_eyegeo_geocoding) {
            await new Promise((resolve) =>
              setTimeout(resolve, Math.random() * 10 + 1000)
            );
            let result = await geoReverseSearch(
              total.value.start.latitude,
              total.value.start.longitude
            );
            if (result) {
              first_position.value = result.display_name;
            }
          } else {
            first_position.value =
              "lat: " +
              normalize(total.value.start.latitude) +
              ", lng:" +
              normalize(total.value.start.longitude);
          }
        }
      }

      if (total.value.end) {
        if (total.value.end_position) {
          last_position.value = total.value.end_position;
        } else {
          if (has_eyegeo_geocoding) {
            await new Promise((resolve) =>
              setTimeout(resolve, Math.random() * 10 + 1000)
            );
            let result = await geoReverseSearch(
              total.value.end.latitude,
              total.value.end.longitude
            );
            if (result) {
              last_position.value = result.display_name;
            }
          } else {
            last_position.value =
              "lat: " +
              normalize(total.value.end.latitude) +
              ", lng:" +
              normalize(total.value.end.longitude);
          }
        }
      }

      if (total.value.duration <= 60) {
        duration.value = i18n.t("services.eyegeo.trip.less_minute");
      } else if (total.value.duration <= 120) {
        duration.value = i18n.t("services.eyegeo.trip.minute");
      } else if (total.value.duration < 3600) {
        duration.value =
          Math.floor(total.value.duration / 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else if (total.value.duration < 7200) {
        duration.value = "1 " + i18n.t("services.eyegeo.trip.hour") + " e ";
        duration.value +=
          Math.floor(total.value.duration / 60 - 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else {
        duration.value =
          Math.floor(total.value.duration / 3600) +
          " " +
          i18n.t("services.eyegeo.trip.hours") +
          " e ";
        duration.value +=
          Math.floor(total.value.duration / 60) -
          Math.floor(total.value.duration / 3600) * 60 +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      }
      if (total.value.stop_duration <= 60) {
        stop_duration.value = i18n.t("services.eyegeo.trip.less_minute");
      } else if (total.value.stop_duration <= 120) {
        stop_duration.value = i18n.t("services.eyegeo.trip.minute");
      } else if (total.value.stop_duration < 3600) {
        stop_duration.value =
          Math.floor(total.value.stop_duration / 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else if (total.value.stop_duration < 7200) {
        stop_duration.value =
          "1 " + i18n.t("services.eyegeo.trip.hour") + " e ";
        stop_duration.value +=
          Math.floor(total.value.stop_duration / 60 - 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else {
        stop_duration.value =
          Math.floor(total.value.stop_duration / 3600) +
          " " +
          i18n.t("services.eyegeo.trip.hours") +
          " e ";
        stop_duration.value +=
          Math.floor(total.value.stop_duration / 60) -
          Math.floor(total.value.stop_duration / 3600) * 60 +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      }
      if (total.value.miliage < 0.001) {
        miliage.value = i18n.t("services.eyegeo.trip.less_meter");
      } else if (total.value.miliage < 1) {
        miliage.value =
          Math.floor(total.value.miliage * 1000) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      } else if (total.value.miliage < 2) {
        miliage.value = "1 " + i18n.t("services.eyegeo.trip.km") + " e ";
        miliage.value +=
          Math.floor((total.value.miliage - 1) * 1000) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      } else {
        miliage.value =
          Math.floor(total.value.miliage) +
          " " +
          i18n.t("services.eyegeo.trip.kms") +
          " e ";
        miliage.value +=
          Math.floor(
            (total.value.miliage - Math.floor(total.value.miliage)) * 1000
          ) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      }
    }

    function normalize(coord) {
      return [parseFloat(coord)];
    }

    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }

    init();
    return {
      i18n,
      dayjs,
      duration,
      stop_duration,
      miliage,
      last_position,
      first_position,
      makeMapsUrl,
      emit,
      total,
    };
  },
});
</script>
