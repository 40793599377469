<template>
  <div v-if="vehicle" id="service-eyegeo">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyegeo.last_position") }}
        </div>
        <div v-if="data.position">
          <DateAgo :datetime="data.position.message_datetime"></DateAgo>
        </div>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <LastPosition :vehicle="vehicle" :position="data.position"></LastPosition>

      <div class="text-md capitalize">
        {{ i18n.t("services.eyegeo.last_trip") }}
      </div>
      <TripItem
        :selected="
          selected_trip && data.trip && selected_trip.id == data.trip.id
        "
        :vehicle="vehicle"
        :item="data.trip"
      ></TripItem>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyegeo.positions_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory">
        <div v-if="history && history.list">
          <div class="text-md capitalize">
            {{ i18n.t("services.eyegeo.history_summary") }}
          </div>
          <TripSummary :trips="history.list" :vehicle="vehicle"></TripSummary>
          <div class="w-full my-5">
            <div class="form-check ml-5 justify-between">
              <label class="form-check-label" for="short_trips"
                >visualizza viaggi corti
              </label>
              <input
                id="short_trips"
                v-model="short_trips"
                class="form-check-switch"
                type="checkbox"
              />
            </div>
          </div>

          <div class="max-h-80 overflow-y-auto">
            <div v-for="h in trips" :key="h.id">
              <TripItem
                :selected="selected_trip && h.id == selected_trip.id"
                :vehicle="vehicle"
                :item="h"
                @click="handleHistoryClick(h)"
              ></TripItem>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div
        class="btn btn-primary rounded-full w-full mt-5"
        @click="requestReport"
      >
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, createApp } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import TripSummary from "@/components/map/TripSummary.vue";
import TripItem from "@/components/map/TripItem.vue";
import LastPosition from "@/components/map/LastPosition.vue";
import PopupPointRenderer from "@/components/popup-rendering/PopupPointRenderer.vue";
import { helper as $h } from "@/utils/helper";

// eslint-disable-next-line vue/one-component-per-file
export default {
  components: {
    TripSummary,
    TripItem,
    LastPosition,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const selected_trip = ref(null);
    const data = ref(null);
    const history = ref([]);
    const trips = ref([]);
    const loadingHistory = ref(false);
    const short_trips = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );
    watch(short_trips, () => {
      getHistory(false);
    });

    function updatePosition(position) {
      if (data.value == null) return;
      data.value.position = position;
      selected_trip.value = data.value.trip;
    }

    EventBus.on("vehicle:updates:position", updatePosition);

    function updateTrip(trip) {
      if (data.value == null) return;
      if (selected_trip.value == data.value.trip) {
        data.value.trip = trip;
        selected_trip.value = data.value.trip;
        handleHistoryClick(data.value.trip);
      } else {
        data.value.trip = trip;
        selected_trip.value = data.value.trip;
      }
    }

    EventBus.on("vehicle:updates:trip", updateTrip);

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyegeo/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      selected_trip.value = data.value.trip;
      handleHistoryClick(data.value.trip);
      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyegeo", "eyegeo");
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory(update = true) {
      if (!props.active) return;

      loadingHistory.value = true;
      if (update) {
        const historyResult = await store.dispatch(
          "eyegeo/getVehicleTripHistory",
          {
            vehicleId: vehicle.value.id,
            trip: true,
            dateFrom: dayjs(historyFrom.value).format(),
            dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
              ? dayjs().format()
              : dayjs(historyTo.value).format(),
          }
        );
        history.value = historyResult.validated;

        history.value.list.sort(
          (b, a) =>
            dayjs(a.message_datetime) - dayjs(b.message_datetime) || a.id - b.id
        );
      }

      if (!short_trips.value) {
        trips.value = history.value.list.filter(
          (trip) => trip.duration > 180 && trip.miliage > 1
        );
      } else {
        trips.value = history.value.list;
      }

      /*
      mapController.addPath({
        id: history.value.vehicle_id,
        group: "service",
        markers: history.value.list.reverse().map((h, i) => {
          return {
            id: h.id,
            group: "service",
            icon: "dot",
            latitude: h.latitude ?? 0,
            longitude: h.longitude ?? 0,
            datetime: h.message_datetime,
            popup: {
              content: function () {
                var app = createApp(PopupPointRenderer, { point: h })
                  .use(store)
                  .use(i18n);
                const content = document.createElement("div");
                app.mount(content);
                return content;
              },
            },
          };
        }),
      });
*/
      loadingHistory.value = false;
    }

    async function handleHistoryClick(item) {
      mapController.clearGroup("service");
      if (typeof item.positions == "undefined") {
        let trip = await store.dispatch("eyegeo/getVehicleTrip", {
          vehicleId: vehicle.value.id,
          tripId: item.id,
        });
        item = trip.data;
      }

      if (item.polyline) {
        selected_trip.value = item;
        mapController.addPath({
          id: item.id,
          group: "service",
          markers: item.positions
            .sort(
              (a, b) =>
                dayjs(b.message_datetime) - dayjs(a.message_datetime) ||
                b.id - a.id
            )
            .map((m) => {
              return {
                id: m.id,
                group: "service",
                icon: "dot",
                latitude: m.latitude ?? 0,
                longitude: m.longitude ?? 0,
                datetime: m.message_datetime,
                popup: {
                  content: function () {
                    var app = createApp(PopupPointRenderer, { point: m })
                      .use(store)
                      .use(i18n);
                    const content = document.createElement("div");
                    app.mount(content);
                    return content;
                  },
                },
              };
            }),
        });
      }
      mapController.flyToPath(item.id, "service");
    }

    return {
      i18n,
      dayjs,
      h: $h,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
      mapController,
      handleHistoryClick,
      selected_trip,
      short_trips,
      trips,
    };
  },
};
</script>

<style></style>
