<template>
  <div
    class="box flex mb-1 cursor-pointer hover:shadow-md hover:bg-gray-200"
    :class="{
      'border border-primary-1 shadow-md': selected,
    }"
    @click="handleClick"
  >
    <div class="flex justify-start items-center">
      <div class="flex h-full">
        <div
          class="w-3 mr-2 h-full bg-gray-400 text-gray-600 rounded-l-md"
          :class="{
            'bg-reyesol-disconnected': vehicle.status == 0,
            'bg-reyesol-connected': vehicle.status == 1,
            'bg-reyesol-alarm': vehicle.status == 2,
            'bg-reyesol-powersafe': vehicle.status == 3,
            'bg-reyesol-moving': vehicle.status == 4,
          }"
        ></div>
      </div>
      <div
        class="flex grid grid-cols-3 grid-rows my-2 md:grid-cols-5 justify-center items-center"
      >
        <!--Icons-->
        <div class="flex-row row-span-2">
          <Tippy
            tag="img"
            :src="
              require(`@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`)
            "
            class="w-10 h-10 mx-auto"
            :content="i18n.t(`vehicles.types.${vehicle.vehicle_type}`)"
          ></Tippy>
          <!-- fridge icon & status-->
          <div
            class="flex flex-row justify-center px-3 py-1"
            :class="{ invisible: !is_fridge }"
          >
            <Tippy
              tag="img"
              :src="require(`@/assets/images/cold.svg`)"
              class="w-5 h-5 mx-auto text-blue-900 self-center"
              :content="i18n.t(`vehicles.is_fridge.${is_fridge}`)"
            ></Tippy>
            <span
              v-if="
                vehicle.overall_status != null && vehicle.overall_status == 1
              "
              class="ml-1 py-1 px-1 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
            >
              ON
            </span>
            <span
              v-if="
                vehicle.overall_status != null && vehicle.overall_status == 0
              "
              class="ml-1 py-1 px-1 rounded-full text-xs bg-theme-24 text-white cursor-pointer font-medium"
            >
              OFF
            </span>
            <span
              v-if="vehicle.overall_status == null"
              class="invisible ml-1 py-1 px-1 rounded-full text-xs bg-theme-24 text-white cursor-pointer font-medium"
            >
              OFF
            </span>
          </div>
        </div>
        <!--plate-->
        <div class="col-start-2 col-span-2 px-2">
          <div class="text-center">
            <div class="text-left text-md truncate">
              {{ i18n.t("vehicles.plate") }}:<br />
              <span class="font-bold">{{ vehicle.plate }}</span>
            </div>
          </div>
        </div>
        <!--Fleets-->
        <div class="col-start-4 col-span-2 px-2 -ml-8 md:ml-0">
          <div
            :class="{ invisible: vehicle.fleet_id == null }"
            class="text-center"
          >
            <div class="text-left text-md truncate">
              {{ i18n.t("vehicles.fleet") }}:<br />
              <span class="font-bold">{{
                vehicleFleet(vehicle.fleet_id)
              }}</span>
            </div>
          </div>
        </div>
        <!--temperatures-->
        <div
          v-if="
            vehicle.T1 != null && vehicle.fridge_temperature_sensors_number >= 1
          "
          class="col-span-3 col-start-2 row-start-2 flex justify-start items-baseline py-1 px-2"
        >
          <span><ThermometerIcon class="mr-1" /></span>
          <span
            v-if="
              vehicle.T1 != null &&
              vehicle.fridge_temperature_sensors_number >= 1
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            {{ vehicle.T1 }}°C
          </span>
          <span
            v-if="
              vehicle.T2 != null &&
              vehicle.fridge_temperature_sensors_number >= 2
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            {{ vehicle.T2 }}°C
          </span>
          <span
            v-if="
              vehicle.T3 != null &&
              vehicle.fridge_temperature_sensors_number >= 3
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            {{ vehicle.T3 }}°C
          </span>
          <span
            v-if="
              vehicle.T4 != null &&
              vehicle.fridge_temperature_sensors_number >= 4
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            {{ vehicle.T4 }}°C
          </span>
        </div>
        <!--Setpoints-->
        <div
          v-if="
            vehicle.setpoint_t1 != null &&
            vehicle.fridge_compartments_number >= 1
          "
          class="col-start-2 col-span-3 flex justify-start items-baseline py-1 px-2"
        >
          <span><TargetIcon class="mr-1" /></span>
          <span
            v-if="
              vehicle.setpoint_t1 != null &&
              vehicle.fridge_compartments_number >= 1
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t1 }}°C
          </span>
          <span
            v-if="
              vehicle.setpoint_t2 != null &&
              vehicle.fridge_compartments_number >= 2
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t2 }}°C
          </span>
          <span
            v-if="
              vehicle.setpoint_t3 != null &&
              vehicle.fridge_compartments_number >= 3
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t3 }}°C
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";

export default {
  components: {},
  props: {
    vehicle: {
      type: [Object, null],
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const alerts = store.getters["alerts/collection"];
    const fleets = store.getters["fleets/collection"];

    const alert_normal = ref([]);
    const alert_medium = ref([]);
    const alert_high = ref([]);

    let is_fridge = ref(false);

    let has_eyecold = props.vehicle.active_agreements.find(
      (o) => o.agreement_id == 3
    );

    if (has_eyecold) {
      is_fridge.value = true;
    }

    if (props.vehicle.active_alerts) {
      props.vehicle.active_alerts.forEach((element) => {
        let severity = alertSeverity(element);
        if (severity == 0) alert_normal.value.push(alertDescription(element));
        if (severity == 1) alert_medium.value.push(alertDescription(element));
        if (severity == 2) alert_high.value.push(alertDescription(element));
      });
    }

    function alertDescription(alert) {
      let obj = alerts.find((o) => o.id == alert.alert_id);
      return obj.description;
    }
    function alertSeverity(alert) {
      let obj = alerts.find((o) => o.id == alert.alert_id);
      return obj.severity;
    }

    function vehicleFleet(fleet_id) {
      let obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }

    function handleClick() {
      emit("select", props.vehicle);
    }

    return {
      dayjs,
      i18n,
      alert_normal,
      alert_medium,
      alert_high,
      alertSeverity,
      alertDescription,
      handleClick,
      vehicleFleet,
      is_fridge,
    };
  },
};
</script>

<style></style>
