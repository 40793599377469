import { dashboard as dashboardApi } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
    dashboard: [],
  };
};

// getters
const getters = {
  ...g,
  dashboard: (state) => state.dashboard,
};

// actions
const actions = {
  ...a(dashboardApi),
  async getDashboardData({ commit, getters }) {
    const dashboardData = getters.dashboard;
    if (dashboardData.length) return { validated: dashboardData };

    const result = await dashboardApi.getDashboardData();
    result.validated = [];
    if (result.success) {
      const dashboardData = result.data;
      commit("SET_DASHBOARD_DATA", dashboardData);
      result.validated = getters.dashboard;
    }
    return result;
  },
};

// mutations
const mutations = {
  ...m,
  SET_DASHBOARD_DATA(state, dashboard) {
    state.dashboard = dashboard;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
