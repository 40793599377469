import _ from "lodash";

export const state = (order = { key: "id", direction: "desc" }) => {
  return _.cloneDeep({
    order: order,
    collection: [],
    collection_meta: null,
  });
};

export const getters = {
  collection_meta: (state) => state.collection_meta,
  collection: (state) =>
    state.collection.sort((firstEl, secondEl) => {
      if (firstEl[state.order.key] < secondEl[state.order.key]) {
        return state.order.direction == "desc" ? 1 : -1;
      }
      if (firstEl[state.order.key] > secondEl[state.order.key]) {
        return state.order.direction == "desc" ? -1 : 1;
      }
      return 0;
    }),
};

export const actions = (api) => {
  return {
    async getEntity(_, entityId) {
      const result = await api.getEntity(entityId);
      result.validated = null;
      if (result.success) {
        result.validated = result.data;
      }
      return result;
    },
    async getCollection({ commit, getters }, meta) {
      const result = await api.getCollection(meta);
      result.validated = [];
      if (result.success) {
        commit("SET_COLLECTION", result);
        result.validated = getters["collection"];
      }
      return result;
    },
    async store({ commit, getters }, entity) {
      const result = await api.postEntity(entity);
      result.validated = getters["collection"];
      if (result.success) {
        commit("PUSH_ENTITY", result.data);
        result.validated = getters["collection"];
      }
      return result;
    },
    async update({ commit, getters }, entity) {
      const result = await api.putEntity(entity.id, entity);
      result.validated = getters["collection"];
      if (result.success) {
        commit("REMOVE_ENTITY", entity.id);
        commit("PUSH_ENTITY", result.data);
        result.validated = getters["collection"];
      }
      return result;
    },
    async delete({ commit, getters }, entityId) {
      const result = await api.deleteEntity(entityId);
      result.validated = getters["collection"];
      if (result.success) {
        commit("REMOVE_ENTITY", entityId);
        result.validated = getters["collection"];
      }
      return result;
    },
  };
};

export const mutations = {
  SET_COLLECTION(state, result) {
    state.collection = result.data;
    state.collection_meta = result.meta;
  },
  PUSH_ENTITY(state, entity) {
    state.collection.push(entity);
  },
  REMOVE_ENTITY(state, entityId) {
    const i = state.collection.findIndex((e) => e.id == entityId);
    if (i != -1) state.collection.splice(i, 1);
  },
};
