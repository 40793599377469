<template>
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box">
      <div class="box p-5 relative overflow-hidden">
        <component
          :is="icon"
          class="text-theme-5 absolute w-40 h-auto -top-4 right-2 z-0 opacity-20"
        ></component>

        <h3
          class="box-title cursor-pointer text-lg font-semibold"
          @click.stop="handleTitleClick(service)"
        >
          <span class="custom-underline w-full text-left">
            {{ title }}
          </span>
        </h3>

        <div class="grid grid-cols-3 tems-start pt-6">
          <div
            v-for="i in items"
            :key="i.title"
            class="py-8"
            @click.stop="i.count ? handleClick(i) : none"
          >
            <div
              class="relative w-20 mx-auto border-2 rounded-md border-gray-500 border-opacity-0 hover:border-opacity-75"
              :class="{
                'cursor-pointer': i.count > 0,
              }"
            >
              <Tippy
                tag="img"
                class="tooltip w-full p-4"
                :content="i.title"
                :src="require(`@/assets/images/${i.icon}`)"
                :alt="i.title"
              >
              </Tippy>
              <div
                v-if="i.count"
                class="text-lg h-8 w-8 grid place-items-center rounded-full text-white absolute -right-3 -top-3"
                :class="
                  i.severity == 0
                    ? 'bg-blue-500'
                    : i.severity == 1
                    ? 'bg-yellow-500'
                    : 'bg-red-500'
                "
              >
                {{ i.count || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertsBox",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    service: {
      type: String,
      default: "eyegeo",
    },
    tooltip: {
      type: String,
      default: "no data",
    },
    severity: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "TruckIcon",
    },
    items: {
      type: Array,
      default: null,
    },
  },
  setup(_, { emit }) {
    function handleClick(item) {
      emit("click", item);
    }
    function handleTitleClick(s) {
      emit("title_click", s);
    }
    return {
      handleTitleClick,
      handleClick,
    };
  },
};
</script>
<style scoped>
/*--- Red Box (box-e) ---*/

.custom-underline {
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
}
.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: #333;
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position: absolute;
}
.box-title .custom-underline::after {
  top: 80%;
  height: 4px;
  width: 0%;
  left: 0%;
  background-color: rgba(66, 66, 66, 0.3);
  transition: 0.5s ease all 0.3s;
}

.box-title:hover .custom-underline::after {
  width: 98%;
  transition: 0.3s ease all;
}
</style>
