<template>
  <div>
    <div class="mb-2">
      <div class="font-bold text-lg">{{ name }}</div>
      <div class="text-sm text-gray-500">{{ description }}</div>
    </div>
    <div class="flex flex-col space-y-2">
      <div class="flex space-x-1">
        <button
          class="btn btn-primary h-full w-10"
          :disabled="sending"
          @click="increment(-step)"
        >
          -
        </button>
        <div class="input-group flex-1">
          <input
            v-model="state"
            :disabled="sending"
            type="number"
            :min="min"
            :max="max"
            :step="step"
            class="form-control"
          />
          <div v-if="unit" class="input-group-text">{{ unit }}</div>
        </div>
        <button
          class="btn btn-primary h-full w-10"
          :disabled="sending"
          @click="increment(step)"
        >
          +
        </button>
      </div>
      <input
        v-model="state"
        :disabled="sending"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        class="form-control"
      />

      <button class="btn btn-primary" :disabled="sending" @click="trySend()">
        <span v-if="!sending">
          {{ i18n.t("general.send") }}
        </span>
        <span v-else class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
      </button>

      <button
        v-if="canNull"
        class="btn btn-primary"
        :disabled="sending"
        @click="trySend(true)"
      >
        <span v-if="!sending">
          {{ i18n.t("general.remove") }}
        </span>
        <span v-else class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    initialValue: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    canNull: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: null,
    },
    ask: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["send"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const sending = ref(false);
    watch(
      () => props.initialValue,
      (v) => {
        sending.value = false;
        state.value = v;
      }
    );

    const state = ref(props.initialValue);
    watch(state, (v) => {
      if (v < props.min) state.value = props.min;
      if (v > props.max) state.value = props.max;
    });

    const decimals = () => {
      if (Math.floor(props.step.valueOf()) === props.step.valueOf()) return 0;
      return props.step.toString().split(".")[1].length || 0;
    };

    function increment(value) {
      let n = Number.parseFloat(state.value);
      n += value;
      state.value = n.toFixed(decimals());
    }

    function trySend(is_null = false) {
      if (props.ask) {
        EventBus.emit("confirm-dialog:show", {
          title: i18n.t("services.eyecontrol.confirm-title"),
          text: i18n.t("services.eyecontrol.confirm-text"),
          doubleCheck: true,
          doubleCheckText: i18n.t("services.eyecontrol.confirm-toggle-text"),
          callback: async (confirm) => {
            if (confirm) send(is_null);
          },
        });
      } else {
        send(is_null);
      }
    }

    function send(is_null = false) {
      if (is_null) {
        sending.value = false;
        emit("send", null);
      } else {
        if (state.value == null) return;
        sending.value = true;
        emit("send", Number(state.value));
      }
    }

    return { i18n: useI18n(), state, increment, trySend, send, sending };
  },
};
</script>

<style></style>
