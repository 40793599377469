<template>
  <Chart
    type="pie"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { computed, reactive, ref, watch } from "vue";

import { useI18n } from "vue3-i18n";

export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const datasets = ref([]);
    const labels = ref([]);
    const data = reactive({
      labels: computed(() => Array.from(new Set(labels.value))),
      datasets: computed(() => datasets.value),
    });

    function init() {
      labels.value = [];
      datasets.value = [];
      setChartData();
    }
    watch(() => props, init, { deep: true });
    init();

    function setChartData() {
      if (!props.statuses) return;

      let default_bgColors = ["#B22222", "#3CB371", "#FFD700"];
      let default_hoverColors = ["#DC143C", "#228B22", "#F0E68C"];
      let bgColors = [];
      let hoverColors = [];

      const data_status = props.statuses.map((item) => {
        return item.total;
      });

      props.statuses.forEach((item) => {
        bgColors.push(default_bgColors[item.status]);
        hoverColors.push(default_hoverColors[item.status]);
      });

      labels.value = props.statuses
        .map((item) => {
          if (item.status == 0)
            return i18n.t("dashboard.vechicles_status.status.disconnected");
          if (item.status == 1)
            return i18n.t("dashboard.vechicles_status.status.connected");
          if (item.status == 2)
            return i18n.t("dashboard.vechicles_status.status.alarm");
          return;
        })
        .filter((e) => e);

      if (data_status.length > 0) {
        datasets.value.push({
          data: data_status,
          backgroundColor: bgColors,
          hoverBackgroundColor: hoverColors,
          borderWidth: 5,
          borderColor: "#fff",
        });
      }
    }

    const options = {
      legend: {
        display: false,
      },
      onClick: (evt, item) => {
        let index = item[0]["_index"];
        let label = item[0]["_chart"].data.labels[index];
        let data = item[0]["_chart"].data.datasets[0].data[index];

        let selected = props.statuses[index];
        emit("chart_click", { index, label, data, selected });
      },
    };

    return {
      data,
      options,
    };
  },
};
</script>
