<template>
  <div class="lang-switcher-wrap dropdown px-3" :class="{ show: isVisible }">
    <div
      class="lang-switcher dropdown-toggle cursor-pointer flex"
      aria-expanded="false"
      role="button"
      @click="isVisible = true"
    >
      <span class="language flex-col pr-2 w-7"
        ><img :alt="activeLang.name" :src="activeLang.img"
      /></span>
      <span class="flex-col language-name text-white">{{
        activeLang.name
      }}</span>
    </div>
    <div class="pt-2 dropdown-menu">
      <div
        class="dropdown-menu__content box bg-theme-20 dark:bg-dark-6 text-white"
      >
        <div
          v-for="lang in langs"
          :key="lang.code"
          class="p-2 cursor-pointer items-center transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md dropdown-item flex"
          @click="selectLang(lang.code)"
        >
          <span class="flex-col w-5"><img :src="lang.img" alt="" /></span>
          <span class="flex-col pl-2">{{ lang.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  props: {
    langs: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const isVisible = ref(false);

    const activeLang = computed(() => {
      let _activeLang = null;
      for (let lang of props.langs) {
        if (lang.active) {
          _activeLang = lang;
        }
      }
      return _activeLang;
    });

    function selectLang(code) {
      for (let lang of props.langs) {
        lang.active = lang.code == code;
      }
      i18n.setLocale(code);
      store.dispatch("main/setLanguage", code);
    }

    return {
      activeLang,
      i18n,
      selectLang,
      isVisible,
    };
  },
});
</script>
