import mitt from "mitt";
const emitter = mitt();
export default class EventBus {
  static on(eventName, callback) {
    emitter.on(eventName, callback);
  }
  static off(eventName, callback) {
    emitter.off(eventName, callback);
  }
  static emit(eventName, data) {
    emitter.emit(eventName, data);
  }
  static clear() {
    emitter.all.clear();
  }
}
