<template>
  <div class="mt-2 box flex flex-col cursor-pointer" @click="handleClick">
    <div class="flex items-center border-b-2 border-theme-7 mb-2">
      <div class="pl-4">
        <TagIcon class="mr-1" />
        <a href="" class="font-bold flex text-base">{{ poi.name }} </a>
        <div class="text-gray-600 font-thin text-xs">
          {{ poi.address || "Unknown address" }}
          {{ poi.city || "Unknown city" }}
          {{ poi.zip_code || "Unknown zip code" }}
        </div>
      </div>
      <div class="ml-auto mr-5 text-xs">
        <div class="px-2 rounded-full bg-gray-400 text-gray-600">
          {{ poi.code }}
        </div>
      </div>
    </div>
    <div class="pl-4 flex-grow flex items-center">
      <TruckIcon class="mr-1" />
      <div v-if="poi.inside == 0">{{ i18n.t("poi.empty") }}</div>
      <div v-else>{{ poi.inside }} {{ i18n.t("poi.vehicles") }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";

import { TagIcon, TruckIcon } from "@zhuowenli/vue-feather-icons";
import dayjs from "dayjs";

export default {
  components: {
    TruckIcon,
    TagIcon,
  },
  props: {
    poi: {
      type: Object,
      default: null,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const i18n = useI18n();

    function handleClick() {
      emit("select", props.poi);
    }

    return {
      dayjs,
      i18n,
      handleClick,
    };
  },
};
</script>

<style></style>
