import { hubs as api } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
    externalSelected: null,
  };
};

// getters
const getters = {
  ...g,
  externalSelected: (state) => state.externalSelected,
};

// actions
const actions = {
  ...a(api),

  async getVehicles(obj, hubId) {
    const result = await api.getVehicles(hubId);
    result.validated = result.data;
    return result;
  },
  setExternalSelected: ({ commit }, ext) => commit("SET_EXT", ext),
  resetExternalSelected: ({ commit }) => commit("RESET_EXT"),
};

// mutations
const mutations = {
  ...m,

  SET_EXT(state, ext) {
    state.externalSelected = ext;
  },
  RESET_EXT(state) {
    state.externalSelected = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
