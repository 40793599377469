<template>
  <div id="carbontax-show">
    <simple-title>Carbontax</simple-title>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
