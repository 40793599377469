<template>
  <div class="mt-2 box flex flex-col cursor-pointer" @click="handleClick">
    <div class="flex items-center mb-2">
      <div class="pl-4">
        <MapPinIcon class="mr-1" />
        <a href="" class="font-bold flex text-base"
          >{{ dayjs(point.message_datetime).format("DD/MM/YYYY HH:mm:ss") }}
        </a>
      </div>
      <div class="ml-auto mr-5 text-xs text-center">
        <template v-if="point.speed == 0 || point.moving == 0">
          <div class="px-2 rounded-full bg-gray-400 text-gray-600">
            {{ i18n.t("services.eyegeo.status.stopped") }}
          </div>
        </template>
        <template v-else>
          <div class="px-2 rounded-full bg-theme-10 text-white">
            {{ i18n.t("services.eyegeo.status.moving") }}
          </div>
        </template>
      </div>
    </div>
    <div class="flex flex-row justify-between content-center p-4">
      <div class="text-gray-600 font-thin text-xs break-words align-middle">
        {{ last_position }}
      </div>
      <div
        class="my-auto justify-self-end bg-gray-200 p-1 rounded shadow-md hover:bg-gray-400"
      >
        <a
          :href="makeMapsUrl(point.latitude, point.longitude)"
          target="_blank"
          class=""
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-share-2"
          >
            <circle cx="18" cy="5" r="3"></circle>
            <circle cx="6" cy="12" r="3"></circle>
            <circle cx="18" cy="19" r="3"></circle>
            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";

import { geoReverseSearch } from "@/services/geocoder";
import { computed, onMounted, ref, watch } from "vue";
import { MapPinIcon } from "@zhuowenli/vue-feather-icons";
import tippy from "tippy.js";
import { useStore } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    MapPinIcon,
  },
  props: {
    point: {
      type: Object,
      default: null,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    var last_position = ref(null);
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    console.log("PointPopOver", vehicle.value.active_agreements);

    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }

    function normalize(coord) {
      return [parseFloat(coord)];
    }

    async function init() {
      const has_eyegeo_geocoding = vehicle.value.active_agreements.find(
        (o) => o.agreement_id == 25
      );
      console.log("has_eyegeo_geocoding", has_eyegeo_geocoding);
      if (!last_position.value) {
        if (has_eyegeo_geocoding) {
          const result = await geoReverseSearch(
            props.point.latitude,
            props.point.longitude
          );
          if (!result) return;

          last_position.value = result.display_name;
        } else {
          last_position.value =
            "lat: " +
            normalize(props.point.latitude) +
            ", long:" +
            normalize(props.point.longitude);
        }
      }
    }

    watch(() => props, init, { deep: true });

    const i18n = useI18n();

    function handleClick() {
      emit("select", props.point);
    }
    init();
    return {
      vehicle,
      dayjs,
      i18n,
      handleClick,
      makeMapsUrl,
      last_position,
    };
  },
};
</script>

<style></style>
