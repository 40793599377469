<template>
  <div id="active_alerts">
    <div class="flex justify-between items-center">
      <simple-title>{{ i18n.t("general.alerts") }}</simple-title>
    </div>

    <div>
      <div
        v-for="a in alerts"
        :key="a.unit_id"
        class="cursor-pointer relative mt-5 py-2 hover:bg-gray-200 rounded-md"
        @click="goToVehicle(a)"
      >
        <div class="grid grid-cols-6">
          <div class="col-span-1 flex items-center justify-center">
            <component :is="getIcon(a)" />
          </div>
          <div class="col-span-3">
            <div class="font-medium">{{ getDescription(a) }}</div>
            <div class="text-gray-600 text-xs mt-0.5">
              {{ dayjs(a.created_at).format("DD/MM/YYYY hh:mm:ss") }}
            </div>
          </div>
          <div class="col-span-2 flex items-center justify-center">
            {{ getVehiclePlate(a) }}
          </div>
        </div>
      </div>
    </div>

    <pagination :meta="alerts_meta" class="mx-20 mt-10" @page="selectPage" />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";
import { useRouter } from "vue-router";
import dayjs from "dayjs";

import pagination from "@/components/pagination/Main.vue";
export default {
  components: {
    pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const alerts = computed(() => store.getters["alerts/active"]);
    const alerts_meta = computed(() => store.getters["alerts/active_meta"]);

    const alert_descriptions = store.getters["alerts/collection"];

    onMounted(() => store.dispatch("globalMap/getPins"));

    function goToAlerts() {}
    function goToVehicle(active_alert) {
      store.dispatch("globalMap/clear");
      store.dispatch("globalMap/setExternalVehicle", {
        id: active_alert.pivot.vehicle_id,
      });
      router.push({
        path: "/global-map",
        query: { v: active_alert.pivot.vehicle_id },
      });
    }
    function getDescription(active_alert) {
      let alert = alert_descriptions.find(
        (o) => o.id === active_alert.alert_id
      );
      return alert.description;
    }
    function getVehiclePlate(active_alert) {
      const pins = store.getters["globalMap/pins"];
      let vehicle = pins.find(
        (o) => o.vehicle_id === active_alert.pivot.vehicle_id
      );
      if (vehicle) return vehicle.plate;
      else return "N/A";
    }
    function getIcon(active_alert) {
      let alert = alert_descriptions.find(
        (o) => o.id === active_alert.alert_id
      );

      switch (alert.severity) {
        case 1:
          return "AlertTriangleIcon";
        case 2:
          return "AlertCircleIcon";

        default:
          return "AlertTriangleIcon";
      }
    }

    function selectPage(page) {
      store.dispatch("alerts/getActive", { page: page });
      console.log("selectPage: ", page);
    }

    return {
      i18n: useI18n(),
      dayjs,
      alerts,
      alerts_meta,
      getIcon,
      getDescription,
      getVehiclePlate,
      goToVehicle,
      goToAlerts,
      selectPage,
    };
  },
};
</script>
