<template>
  <div id="fleets-index">
    <div class="flex justify-between py-2">
      <simple-title>{{ i18n.t("navigation.fleets") }}</simple-title>

      <div class="flex items-center">
        <div
          v-if="fleets && fleets.length > 0"
          class="hidden md:block mx-auto text-gray-600"
        >
          Visualizzate {{ fleets.length }} flotte
        </div>
        <h2 v-else>
          {{ i18n.t("fleets.empty") }}
        </h2>
      </div>

      <div class="flex items-center">
        <button class="btn btn-secondary mr-1" @click="exampleImport">
          {{ i18n.t("general.example-import-xlsx") }}
        </button>
        <label class="btn btn-primary">
          <loading-icon
            v-if="importing"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.import-xlsx") }}
          <input type="file" class="hidden" @input="handleImport" />
        </label>
      </div>
    </div>

    <div class="flex flex-wrap-reverse">
      <!-- fleets -->
      <div class="w-full lg:w-1/2">
        <!-- new fleet -->
        <button class="btn btn-primary w-full mb-3" @click="addNewFleet">
          <div class="w-10 h-10 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">{{ i18n.t("fleets.add") }}</div>
          </div>
        </button>

        <!-- fleets -->
        <FleetCard
          v-for="fleet in fleets"
          :key="fleet.id"
          :active="activeFleet && fleet.id == activeFleet.id"
          :entity="fleet"
          :vehicles="getFleetVehicles(fleet.id)"
          @updated="updateFleets"
          @updated:vehicles="fetchVehicles()"
          @selected="handleFleetSelect"
          @canceled="handleFleetCancel"
        />
      </div>

      <!-- vehicles -->
      <div class="w-full mb-6 lg:w-1/2 lg:pl-8">
        <div class="py-4 text-xl mb-3">
          <div class="h-10 flex items-center">
            {{ i18n.t("fleets.drag-n-drop") }}
          </div>
        </div>
        <div
          class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 border-2 rounded-lg p-4"
        >
          <div v-if="!getUnassignedVehicles().length" class="text-sm">
            {{ i18n.t("fleets.no-vehicles") }}
          </div>
          <VehicleCard
            v-for="vehicle in getUnassignedVehicles()"
            :key="vehicle.vehicle_id"
            :entity="vehicle"
            class="mr-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import VehicleCard from "./VehicleCard.vue";
import FleetCard from "./FleetCard.vue";
import { xlsxToArray, arrayToXlsx } from "@/utils/parse-xlsx";
import EventBus from "../../libs/event-bus";
import { useRouter } from "vue-router";

export default {
  components: { VehicleCard, FleetCard },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      await fetchFleets();

      if ("f" in router.currentRoute.value.query) {
        findAndSelectFleet(router.currentRoute.value.query.f);
        return;
      }

      const sf = store.getters["fleets/externalSelected"];
      if (sf) findAndSelectFleet(sf.id);
      store.dispatch("fleets/resetExternalSelected");
    });

    watch(
      () => store.getters["fleets/externalSelected"],
      (sf) => {
        if (!sf) return;
        findAndSelectFleet(sf.id);
        store.dispatch("fleets/resetExternalSelected");
      }
    );

    // fetch fleets
    const fleets = ref([]);

    async function fetchFleets() {
      const response = await store.dispatch("fleets/getCollection");
      fleets.value = response.validated;
    }

    function updateFleets(c) {
      fleets.value = c;
    }

    // active fleet
    const activeFleet = ref(null);

    function handleFleetSelect(fleet) {
      selectFleet(fleet);
    }

    function handleFleetCancel() {
      activeFleet.value = null;
      fleets.value = fleets.value.filter((f) => f.id);
    }

    function findAndSelectFleet(fleetId) {
      const fleet = fleets.value.find((f) => f.id == fleetId);
      selectFleet(fleet);
    }

    function selectFleet(fleet) {
      activeFleet.value = fleet;
    }

    // new fleet
    function addNewFleet() {
      if (activeFleet.value && !activeFleet.value.id) return;
      fleets.value = [
        {
          code: null,
          description: null,
          color: "#000000",
        },
        ...fleets.value,
      ];
      activeFleet.value = fleets.value[0];
    }

    // import fleets
    const importing = ref(false);
    async function handleImport(e) {
      importing.value = true;
      const files = e.target.files;
      if (files.length) {
        const data = await xlsxToArray(files[0]);
        if (data.length) await storeImport(data);
      }
      importing.value = false;
    }

    async function exampleImport() {
      arrayToXlsx([
        ["Code", "Description", "Color"],
        ["Fleet A", "01", "#FF0000"],
      ]);
    }

    async function storeImport(data) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("fleets.confirm-import-title")}`,
        callback: async (confirm) => {
          if (confirm) {
            for (let i = 0; i < data.length; i++) {
              const result = await store.dispatch("fleets/store", data[i]);
              if (result.success && result.validated.length) {
                updateFleets(result.validated);
              }
            }
          }
        },
      });
    }

    // fetch vehicles
    const vehicles = ref([]);

    async function fetchVehicles() {
      const response = await store.dispatch("globalMap/getPins");
      vehicles.value = response.validated;
    }
    fetchVehicles();

    function getFleetVehicles(fleetId) {
      return vehicles.value.filter((v) => v.fleet_id == fleetId);
    }

    function getUnassignedVehicles() {
      return vehicles.value.filter(
        (v) => !fleets.value.map((f) => f.id).includes(v.fleet_id)
      );
    }

    return {
      i18n,

      //import
      importing,
      exampleImport,
      handleImport,

      //fleets
      fleets,
      updateFleets,
      activeFleet,
      handleFleetSelect,
      handleFleetCancel,

      // new fleet
      addNewFleet,

      //vehicles
      vehicles,
      fetchVehicles,
      getFleetVehicles,
      getUnassignedVehicles,
    };
  },
};
</script>

<style></style>
