<template>
  <div id="users-index">
    <div class="flex justify-between py-2">
      <search-title class="flex-1" @search="updateSearchQuery">{{
        i18n.t("navigation.users")
      }}</search-title>
      <div class="flex items-center mt-5">
        <div
          v-if="filteredUsers && filteredUsers.length > 0"
          class="hidden md:block text-gray-600"
        >
          {{ filteredUsers.length }}
          {{ i18n.t("general.results") }}
        </div>
        <h2 v-else>
          {{ i18n.t("users.empty") }}
        </h2>
      </div>
    </div>

    <div
      class="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-screen-2xl mx-auto"
    >
      <!-- BEGIN: Users Layout -->
      <div
        v-for="user in filteredUsers"
        :key="user.id"
        class="box p-4 cursor-pointer"
      >
        <!-- name -->
        <div class="text-2xl font-bold">{{ user.name }}</div>

        <!-- address -->
        <div class="text-lg my-1">{{ i18n.t(`user.role.${user.role}`) }}</div>

        <!-- contacts -->
        <div class="text-xs mb-1">{{ user.email }}</div>
      </div>
      <!-- BEGIN: Users Layout -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useCollectionSearch } from "@/composables/collection-search";

export default {
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: filteredUsers,
    } = useCollectionSearch(["name", "email"]);

    // fetch users
    async function fetchUsers() {
      const result = await store.dispatch("users/getCollection");
      collectionToFilter.value = result.validated;
    }
    fetchUsers();

    return {
      i18n,
      updateSearchQuery,

      //users
      filteredUsers,
    };
  },
};
</script>

<style></style>
