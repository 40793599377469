import Cookies from "js-cookie";
import { auth as api } from "@/api";

const state = () => {
  return {
    token: Cookies.get("auth-token"),
    user: null,
    customer: null,
  };
};

// getters
const getters = {
  token: (state) => state.token,
  user: (state) => state.user,
  customer: (state) => state.customer,
};

// actions
const actions = {
  async login({ commit, dispatch }, { email, password }) {
    const result = await api.login(email, password);
    if (result.success) {
      commit("SET_TOKEN", result.data.token);
      await dispatch("loadUserAndCustomerInfos");
    }
    return result;
  },
  async remember({ commit }, { email }) {
    const result = await api.remember(email);
    return result;
  },
  async reset({ commit }, { token, email, password, password_confirmation }) {
    const result = await api.reset(
      token,
      email,
      password,
      password_confirmation
    );
    return result;
  },
  async change({ commit }, { old_password, new_password, confirm_password }) {
    const result = await api.change(
      old_password,
      new_password,
      confirm_password
    );
    return result;
  },
  async loadUserAndCustomerInfos({ dispatch }) {
    await dispatch("user");
    await dispatch("customer");
  },
  async user({ commit, getters, dispatch }) {
    if (!getters.token) return;
    const result = await api.profile();
    if (result.success) commit("SET_USER", result.data);
    else commit("CLEAR_AUTH");
    return result.data;
  },
  async customer({ commit, getters, dispatch }) {
    if (!getters.token) return;
    const result = await api.customer();
    if (result.success) commit("SET_CUSTOMER", result.data);
    else commit("CLEAR_AUTH");
    return result.data;
  },
  async logout({ commit }) {
    const result = await api.logout();
    if (result.success) commit("CLEAR_AUTH");
    return result;
  },
  async clear({ commit }) {
    commit("CLEAR_AUTH");
  },
};

// mutations
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    Cookies.set("auth-token", token, {
      secure: true,
      expires: 60,
    });
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
    if (customer.configurations.length > 0) {
      state.customer.configurations = customer.configurations.reduce(function (
        map,
        obj
      ) {
        map[obj.parameter] = obj.value;
        return map;
      },
      {});
    }
  },
  CLEAR_AUTH(state) {
    state.token = null;
    state.user = null;
    state.customer = null;
    Cookies.remove("auth-token");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
