<template>
  <div id="services-dashboard">
    <simple-title class="mb-8">Servizi</simple-title>
    <div class="flex flex-wrap">
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyecold-dashboard' }"
        >eyecold</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyegeo-dashboard' }"
        >eyegeo</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyeway-dashboard' }"
        >eyeway</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'carbontax-dashboard' }"
        >carbontax</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyefuel-dashboard' }"
        >eyefuel</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyelink-dashboard' }"
        >eyelink</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyepharma-dashboard' }"
        >eyepharma</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyeservice-dashboard' }"
        >eyeservice</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'eyeshare-dashboard' }"
        >eyeshare</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'f-gas-dashboard' }"
        >f-gas</router-link
      >
      <router-link
        class="block rounded-2xl p-4 mr-4 mb-4 text-white bg-theme-20"
        :to="{ name: 'geofencing-dashboard' }"
        >geofencing</router-link
      >
    </div>
  </div>
</template>
