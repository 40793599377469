<template>
  <div
    class="box p-4 mb-3 cursor-pointer fleet-drop"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    :data-uniqid="uid"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div
            class="w-10 h-10 rounded-md"
            :style="{ 'background-color': form.color }"
          ></div>
          <div class="ml-4 flex-1 mr-auto">
            <div class="font-medium">
              {{ form.code }} ({{
                `${vehicles.length} ${i18n.t("general.vehicles")}`
              }})
            </div>
            <div class="text-gray-600 text-xs mt-0.5">
              {{ form.description }}
            </div>
          </div>
        </div>
        <div class="flex">
          <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
            <EditIcon class="w-5 h-5" />
          </button>
          <button class="btn btn-danger p-1" @click.stop="deleteEntity(form)">
            <TrashIcon class="w-5 h-5" />
          </button>
        </div>
      </div>

      <!-- drop zone -->
      <DropZone
        v-show="active"
        class="mt-4"
        drop-key="fleet-drop"
        @dropped="handleDrop"
      >
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          <VehicleCard
            v-for="vehicle in vehicles"
            :key="vehicle.vehicle_id"
            :entity="vehicle"
            :show-remove="true"
            :remove-callback="() => removeVehicle(vehicle.vehicle_id)"
            :drag-active="false"
            class="p-1"
          />
        </div>
      </DropZone>
    </div>

    <!-- form -->
    <div v-else>
      <!-- code -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("general.code")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.code.$error"
          :errors="v$.code.$errors"
        >
          <input v-model="form.code" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- description -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("general.description")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.description.$error"
          :errors="v$.description.$errors"
        >
          <input v-model="form.description" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- color -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("general.color")
        }}</label>
        <input
          v-model="form.color"
          type="color"
          class="form-control mr-4 h-9 w-5/12 p-0"
        />
        <select v-model="form.color" class="form-select w-5/12">
          <option
            v-for="c in colors"
            :key="c.key"
            :value="c.value"
            class="capitalize"
          >
            {{ i18n.t(`colors.${c.key}`) }}
          </option>
        </select>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import _ from "lodash";
import DropZone from "@/components/drop-zone/Main.vue";
import VehicleCard from "./VehicleCard.vue";
import uniqid from "uniqid";

export default {
  components: { DropZone, VehicleCard },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      required: true,
    },
    vehicles: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "updated:vehicles", "selected", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const uid = uniqid();

    EventBus.on("draggable:drop", ({ dropUid, cardId }) => {
      if (uid == dropUid) {
        updateVehicleFleet(cardId, props.entity.id);
        select();
      }
    });

    // select
    function select() {
      if (!formActive.value) emit("selected", props.entity);
    }

    // form
    const formActive = ref(false);
    const formBk = ref(null);
    const form = ref(props.entity);
    if (!props.entity.id) showForm();

    function showForm() {
      formActive.value = true;
      formBk.value = _.cloneDeep(form.value);
    }

    function cancelForm() {
      formActive.value = false;
      form.value = _.cloneDeep(formBk.value);
      emit("canceled", props.entity);
    }

    // validation
    const rules = {
      code: { required },
      description: { required },
      color: { required },
    };

    const v$ = useVuelidate(rules, form);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      let result = null;

      if ("id" in form.value) {
        result = await store.dispatch("fleets/update", form.value);
      } else {
        result = await store.dispatch("fleets/store", form.value);
      }

      if (!result.success) {
        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        formActive.value = false;
        emit("updated", result.validated);
      }

      loading.value = false;
    }

    // delete
    async function deleteEntity(entity) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("fleets.confirm-delete-title")} ${entity.code}?`,
        text: i18n.t("fleets.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("fleets/delete", entity.id);
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // drop zone
    function handleDrop(vehicleId) {
      updateVehicleFleet(vehicleId, form.value.id);
    }

    // remove vehicle
    async function removeVehicle(vehicleId) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("fleets.confirm-remove-vehicle-title")}?`,
        callback: (confirm) => {
          if (confirm) updateVehicleFleet(vehicleId, null);
        },
      });
    }

    async function updateVehicleFleet(vehicleId, fleetId) {
      const result = await store.dispatch("vehicles/setFleet", {
        vehicleId,
        fleetId,
      });
      if (result.success) emit("updated:vehicles");
    }

    // utils
    const colors = store.getters["main/colors"];

    return {
      i18n,
      uid,
      // select
      select,

      // form
      form,
      v$,
      formActive,
      showForm,
      cancelForm,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // drop zone
      handleDrop,

      // remove vehicle
      removeVehicle,

      // utils
      colors,
    };
  },
};
</script>

<style></style>
