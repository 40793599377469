import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
const namespace = "agreements";

const api = {
  ...getResource(namespace),
  getAgreementVehicles: async function (agreementId) {
    try {
      const response = await axios.get(`agreements/${agreementId}/vehicles`);
      return handleSuccess(
        { namespace, action: "getAgreementVehicles" },
        response
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getAgreementVehicles" },
        error.response
      );
    }
  },
  getAgreementArchivedVehicles: async function (agreementId) {
    try {
      const response = await axios.get(
        `agreements/${agreementId}/vehicles/archived`
      );
      return handleSuccess(
        { namespace, action: "getAgreementArchivedVehicles" },
        response
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getAgreementArchivedVehicles" },
        error.response
      );
    }
  },
};
export { api as default };
