import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
const namespace = "alerts";
const api = {
  getCollection: getResource(namespace).getCollection,
  getChannels: async function () {
    try {
      const response = await axios.get(`${namespace}/channels`);
      return handleSuccess({ namespace, action: "getChannels" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getChannels" }, error.response);
    }
  },
  getActive: async function (meta) {
    try {
      const response = await axios.get(`${namespace}/active`, { params: meta });
      return handleSuccess({ namespace, action: "getActive" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getActive" }, error.response);
    }
  },
  getVehicles: async function (alertId) {
    try {
      const response = await axios.get(`${namespace}/${alertId}/vehicles`);
      return handleSuccess({ namespace, action: "getVehicles" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getVehicles" }, error.response);
    }
  },
  getConfigCollection: getResource(`${namespace}/configurations`).getCollection,
  postConfigEntity: getResource(`${namespace}/configurations`).postEntity,
  postConfigMassive: getResource(`${namespace}/multi_configurations`)
    .postEntity,
  getConfigEntity: getResource(`${namespace}/configurations`).getEntity,
  putConfigEntity: getResource(`${namespace}/configurations`).putEntity,
  deleteConfigEntity: getResource(`${namespace}/configurations`).deleteEntity,
  removeAll: async function (vehicleId) {
    try {
      const response = await axios.delete(
        `${namespace}/configurations/vehicle/${vehicleId}`
      );
      return handleSuccess({ namespace, action: "removeAll" }, response);
    } catch (error) {
      return handleError({ namespace, action: "removeAll" }, error.response);
    }
  },
  removeAllMassive: async function (vehicles) {
    try {
      const response = await axios.delete(
        `${namespace}/configurations/vehicles`,
        {
          params: {
            vehicles: vehicles,
          },
        }
      );
      return handleSuccess({ namespace, action: "removeAllMassive" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "removeAllMassive" },
        error.response
      );
    }
  },
};
export { api as default };
