<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <TopBar :notifications="notifications_active" />
    <!-- BEGIN: Top Menu -->
    <div class="px-3">
      <nav
        class="top-nav m-auto rounded-t-3xl bg-theme-1 w-full flex flex-row justify-between hidden md:flex"
      >
        <ul class="pl-2 xl:pl-0">
          <!-- BEGIN: First Child -->
          <li v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
            <a
              :href="
                menu.subMenu
                  ? 'javascript:;'
                  : router.resolve({ name: menu.pageName }).path
              "
              class="top-menu"
              :class="{
                'top-menu--active': menu.active,
              }"
              @click="linkTo(menu, router, $event)"
            >
              <div class="top-menu__icon">
                <component :is="menu.icon" />
              </div>
              <div
                class="top-menu__title"
                :class="{
                  'hidden lg:inline ': menu.active,
                }"
              >
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="menu.subMenu"
                  class="top-menu__sub-icon"
                />
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <ul v-if="menu.subMenu">
              <li
                v-for="(subMenu, subMenuKey) in menu.subMenu"
                :key="subMenuKey"
              >
                <a
                  :href="
                    subMenu.subMenu
                      ? 'javascript:;'
                      : router.resolve({ name: subMenu.pageName }).path
                  "
                  class="top-menu"
                  @click="linkTo(subMenu, router, $event)"
                >
                  <div class="top-menu__icon">
                    <!-- <ActivityIcon /> -->
                    <component :is="subMenu.icon" />
                  </div>
                  <div class="top-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="subMenu.subMenu"
                      class="top-menu__sub-icon"
                    />
                  </div>
                </a>
                <!-- BEGIN: Third Child -->
                <ul v-if="subMenu.subMenu">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <a
                      :href="
                        lastSubMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({
                              name: lastSubMenu.pageName,
                            }).path
                      "
                      class="top-menu"
                      @click="linkTo(lastSubMenu, router, $event)"
                    >
                      <div class="top-menu__icon">
                        <component :is="'zap-icon'" />
                      </div>
                      <div class="top-menu__title">
                        {{ lastSubMenu.title }}
                      </div>
                    </a>
                  </li>
                </ul>
                <!-- END: Third Child -->
              </li>
            </ul>
            <!-- END: Second Child -->
          </li>
          <!-- END: First Child -->
        </ul>
        <div class="pr-2 xl:pr-0 pt-1 self-center">
          <!-- fleets filter -->
          <FleetsFilter />
        </div>
      </nav>
    </div>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->

    <div class="content">
      <router-view />
    </div>
    <!-- END: Content -->
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { useI18n } from "vue3-i18n";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { nestedMenu, linkTo } from "@/layouts/side-menu";
import EventBus from "@/libs/event-bus";
import FleetsFilter from "@/components/fleets-filter/Main";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    FleetsFilter,
  },
  setup() {
    const route = useRoute();
    const i18n = useI18n();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const notifications_active = ref(true);
    const alerts = store.getters["alerts/collection"];
    const topMenu = computed(() => nestedMenu(store.state.topMenu.menu, route));

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(topMenu.value);
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main");
      formattedMenu.value = $h.toRaw(topMenu.value);
    });

    function updateAlert(alert) {
      const pins = store.getters["globalMap/pins"];

      let obj = alerts.find((o) => o.id == alert.alert_id);
      let vehicle = pins.find((o) => o.vehicle_id == alert.pivot.vehicle_id);
      EventBus.emit("notification:show", {
        type: obj.severity ? "warning" : "danger",
        title: obj.description,
        text:
          i18n.t("alerts.notify.vehicle") +
          ": " +
          vehicle.plate +
          " " +
          i18n.t("alerts.notify.datetime") +
          ": " +
          dayjs(alert.created_at).format(),
      });
    }

    function updateReport(report) {
      var notification_services = [];
      report.services.split(",").forEach((service) => {
        notification_services.push(i18n.t("reports.services." + service));
      });

      var notification_text =
        i18n.t("reports.notify.period_from_to") +
        ": " +
        dayjs(report.period_from).format() +
        "-" +
        dayjs(report.period_to).format() +
        "\n" +
        i18n.t("reports.notify.services") +
        ": " +
        notification_services.join(", ");
      switch (report.ready) {
        case 0:
          EventBus.emit("notification:show", {
            type: "info",
            title: i18n.t("reports.notify.processing"),
            text: notification_text,
          });
          break;
        case 1:
          EventBus.emit("notification:show", {
            type: "buttons-below",
            title: i18n.t("reports.notify.ready"),
            text: notification_text,
            action_label: i18n.t("reports.notify.view"),
            action_secondary_label: i18n.t("reports.notify.download"),
            action: () => {
              router.push({ path: "/report", query: { r: report.id } });
            },
            action_secondary: () => downloadReport(report),
          });
          break;
        case 2:
          EventBus.emit("notification:show", {
            type: "info",
            title: i18n.t("reports.notify.error"),
            text: notification_text,
          });
          break;
      }
    }

    // Downalod
    async function downloadReport(report) {
      const file_result = await store.dispatch("reports/linkReport", {
        id: report.id,
        hash: report.hash,
      });
      if (file_result.success == false) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("reports.no-data"),
        });
      }
    }

    function updateNotification(notification) {
      /*
      EventBus.emit("notification:show", {
        type: "info",
        title: i18n.t(notification.data.message)
      });*/
    }
    function refresh() {
      router.push({ path: "/global-map" });
      store.dispatch("globalMap/clear");
    }
    EventBus.on("global:updates:alerts", updateAlert);
    EventBus.on("global-map:refresh", refresh);
    EventBus.on("global:updates:reports", updateReport);
    EventBus.on("global:updates:notifications", updateNotification);
    return {
      formattedMenu,
      router,
      notifications_active,
      linkTo,
    };
  },
});
</script>
