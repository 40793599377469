<template>
  <div id="pois-index">
    <div class="flex justify-between py-2">
      <simple-title>Eyecrono</simple-title>
    </div>
    <div class="flex flex-wrap-reverse">
      <div class="w-full">
        <div
          v-if="loading"
          class="flex flex-col place-items-center justify-center py-6"
        >
          <loading-icon icon="rings" class="w-40 h-40" />

          <div class="w-full text-center text-xl font-bold mt-6">
            Sei in attesa del caricamento del portale Eyecrono. Ti preghiamo di
            attendere.
          </div>
        </div>
        <div
          v-else-if="data != null"
          class="flex flex-col place-items-center justify-center py-6"
        >
          <div class="w-full text-center text-xl font-bold my-6 text-green-500">
            Richiesta effettuata con successo. Sei in attesa di essere
            reindirizzato.
          </div>

          <CheckCircleIcon class="w-40 h-40 text-green-500" />

          <div class="w-full text-center text-md font-bold mt-6">
            Se non dovessi essere reindirizzato correttamente sei pregato di
            premere il link qui sotto.
          </div>

          <a :href="data.url" class="p-8"> Vai alla piattaforma </a>
        </div>
        <div
          v-else
          class="flex flex-col place-items-center justify-center py-6"
        >
          <AlertCircleIcon class="w-40 h-40 text-red-500" />

          <div class="w-full text-center text-xl font-bold mt-6 text-red-500">
            <p>Errore durante la procedura di attivazione.</p>
            <p>La pregiamo di contattare l'assistenza tecnica Fiway.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import Map from "@/components/map/Map";
import EventBus from "@/libs/event-bus";
import { useRouter } from "vue-router";

export default {
  components: {},

  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const loading = ref(true);
    const data = ref(null);
    const token = ref("");
    onMounted(async () => {
      const response = await store.dispatch("eyecrono/getPlatform");
      loading.value = false;
      if (response.success) {
        data.value = response.data;
        setTimeout(() => {
          window.location.href = response.data.url;
        }, 2000);
      }
    });
    return {
      i18n,
      loading,
      data,
      token,
    };
  },
};
</script>

<style></style>
