<template>
  <div class="overflow-hidden">
    <!-- detail panel -->
    <div v-if="hub">
      <!-- heading -->
      <div class="flex items-end justify-between">
        <div class="flex items-baseline">
          <span class="text-xl font-bold mr-4">{{ hub.code }}</span>
          <span class="text-sm mr-4">{{ hub.model }}</span>
        </div>
      </div>
    </div>
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("hub.vehicles") }}
      </div>
      <div class="overflow-y-auto">
        <div v-if="vehicles && vehicles.length > 0">
          <div v-for="v in vehicles" :key="v.vehicle_id">
            <div class="intro-y">
              <VehicleCard :vehicle="v" @select="handleVehicleClick" />
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import VehicleCard from "./VehicleCard.vue";
import { useRouter } from "vue-router";
// import EventBus from "@/libs/event-bus";

export default {
  components: { VehicleCard },
  props: {
    service: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    const vehicles = reactive(ref([]));

    // initialization
    const hub = computed(() => store.getters["globalMap/selectedHub"]);

    watch(async () => {
      if (hub.value && hub.value) {
        const dataResult = await store.dispatch(
          "hubs/getVehicles",
          hub.value.id
        );
        vehicles.value = dataResult.validated;
      }
    });

    function handleVehicleClick(v) {
      store.dispatch("globalMap/setExternalVehicle", { id: v.vehicle_id });
      router.push({
        path: "/global-map",
        query: { v: v.vehicle_id },
      });
    }

    function closePanel() {
      emit("close");
    }

    return {
      dayjs,
      i18n,
      hub,
      vehicles,
      closePanel,
      handleVehicleClick,
    };
  },
};
</script>

<style></style>
