<template>
  <div v-if="active" class="flex flex-col h-full divide-y">
    <!--trip planner -->
    <div class="flex w-full p-1 mb-6">
      <div class="flex flex-col w-full">
        <div class="text-lg capitalize mb-6 w-full text-center">
          {{ i18n.t("services.eyedirections.plan trip") }}
        </div>

        <!--Nome Viaggio e Impostazioni-->
        <div class="flex flex-row w-full bg-white align-items-center mb-2">
          <div
            class="flex flex-grow bg-white shadow-md h-10 p-1 pl-2 mr-2 border rounded-sm focus-within:border-blue-300 focus-within:shadow-lg"
          >
            <input
              v-model="tripname"
              placeholder="Nome Viaggio..."
              class="flex flex-grow self-center outline-none truncate"
            />
          </div>
          <div
            class="self-center justify-end cursor-pointer"
            @click="isPopupVisible = true"
          >
            <SettingsIcon></SettingsIcon>
          </div>
        </div>
        <div
          v-if="options.some((e) => e.value == true)"
          class="flex flex-row w-full bg-white mb-4"
        >
          <div class="text-bold">
            <span><b>Esclusioni: </b></span>
            <span v-for="e in options">
              <span v-if="e.value == true">
                {{ e.name + ", " }}
              </span>
            </span>
          </div>
        </div>

        <!-- Date selection-->
        <div class="flex flex-row w-full bg-white align-items-center mb-8 pr-8">
          <Eyepicker
            v-model="startdate"
            class="shadow-md"
            :range="false"
            :min-date="new Date()"
            :max-date="
              new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            "
            :close-on-auto-apply="true"
            placeholder="Seleziona Data..."
          >
          </Eyepicker>
        </div>

        <!--lista waypoints-->
        <template v-for="(item, index) in waypoints" :key="item.address">
          <div class="flex flex-col mb-6">
            <div
              class="flex flex-row w-full bg-white align-items-center mb-2 scale-100"
            >
              <div
                class="flex flex-grow bg-white shadow-md h-10 pl-1 border rounded-sm cursor-text mr-2 focus-within:border-blue-300 focus-within:shadow-lg"
              >
                <div
                  class="self-center justify-start pointer-events-none opacity-50 p-1"
                >
                  <MapPinIcon></MapPinIcon>
                </div>

                <!-- Destination Field-->
                <geocoding-input
                  id="geoinput"
                  v-model="item.address"
                  :placeholder="
                    item.address.length > 0
                      ? item.address + ', ' + item.city + ', ' + item.zipcode
                      : 'Cerca Luogo'
                  "
                  class="flex-1 ring-transparent"
                  :class="focused == index ? 'h-full' : ''"
                  @focus="focused = index"
                  @point="handleclick($event, index)"
                />
              </div>
              <div
                class="self-center justify-end cursor-pointer"
                @click="removeWaypoint(index)"
              >
                <XCircleIcon></XCircleIcon>
              </div>
            </div>
            <!--Note Field-->
            <div class="flex flex-row">
              <input
                v-if="focused == index"
                v-model="item.notes"
                placeholder="Inserisci Note"
                class="flex flex-grow self-center outline-none truncate w-full bg-white shadow-md h-10 pl-1 border rounded-sm cursor-text mr-2 focus-within:border-blue-300 focus-within:shadow-lg"
                @focusout="focused = null"
              />
              <button
                v-if="focused == index"
                class="self-center justify-start cursor-pointer p-1"
                @click="focused = null"
              >
                <CheckCircleIcon></CheckCircleIcon>
              </button>
            </div>
            <div
              v-if="item.notes.length > 1 && focused != index"
              class="flex flex-wrap self-start"
            >
              {{ i18n.t("services.eyedirections.notes") }}: &nbsp{{
                item.notes
              }}
            </div>
          </div>
        </template>

        <!--aggiungi waypoint e salva viaggio-->
        <div class="flex flex-col bg-white mb-6 justify-center gap-2">
          <div
            class="flex bg-white shadow-md h-10 p-1 pl-2 cursor-pointer border justify-center rounded-md hover:border-blue-300 hover:bg-indigo-100 hover:shadow-lg"
            @click="addWaypoint()"
          >
            <div class="flex self-center">
              <PlusCircleIcon></PlusCircleIcon>
            </div>
            <div class="flex self-center cursor-pointer m-2">
              {{ i18n.t("services.eyedirections.add waypoint") }}
            </div>
          </div>
          <div
            class="flex bg-green-200 shadow-md h-10 p-1 pl-2 cursor-pointer border justify-center rounded-md hover:border-blue-300 hover:bg-green-300 hover:shadow-lg"
            @click="saveTrip(tripname, startdate)"
          >
            <div class="flex self-center"><SaveIcon></SaveIcon></div>
            <div class="flex self-center cursor-pointer m-2">Salva</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Trip Preview and alternative route picker-->

    <div
      v-if="tripname.length > 1 && waypoints.length > 1"
      class="flex flex-col w-full gap-1 border border-gray-300 p-2 shadow-md"
    >
      <span class="text-lg mb-4">{{
        i18n.t("services.eyedirections.choose route")
      }}</span>
      <span class="text-lg mb-2"
        >{{ i18n.t("services.eyedirections.trip") }}:
        <b>{{ tripname }}</b></span
      >

      <template v-for="(r, index) in route" :key="index">
        <div
          class="flex flex-col hover:shadow-md hover:border-gray-500 cursor-pointer mb-6 border border-gray-300 p-2"
          :class="
            selectedRoute == index
              ? 'bg-green-100 shadow-md border-gray-500'
              : ''
          "
          @click="selectRoute(index)"
        >
          <span class="text-lg"
            >{{ i18n.t("services.eyedirections.route") }} {{ index + 1 }}</span
          >
          <div class="flex flex-row w-full align-items-center">
            <div class="flex w-full self-center justify-start">
              <span class="flex text-sm"
                >{{ i18n.t("services.eyedirections.start") }}:</span
              >
            </div>
            <div class="flex w-full self-center">
              <span class="flex text-sm">{{
                waypoints[0].address +
                ", " +
                waypoints[0].city +
                ", " +
                waypoints[0].zipcode
              }}</span>
            </div>
          </div>

          <div class="flex flex-row w-full align-items-center">
            <div class="flex w-full self-center justify-start">
              <span class="flex text-sm"
                >{{ i18n.t("services.eyedirections.end") }}:</span
              >
            </div>
            <div class="flex w-full self-center">
              <span class="flex text-sm">{{
                waypoints[waypoints.length - 1].address +
                ", " +
                waypoints[waypoints.length - 1].city +
                ", " +
                waypoints[waypoints.length - 1].zipcode
              }}</span>
            </div>
          </div>

          <div class="flex flex-row w-full align-items-center">
            <div class="flex w-full self-center justify-start">
              <span class="flex text-sm"
                >{{ i18n.t("services.eyedirections.estimated arrival") }}:</span
              >
            </div>
            <div class="flex w-full self-center">
              <span class="flex text-sm">{{ getArrival(r.duration) }}</span>
            </div>
          </div>

          <div class="flex flex-row w-full align-items-center">
            <div class="flex w-full self-center justify-start">
              <span class="flex text-sm"
                >{{ i18n.t("services.eyedirections.distance") }}:</span
              >
            </div>
            <div class="flex w-full self-center">
              <span class="flex text-sm">{{ r.distance / 1000 }} Km</span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!--Trip list-->
    <div class="flex p-1">
      <div class="flex w-full flex-col">
        <div class="text-lg capitalize my-6 text-center">
          {{ i18n.t("services.eyedirections.plan trip") }}
        </div>

        <!--TRIP CARD-->
        <template v-for="(item, index) in trips">
          <div
            class="shadow-md mb-4 border border-gray-300 group-hover:shadow-lg group-hover:border-gray-500 p-2"
          >
            <div class="flex flex-row w-full bg-white align-items-center mb-4">
              <div class="flex w-full divide-x divide-black text-black gap-4">
                <div
                  class="flex flex-wrap self-center outline-none truncate pl-2"
                >
                  <b>{{ i18n.t("services.eyedirections.trip") }}:&nbsp&nbsp</b>
                  {{ item.name }}
                </div>
                <div
                  class="flex flex-wrap self-center outline-none truncate pl-2"
                >
                  <b>{{ i18n.t("services.eyedirections.start") }}:&nbsp&nbsp</b>
                  {{ item.startdate.toString().slice(0, 24) }}
                </div>
              </div>
            </div>
            <!--lista waypoint-->
            <div v-for="(point, index) in item.waypoints" class="flex">
              <div
                class="flex w-full flex-col bg-white align-items-center mb-8 border-b border-gray-400"
              >
                <div class="flex flex-row align-items-center">
                  <div class="self-center pointer-events-none opacity-50 p-1">
                    <MapPinIcon></MapPinIcon>
                  </div>
                  <div class="flex w-full flex-col p-2 mb-2">
                    <div v-if="index == 0" class="p-1 break-words mr-6">
                      <b
                        >{{ i18n.t("services.eyedirections.start") }}:&nbsp<br
                      /></b>
                      {{ point.address }}
                    </div>
                    <div
                      v-if="index != 0 && index < item.waypoints.length - 1"
                      class="p-1 break-words mr-6"
                    >
                      <b
                        >{{
                          i18n.t("services.eyedirections.waypoint")
                        }}:&nbsp<br /></b
                      >{{ point.address }}
                    </div>
                    <div
                      v-if="index == item.waypoints.length - 1"
                      class="p-1 break-words mr-6"
                    >
                      <b
                        >{{
                          i18n.t("services.eyedirections.arrival")
                        }}:&nbsp<br /></b
                      >{{ point.address }}
                    </div>
                    <div class="p-1 break-words mr-6">
                      <b
                        >{{ i18n.t("services.eyedirections.notes") }}:&nbsp<br
                      /></b>
                      {{ point.notes }}
                    </div>
                    <div class="p-1 break-words mr-6">
                      <b
                        >{{ i18n.t("services.eyedirections.hour") }}:&nbsp<br
                      /></b>
                      {{ point.zipcode }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full bg-white align-items-center mb-4">
              <div class="flex flex-col w-full divide-black text-black gap-2">
                <span
                  ><b
                    >{{
                      i18n.t("services.eyedirections.estimated arrival")
                    }}:</b
                  ></span
                >
                <div class="flex flex-wrap self-start outline-none truncate">
                  {{ item.arrival }}
                </div>
                <span
                  ><b>{{ i18n.t("services.eyedirections.distance") }}:</b></span
                >
                <div class="flex flex-wrap self-start outline-none truncate">
                  {{ item.route[0].distance / 1000 }} Km
                </div>
              </div>
            </div>

            <!--Buttons-->
            <div class="flex flex-row w-full justify-center gap-2 mb-2">
              <div
                class="flex bg-green-200 shadow-md h-10 p-1 pl-2 cursor-pointer border justify-center rounded-md hover:border-blue-300 transition-transform transform-gpu hover:scale-105 hover:bg-green-300 hover:shadow-lg"
                @click="RemoveTrip(index)"
              >
                <div class="flex self-center"><TrashIcon></TrashIcon></div>
                <div class="flex self-center cursor-pointer m-2">
                  {{ i18n.t("services.eyedirections.remove") }}
                </div>
              </div>
              <div
                class="flex bg-green-200 shadow-md h-10 p-1 pl-2 cursor-pointer border justify-center rounded-md hover:border-blue-300 transition-transform transform-gpu hover:scale-105 hover:bg-green-300 hover:shadow-lg"
                @click="EditTrip(index)"
              >
                <div class="flex self-center"><EditIcon></EditIcon></div>
                <div class="flex self-center cursor-pointer m-2">
                  {{ i18n.t("services.eyedirections.edit") }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <dropover
    :show="isPopupVisible"
    @keydown.esc="isPopupVisible = false"
    @keydown.enter="isPopupVisible = false"
    @close="isPopupVisible = false"
  >
    <template #header>
      <div class="text-lg self-center">
        {{ i18n.t("services.eyedirections.filter options") }}
      </div>
    </template>
    <template #body>
      <table>
        <tr v-for="(option, index) in options" :key="option.id">
          <td>
            <div class="custom-control custom-switch">
              <input
                :id="'toggle-' + option.id"
                v-model="option.value"
                type="checkbox"
                class="custom-control-input"
                @click="toggleOption(option.name, option.value, option.id)"
              />
              <label
                class="custom-control-label ml-2"
                :for="'toggle-' + option.id"
              >
                {{ i18n.t("services.eyedirections." + option.name) }}
              </label>
            </div>
          </td>
        </tr>
      </table>
    </template>
  </dropover>
</template>
<script>
import {
  CheckCircleIcon,
  EditIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from "@zhuowenli/vue-feather-icons";
import dropover from "@/components/dropover/Main.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { geoRouting } from "@/services/geocoder";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";

export default {
  name: "Eyegeo.Directions",
  components: {
    XCircleIcon,
    PlusCircleIcon,
    EditIcon,
    TrashIcon,
    CheckCircleIcon,
    dropover,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const i18n = useI18n();
    const L = window.L;
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const trips = computed(() => store.getters["trips/trips"]);

    const tripname = ref("");
    const startdate = ref("");
    const estarrival = ref("");
    const tripdistance = ref("");
    const route = reactive([]);
    const waypoints = reactive([]);
    const focused = ref(null);
    const selectedRoute = ref(0);
    const mapController = store.getters["globalMap/controller"];
    const options = reactive([
      { id: 1, value: false, name: "ferry" },
      { id: 2, value: false, name: "toll" },
      { id: 3, value: false, name: "motorway" },
    ]);

    let isPopupVisible = ref(false);
    const markers = reactive([]);

    function addWaypoint() {
      let waypoint = {
        address: "",
        city: "",
        zipcode: "",
        lat: "",
        lng: "",
        notes: "",
      };
      waypoints.push(waypoint);
    }

    function removeWaypoint(id) {
      focused.value = null;
      this.waypoints.forEach((element, index) => {
        if (id == index) {
          waypoints.splice(index, 1);
        }
      });
      mapController.clearPaths();
      if (this.waypoints.length >= 2) {
        getTripInfo();
      } else {
        route.splice(0);
      }
    }

    function getArrival(duration) {
      let mstime = duration * 1000;
      let starth = new Date(startdate.value).getTime();
      let arrival = new Date(new Date().setTime(mstime + starth));
      estarrival.value = arrival.toString().slice(0, 24);
      return arrival.toString().slice(0, 24);
    }

    function handleclick(place, index) {
      waypoints[index].address = place.address;
      waypoints[index].city = place.city;
      waypoints[index].zipcode = place.zip_code;
      waypoints[index].lat = place.latitude;
      waypoints[index].lng = place.longitude;

      if (waypoints.length >= 2) {
        getTripInfo();
      }
      handleMarkers(place);
    }

    function handleMarkers(place) {
      let marker = {
        id: place.address,
        group: "trip",
        icon: "point",
        latitude: place.latitude ?? 0,
        longitude: place.longitude ?? 0,
      };
      mapController.addMarker(marker);
      markers.push(marker);

      if (markers.length < 2) {
        mapController.flyToMarker(place.address, "trip");
      }
    }

    async function selectRoute(index) {
      mapController.clearPaths();
      selectedRoute.value = index;
      await drawRoute(
        route[selectedRoute.value].geometry,
        route[selectedRoute.value].distance
      );
    }

    function decodeRoute(geometry) {
      let coord = L.Polyline.fromEncoded(geometry, 5);
      return coord._latlngs;
    }

    async function getTripInfo(exclusions = null) {
      route.splice(0);
      let coordinates = [];
      waypoints.forEach((element, index) => {
        coordinates.push({ lng: element.lng, lat: element.lat });
      });

      let result = await getRoutesData(coordinates, exclusions);
      result.routes.forEach((element, index) => {
        element.geometry = decodeRoute(element.geometry);
        route.push(element);
      });

      await drawRoute(route[0].geometry, route[0].distance);
    }

    async function drawRoute(geometry, distance) {
      let points = [];
      geometry.forEach((element) => {
        points.push({
          latitude: element.lat,
          longitude: element.lng,
          icon: "dot",
        });
      });

      mapController.addPath({
        id: distance,
        group: "trip",
        markers: points,
      });
      mapController.flyToPath(distance, "trip");
    }
    async function saveTrip(tripname, date) {
      if (tripname.length < 1 || waypoints.length < 2) {
        return;
      }

      let trip = {
        name: tripname,
        startdate: date,
        arrival: "",
        waypoints: [],
        route: [],
      };

      this.waypoints.forEach((element, index) => {
        trip.waypoints.push(element);
      });

      trip.arrival = estarrival.value;
      trip.route.push(route[selectedRoute.value]);

      store.dispatch("trips/pushTrip", trip);
      resetTrip();
    }
    async function toggleOption(name, value, id) {
      let exclusions = options.slice();
      exclusions[id - 1].value = !value;
      if (waypoints.length >= 2) {
        mapController.clearPaths();
        getTripInfo(exclusions);
      }
    }

    async function getRoutesData(coordinates, exclusions) {
      return await geoRouting(coordinates, exclusions);
    }

    function RemoveTrip(index) {
      mapController.clearPaths();
      mapController.clearMarkers();
      store.dispatch("trips/removeTrip", index);
    }

    function EditTrip(index) {
      focused.value = null;
      waypoints.splice(0);
      startdate.value = trips.value[index].startdate;
      tripname.value = trips.value[index].name;
      trips.value[index].waypoints.forEach((element) => {
        waypoints.push(element);
      });
      store.dispatch("trips/removeTrip", index);
    }

    function resetTrip() {
      tripname.value = "";
      startdate.value = "";
      estarrival.value = "";
      tripdistance.value = "";
      waypoints.splice(0);
      route.slice(0);
      markers.slice(0);
      addWaypoint();

      mapController.clearMarkers("trip");

      try {
        document.getElementById("resetField").click();
      } catch {}
    }

    onMounted(() => {
      resetTrip();
      mapController.clearPaths("trip");
    });

    return {
      i18n,
      mapController,
      removeWaypoint,
      addWaypoint,
      saveTrip,
      RemoveTrip,
      EditTrip,
      resetTrip,
      handleclick,
      getRoutesData,
      getTripInfo,
      getArrival,
      selectRoute,
      toggleOption,
      waypoints,
      vehicle,
      trips,
      tripname,
      startdate,
      focused,
      estarrival,
      tripdistance,
      route,
      selectedRoute,
      options,
      isPopupVisible,
      markers,
    };
  },
};
</script>

<style></style>
