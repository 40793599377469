import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
const namespace = "services/eyecrono";
const api = {
  getPlatform: async function () {
    try {
      const response = await axios.get(`${namespace}/platform`);
      return handleSuccess({ namespace, action: "getPlatform" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getPlatform" }, error.response);
    }
  },
};
export { api as default };
