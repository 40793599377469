import { users as api } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
  };
};

// getters
const getters = {
  ...g,
};

// actions
const actions = {
  ...a(api),
};

// mutations
const mutations = {
  ...m,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
