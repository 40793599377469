<template>
  <Chart
    type="pie"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { computed, reactive, ref, watch } from "vue";

import { useStore } from "@/store";

export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    alerts: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const datasets = ref([]);
    const labels = ref([]);
    const store = useStore();
    const data = reactive({
      labels: computed(() => Array.from(new Set(labels.value))),
      datasets: computed(() => datasets.value),
    });

    function init() {
      labels.value = [];
      datasets.value = [];
      setChartData();
    }
    watch(() => props, init, { deep: true });
    init();

    function setChartData() {
      if (!props.alerts) return;

      const data_alerts = props.alerts.map((item) => {
        return item.total;
      });

      const alerts = store.getters["alerts/collection"];
      labels.value = props.alerts.map((item) => {
        let obj = alerts.find((o) => o.id === item.alert_id);
        if (!obj) return "";
        return obj.description;
      });

      if (data_alerts.length > 0) {
        datasets.value.push({
          data: data_alerts,
          backgroundColor: props.colors,
          hoverBackgroundColor: props.colors,
          borderWidth: 5,
          borderColor: "#fff",
        });
      }
    }

    const options = {
      legend: {
        display: false,
      },
      cutoutPercentage: 80,
      onClick: (evt, item) => {
        let index = item[0]["_index"];
        let label = item[0]["_chart"].data.labels[index];
        let data = item[0]["_chart"].data.datasets[0].data[index];
        let selected = props.alerts[index];
        emit("chart_click", { index, label, data, selected });
      },
    };

    return {
      data,
      options,
    };
  },
};
</script>
