import Chart from "./chart/Main.vue";
import Highlight from "./highlight/Main.vue";
import Litepicker from "./litepicker/Main.vue";
import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import TailSelect from "./tail-select/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import TinySlider from "./tiny-slider/Main.vue";
import Dropzone from "./dropzone/Main.vue";
import SimpleTitle from "./simple-title/SimpleTitle.vue";
import SearchTitle from "./search-title/SearchTitle.vue";
import Badge from "./badge/Main.vue";
import DateAgo from "./date_ago/Main.vue";
import GeocodingInput from "./geocoding-input/Main.vue";
import VehiclesInput from "./vehicles-input/VehiclesInput.vue";
import ValidatedInput from "./validated-input/Main.vue";
import ConfirmDialog from "./confirm-dialog/ConfirmDialog.vue";
import NotificationsToasts from "./notifications/NotificationsToasts.vue";
import Eyepicker from "./eyepicker/Eyepicker.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";
import VueMultiselect from "vue-multiselect";

// register globally
export default (app) => {
  app.component("Chart", Chart);
  app.component("DateAgo", DateAgo);
  app.component("Highlight", Highlight);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("TailSelect", TailSelect);
  app.component("LoadingIcon", LoadingIcon);
  app.component("TinySlider", TinySlider);
  app.component("Dropzone", Dropzone);
  app.component("SimpleTitle", SimpleTitle);
  app.component("SearchTitle", SearchTitle);
  app.component("Badge", Badge);
  app.component("GeocodingInput", GeocodingInput);
  app.component("VehiclesInput", VehiclesInput);
  app.component("ValidatedInput", ValidatedInput);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("NotificationsToasts", NotificationsToasts);
  app.component("Eyepicker", Eyepicker);
  app.component("VueMultiselect", VueMultiselect);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
