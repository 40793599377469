<template>
  <div v-if="vehicle" id="service-eyecontrol">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyecontrol.last_status") }}
        </div>
        <DateAgo
          v-if="data.command"
          :datetime="data.command.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.command" class="report-box mb-8">
        <div class="box px-4 py-4 mb-3 flex items-center justify-around">
          <div
            v-if="data.command.user != null"
            class="flex flex-col items-center mr-3"
          >
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("services.eyecontrol.user.name") }}
            </div>
            <div class="mb-2">
              <UserIcon />
            </div>
            <div
              v-if="data.command.user"
              class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
            >
              {{ data.command.user }}
            </div>
            <div
              v-else
              class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
            >
              {{ i18n.t("services.eyecontrol.unknown_user") }}
            </div>
          </div>
          <div
            v-if="data.command.command != null"
            class="flex flex-col items-center mr-3"
          >
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("services.eyecontrol.command.name") }}
            </div>
            <div class="mb-2">
              <SlidersIcon />
            </div>
            <div
              class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
            >
              {{
                i18n.t("services.eyecontrol.command." + data.command.command)
              }}
            </div>
          </div>
          <div
            v-if="data.command.command_data != null"
            class="flex flex-col items-center mr-3"
          >
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("services.eyecontrol.command_data.name") }}
            </div>
            <div class="mb-2">
              <InfoIcon />
            </div>
            <div
              class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
            >
              <div
                v-for="(item, index) in JSON.parse(data.command.command_data)"
                :v-key="item"
              >
                {{ i18n.t("services.eyecontrol.command_data." + index) }}:
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="py-6">
        <div class="text-lg capitalize mb-4">
          {{ i18n.t("services.eyecontrol.command_history") }}
          <Eyepicker v-model="daterange" />
        </div>
        <div
          v-if="!loadingHistory"
          class="max-h-80 overflow-y-auto text-center"
        >
          <div v-if="history && history.list">
            <div v-for="h in history.list.command" :key="h.id">
              <div class="intro-y">
                <div
                  class="box px-4 py-4 mb-3 flex items-center cursor-pointer"
                  @click="handleHistoryClick(h)"
                >
                  <div>
                    {{
                      dayjs(h.message_datetime).format("DD/MM/YYYY HH:mm:ss")
                    }}
                  </div>
                  <div
                    v-if="h.status != null"
                    class="flex flex-col items-center mr-3"
                  >
                    <div class="py-1 px-2 text-xs font-medium">
                      {{ i18n.t("services.eyecontrol.status.name") }}
                    </div>
                    <div class="mb-2">
                      <TrendingDownIcon />
                    </div>
                    <div
                      v-if="h.status == 7 || h.status == 6 || h.status == 3"
                      class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.eyecontrol.status." + h.status) }}
                    </div>
                    <div
                      v-else-if="
                        h.status == 5 || h.status == 4 || h.status == 1
                      "
                      class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.eyecontrol.status." + h.status) }}
                    </div>
                    <div
                      v-else
                      class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.eyecontrol.status." + h.status) }}
                    </div>
                  </div>
                  <div
                    v-if="h.user != null"
                    class="flex flex-col items-center mr-3"
                  >
                    <div class="py-1 px-2 text-xs font-medium">
                      {{ i18n.t("services.eyecontrol.user.name") }}
                    </div>
                    <div class="mb-2">
                      <UserIcon />
                    </div>
                    <div
                      v-if="h.user"
                      class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ h.user }}
                    </div>
                    <div
                      v-else
                      class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.eyecontrol.unknown_user") }}
                    </div>
                  </div>
                  <div
                    v-if="h.command != null"
                    class="flex flex-col items-center mr-3"
                  >
                    <div class="py-1 px-2 text-xs font-medium">
                      {{ i18n.t("services.eyecontrol.command.name") }}
                    </div>
                    <div class="mb-2">
                      <SlidersIcon />
                    </div>
                    <div
                      class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.eyecontrol.command." + h.command) }}
                    </div>
                  </div>
                  <div
                    v-if="h.command_data != null"
                    class="flex flex-col items-center mr-3"
                  >
                    <div class="py-1 px-2 text-xs font-medium">
                      {{ i18n.t("services.eyecontrol.command_data.name") }}
                    </div>
                    <div class="mb-2">
                      <InfoIcon />
                    </div>
                    <div
                      class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      <div
                        v-for="(item, index) in JSON.parse(h.command_data)"
                        :v-key="item"
                      >
                        {{
                          i18n.t("services.eyecontrol.command_data." + index)
                        }}:
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="font-light text-xl text-gray-500">
            {{ i18n.t("general.no-results") }}
          </div>
        </div>
        <loading-icon v-else icon="rings" class="w-20 h-20" />
        <div
          class="btn btn-primary rounded-full w-full mt-5"
          @click="requestReport"
        >
          <FileTextIcon /> {{ i18n.t("general.request-report") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateCommand(command) {
      if (data.value == null) return;
      data.value.command = command;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.command.length
      )
        history.value.list.command.unshift(command);
    }

    EventBus.on("vehicle:updates:command", updateCommand);

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyecontrol/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      getHistory();
    }

    function handleHistoryClick(history) {
      if (mapController.countMarkers("service") == 0) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("services.open_eyegeo_before"),
        });
      } else {
        mapController.flyToDate(history.message_datetime, "service");
      }
    }

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyecontrol", "master");
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "eyecontrol/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(historyFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
            ? dayjs().format()
            : dayjs(historyTo.value).format(),
        }
      );
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      handleHistoryClick,
      daterange,
    };
  },
};
</script>

<style></style>
