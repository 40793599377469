<template>
  <div id="agreements">
    <div class="flex justify-between py-2">
      <search-title @search="updateSearchQuery">{{
        i18n.t("navigation.agreements")
      }}</search-title>
    </div>

    <div v-if="!agreementsVehicles.length"></div>

    <div v-else class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div
        v-for="av in agreementsVehicles"
        :key="av.agreement.id"
        class="report-box-2"
      >
        <div class="box p-6">
          <div class="text-xl font-bold mb-4">
            {{ i18n.t(`services.${av.agreement.code}.name`) }}
          </div>
          <div v-if="av.vehicles.length" class="divide-y divide-gray-400">
            <a
              v-for="v in av.vehicles"
              :key="v.id"
              :href="`/global-map?v=${v.id}`"
              class="flex py-2 space-x-4"
            >
              <div class="font-bold">
                {{ v.plate }}
              </div>
              <div class="text-xs italic">
                {{ v.active_from }} - {{ v.active_to }}
              </div>
              <div
                :class="
                  v.color !== 'gray'
                    ? `text-reyesol-${v.color}`
                    : 'text-gray-500'
                "
              >
                {{ i18n.t(`agreements.state.${v.color}`) }}
              </div>
            </a>
          </div>
          <div v-else>
            {{ i18n.t("agreements.no-vehicles") }}
            <a
              href="mailto:amministrazione@fiway.it"
              class="text-reyesol-blue font-bold underline"
              >{{ i18n.t("agreements.get-contract") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useCollectionSearch } from "@/composables/collection-search";
import dayjs from "dayjs";
export default {
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: agreementsVehicles,
    } = useCollectionSearch([
      {
        key: "agreement.code",
        resolve: (code) => {
          return i18n.t(`services.${code}.name`);
        },
      },
      "vehicles.[].plate",
    ]);
    const agreements = store.getters["agreements/collection"];

    async function fetchVehicles() {
      const result = await store.dispatch("globalMap/getPins");
      if (!result.success) return;
      const vehicles = result.validated;
      collectionToFilter.value = [];
      for (let i = 0; i < agreements.length; i++) {
        const agreement = agreements[i];

        collectionToFilter.value.push({
          agreement,
          vehicles: vehicles
            .filter((v) => {
              return v.active_agreements.find(
                (a) => a.agreement_id == agreement.id
              );
            })
            .map((v) => {
              const a = v.active_agreements.find(
                (a) => a.agreement_id == agreement.id
              );
              const from = dayjs(a.active_from);
              const to = dayjs(a.active_to);

              let color = "gray";
              if (from.diff(dayjs(), "day") <= 0) {
                color = "green";
                const toDiff = to.diff(dayjs(), "day");
                if (toDiff <= 0) color = "red";
                if (toDiff > 0 && toDiff <= 30) color = "yellow";
              }

              return {
                id: v.vehicle_id,
                plate: v.plate,
                active_from: from.format("DD/MM/YYYY"),
                active_to: to.format("DD/MM/YYYY"),
                color,
              };
            }),
        });
      }
    }
    fetchVehicles();

    return {
      i18n,
      updateSearchQuery,
      agreementsVehicles,
    };
  },
};
</script>

<style></style>
