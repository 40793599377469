import { agreements as api } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
  };
};

// getters
const getters = {
  ...g,
};

// actions
const actions = {
  ...a(api),
  async getAgreementVehicles(_, agreementId) {
    const result = await api.getAgreementVehicles(agreementId);
    result.validated = [];
    if (result.success) {
      // const vehicles = result.data;
      // commit("SET_AGREEMENT_VEHICLES", vehicles);
      // result.validated = getters.vehicles;
      result.validated = result.data;
    }
    return result;
  },
};

// mutations
const mutations = {
  ...m,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
