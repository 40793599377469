import { pins as pinsApi } from "@/api";
import { vehicles as vehiclesApi } from "@/api";
import { hubs as hubsApi } from "@/api";
import { pois as poisApi } from "@/api";

const DEFAULT_SERVICE = "general";
const DEFAULT_FEATURE = "all";

const state = () => {
  return {
    controller: null,
    pins: [],
    requestedService: DEFAULT_SERVICE,
    requestedFeature: DEFAULT_FEATURE,
    selectedVehicle: null,
    externalSelectedVehicle: null,
    selectedHub: null,
    externalSelectedHub: null,
    selectedPoi: null,
    externalSelectedPoi: null,
  };
};

// getters
const getters = {
  controller: (state) => state.controller,
  pins: (state) => state.pins,
  requestedService: (state) => state.requestedService,
  requestedFeature: (state) => state.requestedFeature,
  selectedVehicle: (state) => state.selectedVehicle,
  externalSelectedVehicle: (state) => state.externalSelectedVehicle,
  selectedHub: (state) => state.selectedHub,
  externalSelectedHub: (state) => state.externalSelectedHub,
  selectedPoi: (state) => state.selectedPoi,
  externalSelectedPoi: (state) => state.externalSelectedPoi,
};

// actions
const actions = {
  setController: ({ commit }, controller) =>
    commit("SET_CONTROLLER", controller),

  setRequestedService: ({ commit }, serviceKey) =>
    commit("SET_RQ_SERVICE", serviceKey),
  resetRequestedService: ({ commit }) => commit("RESET_RQ_SERVICE"),

  setRequestedFeature: ({ commit }, featureKey) =>
    commit("SET_RQ_FEATURE", featureKey),
  resetRequestedFeature: ({ commit }) => commit("RESET_RQ_FEATURE"),

  setExternalVehicle: ({ commit }, vehicle) =>
    commit("SET_EXT_VEHICLE", vehicle),
  resetExternalVehicle: ({ commit }) => commit("RESET_EXT_VEHICLE"),

  resetSelectedVehicle: ({ commit }) => commit("RESET_VEHICLE"),

  setExternalHub: ({ commit }, hub) => commit("SET_EXT_HUB", hub),
  resetExternalHub: ({ commit }) => commit("RESET_EXT_HUB"),

  setExternalPoi: ({ commit }, poi) => commit("SET_EXT_POI", poi),
  resetExternalPoi: ({ commit }) => commit("RESET_EXT_POI"),

  clearRequested({ dispatch }) {
    dispatch("resetRequestedService");
    dispatch("resetRequestedFeature");
  },

  clear({ dispatch }) {
    //dispatch("resetRequestedService");
    //dispatch("resetRequestedFeature");
    dispatch("resetSelectedVehicle");
    dispatch("resetExternalVehicle");
    dispatch("resetExternalHub");
    dispatch("resetExternalPoi");
  },

  async getPins({ commit, getters }) {
    const result = await pinsApi.getCollection();
    result.validated = getters["pins"];
    if (result.success) {
      commit("SET_PINS", result.data);
      result.validated = getters["pins"];
    }
    return result;
  },

  async getVehicle({ commit, getters }, vehicleId) {
    const result = await vehiclesApi.getEntity(vehicleId);
    result.validated = null;
    if (result.success) {
      commit("SET_VEHICLE", result.data);
      result.validated = getters["selectedVehicle"];
    }
    return result;
  },

  async getHub({ commit, getters }, hubId) {
    const result = await hubsApi.getEntity(hubId);
    result.validated = null;
    if (result.success) {
      commit("SET_HUB", result.data);
      result.validated = getters["selectedHub"];
    }
    return result;
  },

  async getPoi({ commit, getters }, poiId) {
    const result = await poisApi.getEntity(poiId);
    result.validated = null;
    if (result.success) {
      commit("SET_POI", result.data);
      result.validated = getters["selectedPoi"];
    }
    return result;
  },

  async requestReport(_, { vehicleId, services, from, to, type }) {
    return await vehiclesApi.requestReport(vehicleId, services, from, to, type);
  },
};

// mutations
const mutations = {
  SET_CONTROLLER(state, controller) {
    state.controller = controller;
  },
  SET_RQ_SERVICE(state, serviceKey) {
    state.requestedService = serviceKey;
  },
  RESET_RQ_SERVICE(state) {
    state.requestedService = DEFAULT_SERVICE;
  },
  SET_RQ_FEATURE(state, featureKey) {
    state.requestedFeature = featureKey;
  },
  RESET_RQ_FEATURE(state) {
    state.requestedFeature = DEFAULT_FEATURE;
  },
  SET_VEHICLE(state, vehicle) {
    state.selectedVehicle = vehicle;
    if (vehicle.configurations.length > 0) {
      state.selectedVehicle.configurations = vehicle.configurations.reduce(
        function (map, obj) {
          map[obj.parameter] = obj.value;
          return map;
        },
        {}
      );
    }
  },
  SET_HUB(state, hub) {
    state.selectedHub = hub;
  },
  SET_POI(state, poi) {
    state.selectedPoi = poi;
  },
  RESET_VEHICLE(state) {
    state.selectedVehicle = null;
  },
  SET_EXT_VEHICLE(state, vehicle) {
    state.externalSelectedHub = null;
    state.externalSelectedVehicle = vehicle;
  },
  RESET_EXT_VEHICLE(state) {
    state.externalSelectedVehicle = null;
  },
  SET_EXT_HUB(state, hub) {
    state.externalSelectedVehicle = null;
    state.externalSelectedHub = hub;
  },
  RESET_EXT_HUB(state) {
    state.externalSelectedHub = null;
  },
  SET_EXT_POI(state, poi) {
    state.externalSelectedVehicle = null;
    state.externalSelectedPoi = poi;
  },
  RESET_EXT_POI(state) {
    state.externalSelectedPoi = null;
  },
  SET_PINS(state, pins) {
    state.pins = pins;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
