<template>
  <div v-if="vehicle" id="service-eyeservice">
    <div>
      <!-- new maintenance -->
      <button class="btn btn-primary w-full mb-3" @click="addNewMaintenance">
        <div class="w-10 h-10 rounded-md flex items-center justify-center">
          <PlusIcon />
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            {{ i18n.t("services.eyeservice.add") }}
          </div>
        </div>
      </button>
      <!-- ServiceCard -->

      <div v-if="addActive" class="flex-cols items-center justify-between">
        <!-- type -->
        <div class="flex items-center mb-4">
          <label class="w-2/12 mr-4">{{
            i18n.t("services.eyeservice.select_type")
          }}</label>
          <select v-model="addType" class="form-select">
            <option value="0">
              {{ i18n.t("services.eyeservice.type.0.name") }}
            </option>
            <option value="1">
              {{ i18n.t("services.eyeservice.type.1.name") }}
            </option>
            <option value="2">
              {{ i18n.t("services.eyeservice.type.2.name") }}
            </option>
          </select>
        </div>
        <div class="flex items-center mb-4 capitalize">
          <label class="w-2/12 mr-4">{{
            i18n.t("services.eyeservice.date")
          }}</label>
          <input v-model="addDate" type="date" class="form-control" />
        </div>
        <!-- code -->
        <div v-if="addType == 0" class="flex items-center mb-4 capitalize">
          <label class="w-2/12 mr-4">{{
            i18n.t("services.eyeservice.data")
          }}</label>
          <input v-model="addData" type="text" class="form-control" />
        </div>

        <!-- save -->
        <div class="flex justify-end">
          <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
            {{ i18n.t("general.cancel") }}
          </button>

          <button class="btn btn-primary" @click.stop="save()">
            <loading-icon
              v-if="loading"
              icon="rings"
              color="white"
              class="mr-2"
            />
            {{ i18n.t("general.save") }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="data">
      <template
        v-if="
          data.routine_maintenance ||
          data.temperature_recorder_maintenance ||
          data.fgas_maintenance
        "
      >
        <div
          v-if="data.routine_maintenance"
          class="report-box cursor-pointer mb-8"
        >
          <div class="box p-5">
            <div class="flex justify-around">
              <div
                v-if="data.routine_maintenance != null"
                class="flex flex-col items-center mr-3"
              >
                <div class="py-1 px-2 text-xs font-medium">
                  {{ i18n.t("services.eyeservice.routine_maintenance.name") }}
                </div>
                <div class="mb-2">
                  <AlertOctagonIcon
                    v-if="
                      dayjs(data.routine_maintenance.message_datetime).add(
                        vehicle.configurations.routine_maintenance_days || 365,
                        'day'
                      ) < dayjs()
                    "
                  />
                  <ToolIcon v-else />
                </div>
                <div
                  v-if="
                    dayjs(data.routine_maintenance.message_datetime).add(
                      vehicle.configurations.routine_maintenance_days || 365,
                      'day'
                    ) < dayjs()
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(data.routine_maintenance.message_datetime)
                      .add(
                        vehicle.configurations.routine_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
                <div
                  v-else
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(data.routine_maintenance.message_datetime)
                      .add(
                        vehicle.configurations.routine_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
              </div>
              <div
                v-if="data.temperature_recorder_maintenance != null"
                class="flex flex-col items-center mr-3"
              >
                <div class="py-1 px-2 text-xs font-medium">
                  {{
                    i18n.t(
                      "services.eyeservice.temperature_recorder_maintenance.name"
                    )
                  }}
                </div>
                <div class="mb-2">
                  <AlertOctagonIcon
                    v-if="
                      dayjs(
                        data.temperature_recorder_maintenance.message_datetime
                      ).add(
                        vehicle.configurations
                          .temperature_recorder_maintenance_days || 365,
                        'day'
                      ) < dayjs()
                    "
                  />
                  <ClockIcon v-else />
                </div>
                <div
                  v-if="
                    dayjs(
                      data.temperature_recorder_maintenance.message_datetime
                    ).add(
                      vehicle.configurations
                        .temperature_recorder_maintenance_days || 365,
                      'day'
                    ) < dayjs()
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(
                      data.temperature_recorder_maintenance.message_datetime
                    )
                      .add(
                        vehicle.configurations
                          .temperature_recorder_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
                <div
                  v-else
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(
                      data.temperature_recorder_maintenance.message_datetime
                    )
                      .add(
                        vehicle.configurations
                          .temperature_recorder_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
              </div>
              <div
                v-if="data.fgas_maintenance != null"
                class="flex flex-col items-center mr-3"
              >
                <div class="py-1 px-2 text-xs font-medium">
                  {{ i18n.t("services.eyeservice.type.3.name") }}
                </div>
                <div class="mb-2">
                  <AlertOctagonIcon
                    v-if="
                      dayjs(data.fgas_maintenance.message_datetime).add(
                        vehicle.configurations.fgas_maintenance_days || 365,
                        'day'
                      ) < dayjs()
                    "
                  />
                  <FeatherIcon v-else />
                </div>
                <div
                  v-if="
                    dayjs(data.fgas_maintenance.message_datetime).add(
                      vehicle.configurations.fgas_maintenance_days || 365,
                      'day'
                    ) < dayjs()
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(data.fgas_maintenance.message_datetime)
                      .add(
                        vehicle.configurations.fgas_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
                <div
                  v-else
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{
                    dayjs(data.fgas_maintenance.message_datetime)
                      .add(
                        vehicle.configurations.fgas_maintenance_days || 365,
                        "day"
                      )
                      .format("DD/MM/YYYY")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="font-light text-xl text-gray-500">
        {{ i18n.t("general.no-results") }}
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyeservice.service_history") }}
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list" :key="h.id">
            <div class="intro-y">
              <div class="box px-4 py-4 mb-3 flex items-center cursor-pointer">
                <div
                  class="w-10 h-10 flex items-center justify-center image-cover rounded-md overflow-hidden"
                >
                  <FeatherIcon v-if="h.type == 1" />
                  <ClockIcon v-else-if="h.type == 2" />
                  <ToolIcon v-else />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    {{ dayjs(h.message_datetime).format("DD/MM/YYYY") }}
                  </div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    {{ i18n.t("services.eyeservice.type." + h.type + ".date") }}
                  </div>
                </div>
                <div class="flex flex-col items-center mr-3">
                  <div
                    class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyeservice.type." + h.type + ".name") }}
                  </div>
                  <div v-if="h.data != null" class="mt-2">
                    {{ h.data }}
                    {{ i18n.t("services.eyeservice.type." + h.type + ".unit") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import toast from "@/services/toast";

export default {
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const addActive = ref(false);
    const addType = ref(0);
    const addDate = ref(dayjs().format("YYYY-MM-DD"));
    const addData = ref(null);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateService(service) {
      if (data.value == null) return;
      data.value.service = service;

      if (history.value != null && history.value.list)
        history.value.list.unshift(service);
    }

    EventBus.on("vehicle:updates:service", updateService);
    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyeservice/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      getHistory();
    }

    async function addNewMaintenance() {
      addActive.value = true;
    }

    async function cancelForm() {
      addActive.value = false;
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "eyeservice/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: null,
          dateTo: null,
        }
      );
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/addMaintenance", {
        vehicleId: vehicle.value.id,
        type: addType.value,
        date: addDate.value,
        data: addType.value == 0 ? addData.value : null,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    return {
      i18n: useI18n(),
      dayjs,
      vehicle,
      data,
      history,
      addNewMaintenance,
      loadingHistory,
      addActive,
      cancelForm,
      addType,
      addDate,
      addData,
      save,
    };
  },
};
</script>

<style></style>
