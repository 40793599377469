<template>
  <div v-if="vehicle" id="service-eyeway">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyeway.last_status") }}
        </div>
        <DateAgo
          v-if="data.axle"
          :datetime="data.axle.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <div
        v-if="data.axle"
        class="report-box cursor-pointer mb-8"
        @click="handleHistoryClick(data.axle)"
      >
        <div class="box p-5">
          <!-- axle, cap, alarm -->
          <div class="flex justify-around">
            <div
              v-if="data.axle.breaks_status != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyeway.breaks_status.name") }}
              </div>
              <div class="mb-2">
                <AlertTriangleIcon />
              </div>
              <div
                v-if="data.axle.breaks_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyeway.breaks_status.normal") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyeway.breaks_status.critical") }}
              </div>
            </div>
            <div
              v-if="data.axle.tyres_pressure_status != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyeway.tyres_pressure_status.name") }}
              </div>
              <div class="mb-2">
                <AlertTriangleIcon />
              </div>
              <div
                v-if="data.axle.tyres_pressure_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyeway.tyres_pressure_status.normal") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyeway.tyres_pressure_status.critical") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.axle"
        class="report-box cursor-pointer mb-8"
        @click="handleHistoryClick(data.axle)"
      >
        <div class="box p-5 py-6">
          <!-- axle, cap, alarm -->
          <div class="flex justify-around">
            <div
              v-if="data.axle.odometer != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyeway.odometer.name") }}
              </div>
              <div class="mb-2">
                <ArchiveIcon />
              </div>
              <div
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.odometer }} Km
              </div>
            </div>
            <div
              v-if="data.axle.load != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyeway.load.name") }}
              </div>
              <div class="mb-2">
                <TrendingUpIcon />
              </div>
              <div
                v-if="data.axle.load >= 10000"
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.load }} Kg
              </div>
              <div
                v-else-if="data.axle.load >= 5000"
                class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.load }} Kg
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.load }} Kg
              </div>
            </div>

            <div
              v-if="data.axle.speed != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyeway.speed.name") }}
              </div>
              <div class="mb-2">
                <TrendingUpIcon />
              </div>
              <div
                v-if="data.axle.speed >= 100"
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.speed }} Km/h
              </div>
              <div
                v-else-if="data.axle.speed >= 90"
                class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.speed }} Km/h
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.axle.speed }} Km/h
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyeway.speed_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.axle &&
            history.list.axle.length > 0
          "
        >
          <AxleChart :active="active" :height="200" :axle="history.list.axle" />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
        <div
          class="btn btn-primary rounded-full w-full mt-5"
          @click="requestReport"
        >
          <FileTextIcon /> {{ i18n.t("general.request-report") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
    </div>
  </div>
</template>

<script>
import AxleChart from "@/components/axle-chart/Main.vue";
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";

export default {
  components: {
    AxleChart,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateAxle(axle) {
      if (data.value == null) return;
      data.value.axle = axle;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.axle.length
      )
        history.value.list.axle.unshift(axle);
    }

    EventBus.on("vehicle:updates:axle", updateAxle);
    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyeway/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyeway", "simple");
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyeway/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    return {
      i18n: useI18n(),
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
    };
  },
};
</script>

<style></style>
