<template>
  <div class="mt-2 box flex flex-col cursor-pointer" @click="handleClick">
    <div class="flex items-center border-b-2 border-theme-7 mb-1 pb-2">
      <div class="pl-4 w-full">
        <div class="font-bold flex text-base mb-2 content-center">
          <img
            :src="
              require(`@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`)
            "
            class="w-6 h-6 mr-2"
          />
          <span>{{ vehicle.plate }}</span>
          <div class="mt-1 ml-auto mr-5 text-xs justify-self-end">
            <template v-if="vehicle.status == 0">
              <div
                class="px-2 rounded-lg bg-reyesol-disconnected text-reyesol-disconnected-contrast"
              >
                {{ i18n.t("services.general.status.disconnected") }}
              </div>
            </template>
            <template v-else-if="vehicle.status == 1">
              <div
                class="px-2 rounded-lg bg-reyesol-connected text-reyesol-connected-contrast"
              >
                {{ i18n.t("services.general.status.connected") }}
              </div>
            </template>
            <template v-else-if="vehicle.status == 2">
              <div
                class="px-2 rounded-lg bg-reyesol-alarm text-reyesol-alarm-contrast"
              >
                {{ i18n.t("services.general.status.powersafe") }}
              </div>
            </template>
            <template v-else-if="vehicle.status == 3">
              <div
                class="px-2 rounded-lg bg-reyesol-powersafe text-reyesol-powersafe-contrast"
              >
                {{ i18n.t("services.general.status.powersafe") }}
              </div>
            </template>
            <template v-else-if="vehicle.status == 4">
              <div
                class="px-2 rounded-lg bg-reyesol-moving text-reyesol-moving-contrast"
              >
                {{ i18n.t("services.general.status.moving") }}
              </div>
            </template>
            <template v-else>
              <div
                class="px-2 rounded-lg bg-reyesol-alarm text-reyesol-alarm-contrast"
              >
                {{ i18n.t("services.general.status.alarm") }}
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-row justify-between content-center pr-4">
          <div class="text-gray-600 font-thin text-xs break-words">
            {{ last_position }}
          </div>
          <div
            class="my-auto justify-self-end bg-gray-200 p-1 rounded shadow-md hover:bg-gray-400 m-2"
          >
            <a
              :href="makeMapsUrl(vehicle.latitude, vehicle.longitude)"
              target="_blank"
              class=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-share-2"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow flex items-center">
      <div
        class="flex-grow grid grid-cols-3 md:grid-cols-3 justify-center items-center divide-x"
      >
        <div class="flex text-center justify-center">
          <img
            v-if="is_fridge"
            :src="require(`@/assets/images/cold.svg`)"
            class="w-5 h-5 text-blue-900 self-center"
          />
          <span
            v-if="vehicle.overall_status != null && vehicle.overall_status == 1"
            class="ml-1 py-1 px-1 rounded text-xs bg-theme-10 text-white cursor-pointer font-medium"
          >
            ON
          </span>
          <span
            v-if="vehicle.overall_status != null && vehicle.overall_status == 0"
            class="ml-1 py-1 px-1 rounded text-xs bg-theme-24 text-white cursor-pointer font-medium"
          >
            OFF
          </span>
        </div>
        <div>
          <div class="text-center">
            <div class="text-center text-xs">
              {{ i18n.t("vehicles.fleet") }}
            </div>
            <div class="font-medium">
              {{ vehicleFleet(vehicle.fleet_id) }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-center">
            <div class="text-center text-xs">
              {{ i18n.t("vehicles.unit_code") }}
            </div>
            <div class="font-medium">
              {{ vehicle.unit_code || "***" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow flex items-center mt-2">
      <div
        class="flex-grow grid grid-cols-3 md:grid-cols-3 justify-center items-center divide-y"
      >
        <div
          v-if="
            vehicle.T1 != null && vehicle.fridge_temperature_sensors_number >= 1
          "
          class="md:col-span-3 flex px-3 py-1"
        >
          <ThermometerIcon class="mr-1" />
          <span
            v-if="
              vehicle.T1 != null &&
              vehicle.fridge_temperature_sensors_number >= 1
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            T1: {{ vehicle.T1 }}°C
          </span>
          <span
            v-if="
              vehicle.T2 != null &&
              vehicle.fridge_temperature_sensors_number >= 2
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            T2: {{ vehicle.T2 }}°C
          </span>
          <span
            v-if="
              vehicle.T3 != null &&
              vehicle.fridge_temperature_sensors_number >= 3
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            T3: {{ vehicle.T3 }}°C
          </span>
          <span
            v-if="
              vehicle.T4 != null &&
              vehicle.fridge_temperature_sensors_number >= 4
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-theme-10 text-theme-10 cursor-pointer font-medium"
          >
            T4: {{ vehicle.T4 }}°C
          </span>
        </div>
        <div
          v-if="
            vehicle.setpoint_t1 != null &&
            vehicle.fridge_compartments_number >= 1
          "
          class="md:col-span-3 flex px-3 py-1"
        >
          <TargetIcon class="mr-1" />
          <span
            v-if="
              vehicle.setpoint_t1 != null &&
              vehicle.fridge_compartments_number >= 1
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t1 }}°C
          </span>
          <span
            v-if="
              vehicle.setpoint_t2 != null &&
              vehicle.fridge_compartments_number >= 2
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t2 }}°C
          </span>
          <span
            v-if="
              vehicle.setpoint_t3 != null &&
              vehicle.fridge_compartments_number >= 3
            "
            class="mr-1 py-1 px-1 rounded text-xs border-2 border-blue-400 text-blue-400 cursor-pointer font-medium"
          >
            {{ vehicle.setpoint_t3 }}°C
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { geoReverseSearch } from "@/services/geocoder";
import { computed, onMounted, ref, watch } from "vue";
import {
  PowerIcon,
  ThermometerIcon,
  TargetIcon,
} from "@zhuowenli/vue-feather-icons";
import tippy from "tippy.js";
import { useStore } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    ThermometerIcon,
    TargetIcon,
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const last_position = ref(null);

    const has_eyegeo_geocoding = props.vehicle.active_agreements.find(
      (o) => o.agreement_id == 25
    );

    async function init() {
      if (!last_position.value) {
        if (has_eyegeo_geocoding) {
          const result = await geoReverseSearch(
            props.vehicle.latitude,
            props.vehicle.longitude
          );
          if (!result) {
            last_position.value =
              normalize(props.vehicle.latitude) +
              " , " +
              normalize(props.vehicle.longitude);
          }

          last_position.value = result.display_name;
        } else {
          last_position.value =
            "lat: " +
            normalize(props.vehicle.latitude) +
            ", long:" +
            normalize(props.vehicle.longitude);
        }
      }
    }

    watch(() => props, init, { deep: true });

    const i18n = useI18n();
    const store = useStore();
    const fleets = store.getters["fleets/collection"];

    let is_fridge = ref(false);

    let has_eyecold = props.vehicle.active_agreements.find(
      (o) => o.agreement_id == 3
    );

    if (has_eyecold) {
      is_fridge = true;
    }
    function vehicleFleet(fleet_id) {
      let obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }
    function normalize(coord) {
      return [parseFloat(coord)];
    }
    function handleClick() {
      emit("select", props.vehicle);
    }
    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }
    init();
    return {
      dayjs,
      i18n,
      handleClick,
      vehicleFleet,
      normalize,
      makeMapsUrl,
      is_fridge,
      has_eyegeo_geocoding,
      last_position,
    };
  },
};
</script>

<style></style>
