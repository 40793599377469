<template>
  <div>
    <div class="mb-2">
      <div class="font-bold text-lg">{{ name }}</div>
      <div class="text-sm text-gray-500">{{ description }}</div>
    </div>
    <div class="flex space-x-2">
      <button
        class="btn btn-secondary"
        :class="state && 'btn-success'"
        :disabled="sending"
        @click="trySend(true)"
      >
        <span v-if="sending && state" class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
        <span v-else>ON</span>
      </button>
      <button
        class="btn btn-secondary"
        :class="!state && 'btn-danger'"
        :disabled="sending"
        @click="trySend(false)"
      >
        <span v-if="sending && !state" class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
        <span v-else>OFF</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    initialValue: {
      type: [Boolean, Number],
      default: false,
    },
    ask: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["send"],
  setup(props, { emit }) {
    const i18n = useI18n();

    const sending = ref(false);
    watch(
      () => props.initialValue,
      (v) => {
        sending.value = false;
        state.value = v;
      }
    );

    const state = ref(props.initialValue == 1);

    function trySend(value) {
      if (props.ask) {
        EventBus.emit("confirm-dialog:show", {
          title: i18n.t("services.eyecontrol.confirm-title"),
          text: i18n.t("services.eyecontrol.confirm-text"),
          doubleCheck: true,
          doubleCheckText: i18n.t("services.eyecontrol.confirm-toggle-text"),
          callback: async (confirm) => {
            if (confirm) send(value);
          },
        });
      } else {
        send();
      }
    }

    function send(value) {
      if (state.value == value) return;
      sending.value = true;
      state.value = value;
      emit("send", state.value);
    }

    return { state, trySend, send, sending };
  },
};
</script>

<style></style>
