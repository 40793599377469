<template>
  <div v-if="vehicle" id="service-carbontax">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-2">
        <div class="text-lg capitalize">
          {{ i18n.t("services.carbontax.month") }}
        </div>
        <div v-if="data.month != null && data.month.start && data.month.end">
          {{ dayjs(data.month.start).format("DD/MM/YYYY") }} -
          {{ dayjs(data.month.end).format("DD/MM/YYYY") }}
        </div>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.month != null" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <!-- start_datetitme -->
          <div class="pb-4 mb-4 border-b border-gray-400">
            <div class="flex justify-around">
              <div class="text-base text-gray-600 text-center capitalize">
                {{ i18n.t("services.carbontax.start_datetitme") }}
                <div>
                  {{
                    dayjs(data.month.start_datetitme).format("DD/MM/YYYY HH:MM")
                  }}
                </div>
              </div>
              <div class="text-base text-gray-600 text-center capitalize">
                {{ i18n.t("services.carbontax.end_datetime") }}
                <div>
                  {{
                    dayjs(data.month.end_datetime).format("DD/MM/YYYY HH:MM")
                  }}
                </div>
              </div>
            </div>
          </div>

          <!-- direction & speed-->
          <div class="flex justify-around">
            <div
              v-if="data.month.start_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">{{ data.month.start_hours }} h</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.start_hours") }}
              </div>
            </div>

            <div
              v-if="data.month.end_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">{{ data.month.end_hours }} h</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.end_hours") }}
              </div>
            </div>

            <div
              v-if="data.month.end_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">
                {{
                  Number(
                    (data.month.end_hours - data.month.start_hours) *
                      data.average_consumption
                  ).toFixed(1)
                }}
                l
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.consumption") }}
              </div>
            </div>
          </div>

          <!-- month -->
          <div
            v-if="data.month.alert != 0"
            class="my-3 pt-4 items-center text-center border-t border-gray-400"
          >
            <div class="text-base text-gray-600">
              {{ i18n.t("services.carbontax.alert") }}
            </div>
            <div
              v-if="data.month.alert == 1"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_init") }}
            </div>
            <div
              v-if="data.month.alert == 2"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_end") }}
            </div>
            <div
              v-if="data.month.alert == 3"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_init_end") }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.carbontax.quarter") }}
        </div>
        <div
          v-if="data.quarter != null && data.quarter.start && data.quarter.end"
        >
          {{ dayjs(data.quarter.start).format("DD/MM/YYYY") }} -
          {{ dayjs(data.quarter.end).format("DD/MM/YYYY") }}
        </div>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.quarter != null" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <!-- start_datetitme -->
          <div class="pb-4 mb-4 border-b border-gray-400">
            <div class="flex justify-around">
              <div class="text-base text-gray-600 text-center capitalize">
                {{ i18n.t("services.carbontax.start_datetitme") }}
                <div>
                  {{
                    dayjs(data.quarter.start_datetitme).format(
                      "DD/MM/YYYY HH:MM"
                    )
                  }}
                </div>
              </div>
              <div class="text-base text-gray-600 text-center capitalize">
                {{ i18n.t("services.carbontax.end_datetime") }}
                <div>
                  {{
                    dayjs(data.quarter.end_datetime).format("DD/MM/YYYY HH:MM")
                  }}
                </div>
              </div>
            </div>
          </div>

          <!-- direction & speed-->
          <div class="flex justify-around">
            <div
              v-if="data.quarter.start_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">{{ data.quarter.start_hours }} h</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.start_hours") }}
              </div>
            </div>

            <div
              v-if="data.quarter.end_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">{{ data.quarter.end_hours }} h</div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.end_hours") }}
              </div>
            </div>

            <div
              v-if="data.quarter.end_hours != null"
              class="flex flex-col items-center mr-3"
            >
              <div class="mb-2">
                {{
                  Number(
                    (data.quarter.end_hours - data.quarter.start_hours) *
                      data.average_consumption
                  ).toFixed(1)
                }}
                l
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.carbontax.consumption") }}
              </div>
            </div>
          </div>

          <!-- quarter -->
          <div
            v-if="data.quarter.alert != 0"
            class="my-3 pt-4 items-center text-center border-t border-gray-400"
          >
            <div class="text-base text-gray-600">
              {{ i18n.t("services.carbontax.alert") }}
            </div>
            <div
              v-if="data.quarter.alert == 1"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_init") }}
            </div>
            <div
              v-if="data.quarter.alert == 2"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_end") }}
            </div>
            <div
              v-if="data.quarter.alert == 3"
              class="text-2xl font-medium leading-8 mb-2"
            >
              {{ i18n.t("services.carbontax.alert_init_end") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "carbontax/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;
    }
    // destroy service
    function destroyService() {}

    return {
      i18n,
      dayjs,
      vehicle,
      data,
    };
  },
};
</script>

<style></style>
