<template>
  <div v-if="vehicle" id="service-eyedoor">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyedoor.last_status") }}
        </div>
        <DateAgo
          v-if="data.door"
          :datetime="data.door.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.door" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <!-- door, cap, alarm -->
          <div class="flex justify-around">
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.door1_active == 'true' &&
                data.door.door_1 != null
              "
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyedoor.door_1.name") }}
              </div>
              <div class="mb-2">
                <LockIcon v-if="data.door.door_1 == 0" />
                <LogInIcon v-else />
              </div>
              <div
                v-if="data.door.door_1 == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.door_1.closed") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.door_1.opened") }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.door2_active == 'true' &&
                data.door.door_2 != null
              "
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyedoor.door_2.name") }}
              </div>
              <div class="mb-2">
                <LockIcon v-if="data.door.door_2 == 0" />
                <LogInIcon v-else />
              </div>
              <div
                v-if="data.door.door_2 == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.door_2.closed") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.door_2.opened") }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.siren_active == 'true' &&
                data.door.armed != null
              "
              class="flex flex-col items-center mr-3"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyedoor.armed.name") }}
              </div>
              <div class="mb-2">
                <BellOffIcon v-if="data.door.armed == 0" />
                <BellIcon v-else />
              </div>
              <div
                v-if="data.door.armed == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.armed.none") }}
              </div>
              <div
                v-else-if="data.door.door_1 == 1 || data.door.door_2 == 1"
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.armed.active") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyedoor.armed.armed") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyedoor.door_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.door" :key="h.id">
            <div
              v-if="h.door_1 == 1 || h.door_2 == 1 || h.armed == 1"
              class="intro-y"
            >
              <div
                class="box px-4 py-4 mb-3 flex items-center cursor-pointer"
                @click="handleHistoryClick(h)"
              >
                <div
                  class="w-10 h-10 flex items-center justify-center image-cover rounded-md overflow-hidden"
                >
                  <img
                    alt="pin image"
                    :src="require('@/assets/images/marker-icon-small.png')"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    <DateAgo :datetime="h.message_datetime"></DateAgo>
                  </div>
                </div>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.door1_active == 'true' &&
                    h.door_1 != null
                  "
                  class="flex flex-col items-center mr-3"
                >
                  <div class="py-1 px-2 text-xs font-medium">
                    {{ i18n.t("services.eyedoor.door_1.name") }}
                  </div>
                  <div class="mb-2">
                    <LockIcon v-if="h.door_1 == 0" />
                    <LogInIcon v-else />
                  </div>
                  <div
                    v-if="h.door_1 == 0"
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.door_1.closed") }}
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.door_1.opened") }}
                  </div>
                </div>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.door2_active == 'true' &&
                    h.door_2 != null
                  "
                  class="flex flex-col items-center mr-3"
                >
                  <div class="py-1 px-2 text-xs font-medium">
                    {{ i18n.t("services.eyedoor.door_2.name") }}
                  </div>
                  <div class="mb-2">
                    <LockIcon v-if="h.door_2 == 0" />
                    <LogInIcon v-else />
                  </div>
                  <div
                    v-if="h.door_2 == 0"
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.door_2.closed") }}
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.door_2.opened") }}
                  </div>
                </div>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.siren_active == 'true' &&
                    h.armed != null
                  "
                  class="flex flex-col items-center mr-3"
                >
                  <div class="py-1 px-2 text-xs font-medium">
                    {{ i18n.t("services.eyedoor.armed.name") }}
                  </div>
                  <div class="mb-2">
                    <BellOffIcon v-if="h.armed == 0" />
                    <BellIcon v-else />
                  </div>
                  <div
                    v-if="h.armed == 0"
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.armed.none") }}
                  </div>
                  <div
                    v-else-if="h.door_1 == 1 || h.door_2 == 1"
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.armed.active") }}
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyedoor.armed.armed") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div
        class="btn btn-primary rounded-full w-full mt-5"
        @click="requestReport"
      >
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateDoor(door) {
      if (data.value == null) return;
      data.value.door = door;
      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.door.length
      )
        history.value.list.door.unshift(door);
    }

    EventBus.on("vehicle:updates:door", updateDoor);

    function handleHistoryClick(history) {
      if (mapController.countMarkers("service") == 0) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("services.open_eyegeo_before"),
        });
      } else {
        mapController.flyToDate(history.message_datetime, "service");
      }
    }

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyedoor/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;
      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyedoor", "master");
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyedoor/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
      handleHistoryClick,
    };
  },
};
</script>

<style></style>
