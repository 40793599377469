import axios from "axios";
import store from "@/store";
import router from "@/router";

const getInstance = function ({
  baseURL = `${process.env.VUE_APP_API_HOST}/api/${process.env.VUE_APP_API_VERSION}/`,
  headers = null,
  withAuth = false,
} = {}) {
  const instance = axios.create({
    baseURL: baseURL,
  });

  if (headers) {
    instance.defaults.headers.common = {
      ...instance.defaults.headers.common,
      ...headers,
    };
  }
  instance.interceptors.request.use(function (config) {
    const language = store.getters["main/language"];
    if (language) config.headers["Accept-Language"] = language;
    return config;
  });
  if (withAuth) {
    instance.interceptors.request.use(function (config) {
      const token = store.getters["auth/token"];
      if (token) config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // console.log("interceptor response", error.response.status);
        if (error.response.status === 401) {
          store.dispatch("auth/clear");
          router.replace("/login");
        }
        return Promise.reject(error);
      }
    );
  }

  return instance;
};

const instance = getInstance({ withAuth: true });
export { instance as default, getInstance };
