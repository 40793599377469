import { eyecrono as api } from "@/api";
const state = () => {
  return {
    data: [],
    history: [],
  };
};

// getters
const getters = {};

// actions
const actions = {
  async getPlatform() {
    return await api.getPlatform();
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
