<template>
  <div v-if="vehicle" id="service-tandt">
    <div v-if="data">
      <!--LAST UPDATE-->
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.tandt.last_status") }}
        </div>

        <DateAgo
          v-if="data.beacon"
          :datetime="data.beacon.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <!--NEW CARDS-->
      <div
        v-if="data.beacon"
        class="flex flex-col border border-1 border-gray-300 rounded-md shadow-md mb-8 p-2 divide-y"
      >
        <!--Vehicle Lock status-->
        <div class="flex flex-row justify-center pt-2 mb-4">
          <img
            :src="
              require(`@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`)
            "
            class="block h-5 opacity-95 transform-gpu translate-y-1"
          />
          <div class="pr-4 pl-4">
            <AlertCircleIcon v-if="data.beacon.locked == null" />
            <UnlockIcon v-else-if="data.beacon.locked == 0" />
            <LockIcon v-else />
          </div>
          <img
            v-if="data.beacon.locked_vehicle"
            :src="
              require(`@/assets/images/vehicles/${data.beacon.locked_vehicle.vehicle_type}-primary.svg`)
            "
            class="block h-6 opacity-95 transform-gpu translate-y-1"
          />
        </div>
        <!--Locking Auth status-->
        <div class="flex flex-row justify-center pt-4 mb-4">
          <div class="py-1 px-2 text-xs font-medium mr-3">
            {{ i18n.t("services.tandt.locked.name") }}:
          </div>
          <div
            v-if="data.beacon.locked === null"
            class="py-1 px-2 bg-theme-10 rounded-md text-xs text-white cursor-pointer font-medium"
          >
            {{ i18n.t("services.tandt.locked.unknown") }}
          </div>
          <div
            v-else-if="data.beacon.locked == 0"
            class="py-1 px-2 bg-theme-10 rounded-md text-xs text-white cursor-pointer font-medium"
          >
            {{ i18n.t("services.tandt.locked.unfasten") }}
          </div>
          <div v-else>
            <div
              v-if="data.beacon.authorized === null"
              class="py-1 px-2 bg-theme-24 rounded-md text-xs text-white cursor-pointer font-medium"
            >
              {{ i18n.t("services.tandt.authorized.unknown") }}
            </div>
            <div
              v-else-if="data.beacon.authorized == 0"
              class="py-1 px-2 bg-red-500 rounded-md text-xs text-white cursor-pointer font-medium"
            >
              {{ i18n.t("services.tandt.authorized.unauthorized") }}
            </div>
            <div
              v-else
              class="py-1 px-2 bg-theme-10 rounded-md text-xs text-white cursor-pointer font-medium"
            >
              {{ i18n.t("services.tandt.authorized.authorized") }}
            </div>
          </div>
        </div>
        <!--Locked vehicle-->
        <div
          v-if="data.beacon.locked_vehicle"
          class="flex flex-row justify-center pt-2 mb-6"
        >
          <div v-if="data.beacon.locked_vehicle">
            <div class="grid grid-cols-2 gap-4">
              <div class="flex flex-col items-center">
                <div class="py-1 text-xs font-medium">
                  {{ i18n.t("vehicles.plate") }}
                </div>
                <div class="mb-2">
                  <CreditCardIcon />
                </div>
                <div
                  class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
                >
                  {{ data.beacon.locked_vehicle.plate }}
                </div>
              </div>
              <div class="flex flex-col items-center">
                <div class="py-1 text-xs font-medium">
                  {{ i18n.t("vehicles.fleet") }}
                </div>
                <div class="mb-2">
                  <TruckIcon />
                </div>
                <div
                  class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
                >
                  <span>{{
                    vehicleFleet(data.beacon.locked_vehicle.fleet_id)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <!--History-->
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.tandt.beacon_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list" :key="h.id">
            <div class="intro-y">
              <div
                class="box border border-1 border-gray-300 px-4 py-4 mb-3 mx-1 cursor-pointer"
                @click="handleHistoryClick(h)"
              >
                <div class="flex justify-between items-center">
                  <div
                    class="w-10 h-10 flex items-center image-cover rounded-md overflow-hidden"
                  >
                    <AlertTriangleIcon v-if="h.locked === null" />
                    <UnlockIcon v-else-if="h.locked == 0" />
                    <LockIcon v-else />
                  </div>
                  <div class="justify-around">
                    <div
                      v-if="h.locked === 0"
                      class="py-1 px-2 bg-theme-10 rounded-md text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.tandt.locked.unfasten") }}
                    </div>
                    <div v-else-if="h.locked == 1">
                      <div
                        v-if="h.authorized === null"
                        class="py-1 px-2 bg-theme-24 rounded-md text-xs text-white cursor-pointer font-medium"
                      >
                        {{ i18n.t("services.tandt.authorized.unknown") }}
                      </div>
                      <div
                        v-else-if="h.authorized == 0"
                        class="py-1 px-2 bg-red-500 rounded-md text-xs text-white cursor-pointer font-medium"
                      >
                        {{ i18n.t("services.tandt.authorized.unauthorized") }}
                      </div>
                      <div
                        v-else
                        class="py-1 px-2 bg-theme-10 rounded-md text-xs text-white cursor-pointer font-medium"
                      >
                        {{ i18n.t("services.tandt.authorized.authorized") }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="py-1 px-2 bg-theme-24 rounded-md text-xs text-white cursor-pointer font-medium"
                    >
                      {{ i18n.t("services.tandt.locked.unknown") }}
                    </div>
                  </div>
                  <div class="font-medium text-right">
                    <DateAgo :datetime="h.message_datetime"></DateAgo>
                  </div>
                </div>

                <div v-if="h.locked_vehicle" class="mt-3 p-3 text-center">
                  <div class="py-1 px-2 font-medium">
                    {{ i18n.t("services.tandt.vehicle.name") }}
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <div class="flex flex-col items-center mr-1 md:mr-3">
                      <div class="py-1 px-2 text-xs font-medium">
                        {{ i18n.t("vehicles.plate") }}
                      </div>
                      <div class="mb-2">
                        <CreditCardIcon />
                      </div>
                      <div
                        class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
                      >
                        {{ h.locked_vehicle.plate }}
                      </div>
                    </div>
                    <div class="flex flex-col items-center mr-1 md:mr-3">
                      <div class="py-1 px-2 text-xs font-medium">
                        {{ i18n.t("vehicles.fleet") }}
                      </div>
                      <div class="mb-2">
                        <TruckIcon />
                      </div>
                      <div
                        class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
                      >
                        {{ vehicleFleet(h.locked_vehicle.fleet_id) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="h.locked == 1" class="box mt-3 p-3 text-center">
                  <div class="py-1 px-2 font-medium">
                    {{ i18n.t("services.tandt.beacon_code.name") }}
                  </div>
                  <div
                    class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
                  >
                    {{ h.beacon_code }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div
        class="btn btn-primary rounded-md w-full mt-5"
        @click="requestReport"
      >
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  Link2Icon,
} from "@zhuowenli/vue-feather-icons";

export default {
  components: { AlertCircleIcon, AlertTriangleIcon, Link2Icon },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });
    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateBeacon(beacon) {
      if (data.value == null) return;
      data.value.beacon = beacon;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.beacon.length
      )
        history.value.list.beacon.unshift(beacon);
    }
    EventBus.on("vehicle:updates:beacon", updateBeacon);

    function handleHistoryClick(history) {
      if (mapController.countMarkers("service") == 0) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("services.open_eyegeo_before"),
        });
      } else {
        mapController.flyToDate(history.message_datetime, "service");
      }
    }

    function vehicleFleet(fleet_id) {
      const fleets = store.getters["fleets/collection"];
      let obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "tandt/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;
      getHistory();
    }
    // requestReport service
    async function requestReport() {
      emit("report-request", "tandt", "tandt");
    }
    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;
      const historyResult = await store.dispatch("tandt/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }
    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
      handleHistoryClick,
      vehicleFleet,
    };
  },
};
</script>

<style></style>
