/* eslint-disable vue/no-multiple-template-root */
<template>
  <div>
    <!-- BEGIN: Top Bar -->
    <div class="top-bar-boxed w-full">
      <div class="h-full w-full flex place-items-center justify-center md:px-5">
        <!-- BEGIN: Logo -->
        <router-link to="/" class="-intro-x hidden md:flex">
          <img
            alt="Reyesol"
            class="w-40"
            src="@/assets/images/reyesol-logo-white.svg"
          />
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb mr-auto">
          <!-- <div
            v-for="breadcrumb in breadcrumbs"
            :key="breadcrumb.path"
            class="hidden lg:flex items-center"
          >
            <router-link
              :to="breadcrumb.path"
              :class="{ 'breadcrumb--active': breadcrumb.active }"
              >{{ breadcrumb.label }}</router-link
            >
            <ChevronRightIcon
              v-if="!breadcrumb.active"
              class="breadcrumb__icon"
            />
          </div> -->
        </div>
        <!-- END: Breadcrumb -->
        <!-- second bar -->
        <div class="hidden xl:block">
          <div class="h-full flex items-center justify-between flex-wrap">
            <div class="flex items-center">
              <template v-for="(type, index) in vehicleTypes" :key="type.id">
                <div v-if="getStatisticsOfType(type.code) > 0">
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    :name="`stats-${type.code}-tooltip`"
                    class="relative tooltip flex items-center h-6 md:h-8 sm:mr-8 last:mr-0"
                    :content="i18n.t(`vehicles.types.${type.code}`)"
                  >
                    <img
                      :src="
                        require(`@/assets/images/vehicles/${type.code}-white.svg`)
                      "
                      class="h-1/2"
                    />
                    <div
                      class="absolute -top-2 -right-6 text-xs rounded-full bg-white px-2 text-center font-bold text-black"
                    >
                      {{ getStatisticsOfType(type.code) }}
                    </div>
                  </Tippy>
                  <div class="tooltip-content">
                    <TippyContent
                      :to="`stats-${type.code}-tooltip`"
                      :options="{
                        interactive: true,
                      }"
                    >
                      <div
                        class="grid grid-cols-4 gap-4 py-4 px-2 justify-evenly"
                      >
                        <div
                          class="cursor-pointer"
                          @click="filterType(type.code, 0)"
                        >
                          <badge class="bg-reyesol-disconnected w-auto">{{
                            getStatisticsOfType(type.code, 0)
                          }}</badge>
                          <div class="text-center mt-1 text-sm font-bold">
                            {{ i18n.t("features.disconnected.name") }}
                          </div>
                        </div>
                        <div
                          class="cursor-pointer"
                          @click="filterType(type.code, 1)"
                        >
                          <badge class="bg-reyesol-connected w-auto">{{
                            getStatisticsOfType(type.code, 1)
                          }}</badge>
                          <div class="text-center mt-1 text-sm font-bold">
                            {{ i18n.t("features.connected.name") }}
                          </div>
                        </div>
                        <div
                          class="cursor-pointer"
                          @click="filterType(type.code, 2)"
                        >
                          <badge class="bg-reyesol-alarm w-auto">{{
                            getStatisticsOfType(type.code, 2)
                          }}</badge>
                          <div class="text-center mt-1 text-sm font-bold">
                            {{ i18n.t("features.alarm.name") }}
                          </div>
                        </div>
                        <div
                          class="cursor-pointer"
                          @click="filterType(type.code, 3)"
                        >
                          <badge class="bg-reyesol-powersafe w-auto">{{
                            getStatisticsOfType(type.code, 3)
                          }}</badge>
                          <div class="text-center mt-1 text-sm font-bold">
                            {{ i18n.t("features.powersafe.name") }}
                          </div>
                        </div>
                      </div>
                    </TippyContent>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- END: second Bar -->
        <!-- BEGIN: Map -->
        <div class="intro-x flex">
          <div class="mr-2 block md:hidden">
            <!-- fleets filter -->
            <FleetsFilter />
          </div>
          <a class="btn btn-secondary rounded-full mx-2" @click="openGlobalMap">
            <MapIcon class="w-5 h-5 mx-2" />
            <span class="hidden md:block">{{ i18n.t(`general.map`) }}</span>
          </a>
        </div>
        <!-- END: Map -->

        <!-- BEGIN: Search -->
        <GlobalSearch />
        <!-- END: Search -->

        <!-- BEGIN: Languages -->
        <LanguagesMenu :langs="langs" />
        <!-- END: Languages -->

        <!-- BEGIN: Alerts -->
        <AlertsMenu v-model:notify="notify_alerts" />
        <!-- END: Alerts -->

        <!-- BEGIN: Notifications -->
        <NotificationsMenu v-if="false" v-model:notify="notify_notifications" />
        <!-- END: Notifications -->

        <!-- BEGIN: Account Menu -->
        <div id="profile-dropdown" class="intro-x dropdown w-8 h-8 mx-2">
          <div
            class="flex items-stretch dropdown-toggle w-8 h-8 bg-white rounded-full overflow-hidden shadow-lg zoom-in scale-110"
            role="button"
            aria-expanded="false"
          >
            <div class="m-auto">
              <UserIcon />
            </div>
          </div>
          <div v-if="user" class="dropdown-menu w-56">
            <div
              class="dropdown-menu__content box bg-theme-20 dark:bg-dark-6 text-white"
            >
              <div class="flex p-4 border-b border-theme-2 dark:border-dark-3">
                <div class="flex-grow">
                  <div class="font-medium">{{ user.name }}</div>
                  <div class="text-xs text-theme-13 mt-0.5 dark:text-gray-600">
                    {{ i18n.t(`user.role.${user.role}`) }}
                  </div>
                </div>
                <div class="flex-shrink self-center cursor-pointer">
                  <SettingsIcon />
                </div>
              </div>

              <!-- customers -->
              <div v-if="max_users > 0" class="p-2">
                <router-link
                  to="/customers"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <BriefcaseIcon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.customers") }}
                </router-link>
              </div>

              <!-- users -->
              <div class="p-2">
                <router-link
                  to="/users"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <UserIcon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.users") }}
                </router-link>
              </div>

              <!-- alerts -->
              <div class="p-2">
                <router-link
                  to="/alerts"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <AlertTriangleIcon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.alerts") }}
                </router-link>
              </div>

              <!-- reports -->
              <div class="p-2">
                <router-link
                  to="/reports"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.reports") }}
                </router-link>
              </div>

              <!-- agreements -->
              <div class="p-2">
                <router-link
                  to="/agreements"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <Edit3Icon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.agreements") }}
                </router-link>
              </div>

              <!-- old data -->
              <div class="p-2 border-t border-theme-2 dark:border-dark-3">
                <a
                  href="https://v1.reyesol.com"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="hideProfileDropdown()"
                >
                  <ArchiveIcon class="w-4 h-4 mr-2" />
                  {{ i18n.t("navigation.archive") }}
                </a>
              </div>
              <!-- logout -->
              <div class="p-2 border-t border-theme-2 dark:border-dark-3">
                <a
                  href="javascript:;"
                  class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="logout"
                >
                  <LogOutIcon class="w-4 h-4 mr-2" /> Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
  </div>
</template>

<script>
import EventBus from "@/libs/event-bus";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
  computed,
} from "vue";
import { informations as informationsApi } from "@/api";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { useI18n } from "vue3-i18n";
import GlobalSearch from "@/components/global-search/GlobalSearch";
import LanguagesMenu from "@/components/language-switcher/Main";
import NotificationsMenu from "@/components/notifications/NotificationsMenu";
import AlertsMenu from "@/components/alerts/AlertsMenu";
import FleetsFilter from "@/components/fleets-filter/Main";

export default defineComponent({
  components: {
    GlobalSearch,
    NotificationsMenu,
    AlertsMenu,
    LanguagesMenu,
    FleetsFilter,
  },
  setup() {
    // refs
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();
    // UI
    const breadcrumbs = ref([]);

    const notify_alerts = ref(
      store.getters["main/notify_alerts"] == "false" ? false : true
    );

    const notify_notifications = ref(
      store.getters["main/notify_notifications"] == "false" ? false : true
    );

    watch(notify_alerts, () => {
      store.dispatch("main/setNotifyAlerts", notify_alerts.value);
    });

    watch(notify_notifications, () => {
      store.dispatch("main/setNotifyNotifications", notify_notifications.value);
    });

    const language = store.getters["main/language"];

    const langs = ref([
      {
        name: "Italiano",
        img: require("../../assets/images/flags/it.svg"),
        code: "it",
        active: false,
      },
      {
        name: "English",
        img: require("../../assets/images/flags/en.svg"),
        code: "en",
        active: false,
      },
      {
        name: "Español",
        img: require("../../assets/images/flags/es.svg"),
        code: "es",
        active: false,
      },
      {
        name: "Deutsch",
        img: require("../../assets/images/flags/de.svg"),
        code: "de",
        active: false,
      },
    ]);

    let stats_interval = null;
    let updates_interval = null;

    onMounted(() => {
      fetchStats();
      stats_interval = setInterval(fetchStats, 60000);
      fetchUpdates();
      updates_interval = setInterval(fetchUpdates, 10000);
    });

    onUnmounted(() => {
      clearInterval(stats_interval);
      clearInterval(updates_interval);
    });

    langs.value.map((l) => (l.active = l.code == language));

    function setupBreadcrumbs() {
      breadcrumbs.value = [];
      const routeParts = router.currentRoute.value.matched;
      if (routeParts.length > 1) {
        const route = routeParts[1];
        const splittedPath = route.path.split("/");
        let progressivePath = "";
        for (let j = 1; j < splittedPath.length; j++) {
          const path = splittedPath[j];
          progressivePath += `/${path}`;
          breadcrumbs.value.push({
            path: progressivePath,
            label: i18n.t(`navigation.${path}`),
            active: route.path == progressivePath,
          });
        }
      }
    }
    setupBreadcrumbs();
    watch(router.currentRoute, () => setupBreadcrumbs());

    // user infos
    const user = ref(store.getters["auth/user"]);
    const customer = ref(store.getters["auth/customer"]);

    const max_users = computed(() => {
      let max_users_conf = false;
      if (
        customer.value &&
        customer.value &&
        customer.value.configurations &&
        customer.value.configurations.max_users
      ) {
        max_users_conf = customer.value.configurations.max_users;
      }
      return max_users_conf !== false ? max_users_conf : 50;
    });

    // statistics
    const vehicleTypes = ref([]);
    const typesStatistics = ref([]);

    async function fetchStats() {
      vehicleTypes.value = (
        await store.dispatch("vehicles/getTypes")
      ).validated;
      typesStatistics.value = (
        await store.dispatch("vehicles/getTypesStatistics")
      ).validated;
    }

    // updates
    const lastUpdate = ref(dayjs());
    async function fetchUpdates() {
      const from = lastUpdate.value;
      lastUpdate.value = dayjs();
      const updatesType = (await informationsApi.updates(from.format()))
        .validated;
      if (updatesType) {
        Object.keys(updatesType).forEach((key) => {
          if (key == "notifications" && notify_notifications.value == false) {
            console.log("Skipping notifications");
            return;
          }
          if (key == "alerts" && notify_alerts.value == false) {
            console.log("Skipping alerts");
            return;
          }
          if (Array.isArray(updatesType[key])) {
            updatesType[key].forEach((v) => {
              EventBus.emit("global:updates:" + key, v);
            });
          } else {
            EventBus.emit("global:updates:" + key, updatesType[key]);
          }
        });
      }
    }

    function getStatisticsOfType(typeCode, statStatus = null) {
      const ts = typesStatistics.value.map((t) => {
        if (t.vehicle_type == typeCode) return t;
      });

      if (ts && ts.length > 0) {
        if (statStatus == null) {
          return ts.reduce(
            (o, n) => (n == null ? o : parseFloat(n.total) + o),
            0
          );
        }

        return ts.reduce(
          (o, n) =>
            n == null || n.status != statStatus ? o : parseFloat(n.total) + o,
          0
        );
      }
      return 0;
    }

    function notifyApiError({ namespace, action, response }) {
      console.log("notifyApiError", response.status);
      if (namespace == "auth") {
        store.dispatch("auth/clear");
        router.replace("/login");
      }

      switch (response.status) {
        case 400:
          EventBus.emit("notification:show", {
            type: "warning",
            title: i18n.t("api.notify.request_fails.title"),
            text: i18n.t("api.notify.request_fails.description"),
          });
          break;
        case 401:
          EventBus.emit("notification:show", {
            type: "warning",
            title: i18n.t("api.notify.request_unauth.title"),
            text: i18n.t("api.notify.request_unauth.description"),
          });
          break;
        case 403:
          EventBus.emit("notification:show", {
            type: "warning",
            title: i18n.t("api.notify.request_auth_error.title"),
            text: i18n.t("api.notify.request_auth_error.description"),
          });
          break;
        case 429:
          EventBus.emit("notification:show", {
            type: "warning",
            title: i18n.t("api.notify.too_many_requests_error.title"),
            text: i18n.t("api.notify.too_many_requests_error.description"),
          });
          break;
      }
    }

    EventBus.on("api:error", notifyApiError);

    // logout
    async function logout() {
      const result = await store.dispatch("auth/logout");
      if (result.success) router.replace("/login");
      hideProfileDropdown();
    }

    // global map
    function openGlobalMap() {
      EventBus.emit("global-map:refresh");
    }

    // profile dropdown
    function hideProfileDropdown() {
      cash("#profile-dropdown").dropdown("hide");
    }

    function filterType(type, status) {
      let service = "type_" + type;
      let feature =
        status == 1 ? "connected" : status == 0 ? "disconnected" : "alarm";
      store.dispatch("globalMap/setRequestedService", service);
      store.dispatch("globalMap/setRequestedFeature", feature);
      router.push({
        path: "/global-map",
        query: { f: feature, s: service },
      });
    }

    return {
      i18n,
      user,
      notify_alerts,
      notify_notifications,
      langs,
      customer,
      max_users,
      logout,
      breadcrumbs,
      vehicleTypes,
      getStatisticsOfType,
      openGlobalMap,
      filterType,
      hideProfileDropdown,
    };
  },
});
</script>
