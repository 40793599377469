<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Reyesol"
              class="h-6"
              :src="require(`@/assets/images/reyesol-logo-white.svg`)"
            />
          </a>
          <div class="my-auto">
            <!-- <img alt="Reyesol" class="-intro-x w-1/2 -mt-16" :src="require(`@/assets/images/illustration.svg`)" /> -->
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              {{ $t("login.welcome") }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <div class="intro-x xl:hidden flex justify-center mb-4">
              <img
                alt="Reyesol"
                class="h-8"
                :src="require(`@/assets/images/reyesol-logo-black.svg`)"
              />
            </div>
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              <span v-if="isLogin">{{ $t("login.sign_in") }}</span>
              <span v-else>{{ $t("login.forgot") }}</span>
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              {{ $t("login.welcome") }}
            </div>
            <h3
              v-if="loginMessage"
              class="pt-3 text-center font-bold m-2 text-red-800"
            >
              {{ loginMessage }}
            </h3>
            <div class="intro-x mt-8">
              <input
                v-model="v$.email.$model"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :class="{ 'border-theme-24': v$.email.$error && hasErrors }"
                placeholder="Email"
                @keypress.enter="login"
              />
              <div v-if="hasErrors">
                <div
                  v-for="error in v$.email.$errors"
                  :key="error.$uid"
                  class="text-theme-24 mt-2"
                >
                  {{ $t(`validation.${error.$validator}`) }}
                </div>
              </div>

              <input
                v-if="isLogin"
                v-model="v$.password.$model"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{ 'border-theme-24': v$.password.$error && hasErrors }"
                placeholder="Password"
                @keypress.enter="login"
              />
              <div v-if="hasErrors">
                <div
                  v-for="error in v$.password.$errors"
                  :key="error.$uid"
                  class="text-theme-24 mt-2"
                >
                  {{ $t(`validation.${error.$validator}`) }}
                </div>
              </div>
            </div>
            <div
              v-if="isLogin"
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4 cursor-pointer"
              @click="isLogin = false"
            >
              <!-- <div class="flex items-center mr-auto">
                <input id="remember-me" type="checkbox" class="form-check-input border mr-2" />
                <label class="cursor-pointer select-none" for="remember-me">{{ $t("login.remember") }}</label>
              </div> -->
              {{ $t("login.forgot") }}
            </div>
            <div
              v-else
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4 cursor-pointer"
              @click="isLogin = true"
            >
              <!-- <div class="flex items-center mr-auto">
                <input id="remember-me" type="checkbox" class="form-check-input border mr-2" />
                <label class="cursor-pointer select-none" for="remember-me">{{ $t("login.remember") }}</label>
              </div> -->
              {{ $t("login.try_login") }}
            </div>

            <div
              v-if="isLogin"
              class="intro-x mt-5 xl:mt-8 text-center xl:text-left"
            >
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                @click="login"
              >
                <loading-icon
                  v-if="loading"
                  icon="three-dots"
                  color="white"
                  class="mr-2"
                />
                {{ $t("login.login_btn") }}
              </button>
            </div>
            <div v-else class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-auto xl:mr-3 align-top"
                @click="remember"
              >
                <loading-icon
                  v-if="loading"
                  icon="three-dots"
                  color="white"
                  class="mr-2"
                />
                {{ $t("login.remember_btn") }}
              </button>
            </div>
            <!-- <div class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
              By signin up, you agree to our <br />
              <a class="text-theme-17 dark:text-gray-300" href="">Terms and Conditions</a>
              &
              <a class="text-theme-17 dark:text-gray-300" href="">Privacy Policy</a>
            </div> -->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import EventBus from "@/libs/event-bus";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const isLogin = ref(true);
    const loginMessage = ref(null);
    const loading = ref(false);
    const hasErrors = ref(false);
    const state = reactive({
      email: "",
      password: "",
    });

    // validation
    const rules = {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, state);

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });

    async function login() {
      loginMessage.value = null;
      loading.value = true;
      hasErrors.value = false;
      v$.value.$touch();
      if (v$.value.$invalid) {
        loading.value = false;
        hasErrors.value = true;
        return;
      }

      const result = await store.dispatch("auth/login", {
        email: v$.value.email.$model,
        password: v$.value.password.$model,
      });

      if (!result.success) {
        switch (result.status) {
          case 400:
            EventBus.emit("notification:show", {
              type: "warning",
              title: i18n.t("api.notify.request_fails.title"),
              text: i18n.t("api.notify.request_fails.description"),
            });
            break;
          case 401:
            EventBus.emit("notification:show", {
              type: "warning",
              title: i18n.t("api.notify.request_unauth.title"),
              text: i18n.t("api.notify.request_unauth.description"),
            });
            break;
          case 403:
            EventBus.emit("notification:show", {
              type: "warning",
              title: i18n.t("api.notify.request_auth_error.title"),
              text: i18n.t("api.notify.request_auth_error.description"),
            });
            break;
          case 429:
            EventBus.emit("notification:show", {
              type: "warning",
              title: i18n.t("api.notify.too_many_requests_error.title"),
              text: i18n.t("api.notify.too_many_requests_error.description"),
            });
            break;
        }
      }

      if (!result.success && result.status == 422) {
        hasErrors.value = true;
        // TODO
      }

      if (store.getters["auth/token"]) {
        const user = store.getters["auth/user"];
        if (!user) {
          await store.dispatch("auth/loadUserAndCustomerInfos");
        }
        await store.dispatch("agreements/getCollection");
        await store.dispatch("alerts/getCollection");
        await store.dispatch("alerts/getChannels");
        i18n.setLocale(store.getters["main/language"]);
      }
      loading.value = false;

      if (result.success) router.replace("/");
    }

    async function remember() {
      loginMessage.value = null;
      loading.value = true;
      hasErrors.value = false;
      v$.value.password.$model = "x";

      v$.value.$touch();
      if (v$.value.$invalid) {
        loading.value = false;
        hasErrors.value = true;
        return;
      }

      v$.value.password.$model = "";

      const result = await store.dispatch("auth/remember", {
        email: v$.value.email.$model,
      });

      if (!result.success && result.status >= 400) {
        hasErrors.value = true;
        EventBus.emit("notification:show", {
          type: "error",
          title: result.data?.message || result.errorMessage,
        });
      }

      if (!result.success && result.status == 422) {
        hasErrors.value = true;
        // TODO
      }

      loading.value = false;

      if (result.success) {
        isLogin.value = true;

        loginMessage.value = i18n.t("login.loginMessage");
      }
    }

    return {
      v$,
      loading,
      hasErrors,
      login,
      remember,
      isLogin,
      loginMessage,
    };
  },
});
</script>
