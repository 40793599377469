import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import dayjs from "dayjs";
const namespace = "services/eyeway";
const api = {
  getDashboard: async function () {
    try {
      const response = await axios.get(`${namespace}`);
      return handleSuccess({ namespace, action: "getDashboard" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getDashboard" }, error.response);
    }
  },
  getVehicleData: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "getVehicleData" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleData" },
        error.response
      );
    }
  },
  getVehicleHistory: async function (id, from, to) {
    try {
      const response = await axios.get(`${namespace}/${id}/history`, {
        params: {
          period_from: dayjs(from).format(),
          period_to: dayjs(to).format(),
        },
      });
      return handleSuccess(
        { namespace, action: "getVehicleHistory" },
        response
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleHistory" },
        error.response
      );
    }
  },
};
export { api as default };
