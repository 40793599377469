import { getInstance } from "@/libs/axios";
const axios = getInstance();

const geoSearch = async function (query) {
  try {
    const result = await axios.get(
      `https://eu1.locationiq.com/v1/autocomplete.php?key=pk.f51c62d27506379c0daeb08bc3b54d03&q=${query}&format=json&limit=10`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const geoReverseSearch = async function (lat, lng) {
  try {
    if (lat && lng) {
      const result = await axios.get(
        `https://eu1.locationiq.com/v1/reverse.php?key=pk.f51c62d27506379c0daeb08bc3b54d03&lat=${lat}&lon=${lng}&format=json`
      );
      return result.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const geoRouting = async function (coordinates, exclusions) {
  let tripCoord = "";
  let excluded = "";
  coordinates.forEach((element, index) => {
    tripCoord = tripCoord.concat(element.lng, ",");
    if (index == coordinates.length - 1) {
      tripCoord = tripCoord.concat(element.lat);
    } else {
      tripCoord = tripCoord.concat(element.lat, ";");
    }
  });
  console.log("exex:", exclusions);
  if (exclusions != null) {
    exclusions.forEach((element, index) => {
      console.log(element.value, element.name);
      if (element.value == true) {
        excluded = excluded.concat("&exclude=", element.name);
      }
    });
  }
  try {
    const result = await axios.get(
      `https://eu1.locationiq.com/v1/directions/driving/${tripCoord}?key=pk.f51c62d27506379c0daeb08bc3b54d03&alternatives=true&overview=full${excluded}`
    );
    console.log(result);
    if (result.status == 200) {
      return result.data;
    } else {
      console.error(result.status);
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export { geoSearch, geoReverseSearch, geoRouting };
