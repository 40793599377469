import { eyegeo as api } from "@/api";

const state = () => {
  return {
    data: [],
    trips: [],
    history: [],
    trip_history: [],
  };
};

// getters
const getters = {
  vehicleData: (state) => (vehicleId) => {
    return state.data.find((d) => d.vehicle_id == vehicleId);
  },
  vehicleHistory: (state) => (vehicleId) => {
    return state.history.find((h) => h.vehicle_id == vehicleId);
  },
  vehicleTripHistory: (state) => (vehicleId) => {
    return state.trip_history.find((h) => h.vehicle_id == vehicleId);
  },
  vehicleTrip: (state) => (vehicleId, tripId) => {
    return state.trips.find((t) => t.vehicle_id == vehicleId && t.id == tripId);
  },
};

// actions
const actions = {
  async getVehicleData({ dispatch, getters }, vehicleId) {
    const cachedData = getters.vehicleData(vehicleId);
    if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleData", vehicleId);
  },

  async fetchVehicleData({ commit, getters }, vehicleId) {
    const result = await api.getVehicleData(vehicleId);
    if (result.success) {
      commit("REMOVE_VEHICLE_DATA", vehicleId);
      commit("PUSH_VEHICLE_DATA", { vehicle_id: vehicleId, ...result.data });
    }
    result.validated = getters.vehicleData(vehicleId);
    return result;
  },

  async getVehicleHistory({ dispatch }, vehicleId) {
    // const cachedData = getters.vehicleHistory(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleHistory", vehicleId);
  },

  async getVehicleTripHistory({ dispatch }, vehicleId) {
    // const cachedData = getters.vehicleHistory(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleTripHistory", vehicleId);
  },

  async getVehicleTrip({ dispatch }, vehicleData) {
    // const cachedData = getters.vehicleHistory(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleTrip", vehicleData);
  },

  async fetchVehicleTrip({ commit, getters }, { vehicleId, tripId }) {
    const result = await api.getVehicleTrip(vehicleId, tripId);
    result.validated = [];
    if (result.success) {
      commit("REMOVE_VEHICLE_TRIP", vehicleId, tripId);
      commit("PUSH_VEHICLE_TRIP", result.data);
      result.validated = getters.vehicleTrip(vehicleId, tripId);
    }
    return result;
  },

  async fetchVehicleHistory(
    { commit, getters },
    { vehicleId, dateFrom, dateTo }
  ) {
    const result = await api.getVehicleHistory(vehicleId, dateFrom, dateTo);
    result.validated = [];
    if (result.success) {
      commit("REMOVE_VEHICLE_HISTORY", vehicleId);
      commit("PUSH_VEHICLE_HISTORY", {
        vehicle_id: vehicleId,
        list: result.data,
      });
      result.validated = getters.vehicleHistory(vehicleId);
    }
    return result;
  },
  async fetchVehicleTripHistory(
    { commit, getters },
    { vehicleId, dateFrom, dateTo }
  ) {
    const result = await api.getVehicleTripHistory(vehicleId, dateFrom, dateTo);
    result.validated = [];
    if (result.success) {
      commit("REMOVE_VEHICLE_TRIP_HISTORY", vehicleId);
      commit("PUSH_VEHICLE_TRIP_HISTORY", {
        vehicle_id: vehicleId,
        list: result.data,
      });
      result.validated = getters.vehicleTripHistory(vehicleId);
    }
    return result;
  },
};

// mutations
const mutations = {
  PUSH_VEHICLE_DATA(state, vehicleData) {
    state.data.push(vehicleData);
  },
  REMOVE_VEHICLE_DATA(state, vehicleId) {
    const i = state.data.findIndex((d) => d.vehicle_id == vehicleId);
    if (i != -1) state.data.splice(i, 1);
  },
  PUSH_VEHICLE_TRIP(state, vehicleData) {
    state.trips.push(vehicleData);
  },
  REMOVE_VEHICLE_TRIP(state, vehicleId, tripId) {
    const i = state.trips.findIndex(
      (t) => t.vehicle_id == vehicleId && t.id == tripId
    );
    if (i != -1) state.trips.splice(i, 1);
  },
  PUSH_VEHICLE_TRIP_HISTORY(state, vehicleHistory) {
    state.trip_history.push(vehicleHistory);
  },
  REMOVE_VEHICLE_TRIP_HISTORY(state, vehicleId) {
    const i = state.trip_history.findIndex((h) => h.vehicle_id == vehicleId);
    if (i != -1) state.trip_history.splice(i, 1);
  },
  PUSH_VEHICLE_HISTORY(state, vehicleHistory) {
    state.history.push(vehicleHistory);
  },
  REMOVE_VEHICLE_HISTORY(state, vehicleId) {
    const i = state.history.findIndex((h) => h.vehicle_id == vehicleId);
    if (i != -1) state.history.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
