<template>
  <Chart
    type="line"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import dayjs from "dayjs";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    temperature: {
      type: Array,
      default: () => [],
    },
    fridge: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const datasets = ref([]);
    const labels = ref([]);
    const data = reactive({
      labels: computed(() => Array.from(new Set(labels.value))),
      datasets: computed(() => datasets.value),
    });

    function init() {
      labels.value = [];
      datasets.value = [];
      setTemperatureData();
      setFridgeData();
    }
    watch(() => props, init, { deep: true });
    init();

    function setTemperatureData() {
      if (!props.active || !props.temperature) return;

      const temps = props.temperature.map((item) => {
        const datetime = dayjs(item.message_datetime).format();
        return {
          t1: { x: datetime, y: item.T1 ?? null },
          t2: { x: datetime, y: item.T2 ?? null },
          t3: { x: datetime, y: item.T3 ?? null },
          t4: { x: datetime, y: item.T4 ?? null },
        };
      });

      // Temperature 1
      const data_t1 = temps.map((t) => t.t1).filter((t) => t.y);
      if (data_t1.length > 0) {
        datasets.value.push({
          label: "T1",
          data: data_t1,
          borderWidth: 2,
          borderColor: "#537bc4",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // Temperature 2
      const data_t2 = temps.map((t) => t.t2).filter((t) => t.y);
      if (data_t2.length > 0) {
        datasets.value.push({
          label: "T2",
          data: data_t2,
          borderWidth: 2,
          borderColor: "#acc236",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // Temperature 3
      const data_t3 = temps.map((t) => t.t3).filter((t) => t.y);
      if (data_t3.length > 0) {
        datasets.value.push({
          label: "T3",
          data: data_t3,
          borderWidth: 2,
          borderColor: "#166a8f",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // Temperature 4
      const data_t4 = temps.map((t) => t.t4).filter((t) => t.y);
      if (data_t4.length > 0) {
        datasets.value.push({
          label: "T4",
          data: data_t4,
          borderWidth: 2,
          borderColor: "#00a950",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }
    }

    function setFridgeData() {
      if (!props.active || !props.fridge) return;

      const sets = props.temperature.map((item) => {
        const datetime = dayjs(item.message_datetime).format();
        return {
          set1: { x: datetime, y: item.setpoint_t1 ?? null },
          set2: { x: datetime, y: item.setpoint_t2 ?? null },
          set3: { x: datetime, y: item.setpoint_t3 ?? null },
          onOff: { x: datetime, y: item.overall_status == 1 ? "ON" : "OFF" },
          alarm: {
            x: datetime,
            y:
              item.alarm_status == 1
                ? "ALARM"
                : item.alarm_status == 2
                ? "STOP"
                : "NONE",
          },
        };
      });

      // Setpoint 1
      const data_s_t1 = sets.map((t) => t.set1).filter((t) => t.y);
      if (data_s_t1.length > 0) {
        datasets.value.push({
          label: "Setpoint 1",
          data: data_s_t1,
          borderWidth: 2,
          borderColor: "#4dc9f6",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // Setpoint 2
      const data_s_t2 = sets.map((t) => t.set2).filter((t) => t.y);
      if (data_s_t2.length > 0) {
        datasets.value.push({
          label: "Setpoint 2",
          data: data_s_t2,
          borderWidth: 2,
          borderColor: "#f67019",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // Setpoint 3
      const data_s_t3 = sets.map((t) => t.set3).filter((t) => t.y);
      if (data_s_t3.length > 0) {
        datasets.value.push({
          label: "Setpoint 3",
          data: data_s_t3,
          borderWidth: 2,
          borderColor: "#f53794",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }

      // On off
      const data_on_off = sets.map((t) => t.onOff);
      if (data_on_off.length > 0) {
        datasets.value.push({
          label: "On/Off",
          data: data_on_off,
          borderWidth: 1,
          borderColor: "#8549ba",
          backgroundColor: "transparent",
          stepped: true,
          yAxisID: "y2",
        });
      }

      // Alarm
      const data_alarm = sets.map((t) => t.alarm);
      if (data_alarm.length > 0) {
        datasets.value.push({
          label: "Allarmi",
          data: data_alarm,
          borderWidth: 1,
          borderColor: "#cbd5e0",
          backgroundColor: "transparent",
          stepped: true,
          yAxisID: "y3",
        });
      }
    }

    const options = {
      plugins: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          enabled: true,
          mode: "xy",
        },
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "hour",
            },
            title: {
              display: true,
              text: "Orario",
            },
            ticks: {
              display: true,
              autoSkip: false,
              maxRotation: 0,
              major: {
                enabled: true,
              },
              font: function (context) {
                if (context.tick && context.tick.major) {
                  return {
                    weight: "bold",
                  };
                }
              },
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            position: "left",
            stack: "temps",
            lineTension: 0.8,
            stackWeight: 3,
            grid: {
              borderColor: "#777700",
            },
          },
          {
            id: "y2",
            type: "category",
            labels: ["ON", "OFF"],
            offset: true,
            position: "left",
            stack: "temps",
            stackWeight: 1,
            grid: {
              borderColor: "#777777",
            },
          },
          {
            id: "y3",
            type: "category",
            labels: ["ALARM", "STOP", "NONE"],
            offset: true,
            position: "left",
            stack: "temps",
            stackWeight: 2,
            grid: {
              borderColor: "#D8D8D8",
            },
          },
        ],
      },
    };

    return {
      data,
      options,
    };
  },
};
</script>
