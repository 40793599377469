<template>
  <div id="alerts">
    <div class="flex justify-between py-2">
      <search-title
        :placeholder="i18n.t('vehicles.search-by-plate')"
        @search="updateSearchQuery"
        >{{ i18n.t("navigation.alerts") }}</search-title
      >
    </div>

    <div class="grid md:grid-cols-3 gap-6">
      <!-- vehicles -->
      <div>
        <h2 class="text-lg font-bold mb-4 capitalize">
          {{ i18n.t("general.vehicles") }}
        </h2>

        <!-- select all -->
        <div class="box p-6 py-2 mb-4 bg-theme-34">
          <div class="flex items-center space-x-4">
            <div class="form-check">
              <input
                v-model="allSelected"
                class="form-check-input"
                type="checkbox"
                @click="toggleSelectAll"
              />
            </div>
            <div class="text-base text-white">
              {{ i18n.t("general.select-all") }}
            </div>
          </div>
        </div>

        <!-- collection -->
        <div v-if="!filteredVehicles.length" class="pt-6 text-lg text-center">
          {{ i18n.t("general.no-results") }}
        </div>

        <div v-else class="flex flex-col h-96 overflow-auto">
          <div
            v-for="v in filteredVehicles"
            :key="v.id"
            class="box p-6 mb-4 cursor-pointer"
            @click="v.selected = !v.selected"
          >
            <div class="flex flex-wrap items-center space-x-4">
              <div class="form-check">
                <input
                  v-model="v.selected"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
              <div>
                <div class="text-xs capitalize">
                  {{ i18n.t("vehicles.plate") }}
                </div>
                <div class="text-sm">{{ v.plate }}</div>
              </div>
              <div>
                <div class="text-xs capitalize">
                  {{ i18n.t("vehicles.fleet") }}
                </div>
                <div class="text-sm">{{ v.fleet.code }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- configurations -->
      <div class="md:col-span-2">
        <h2 class="text-lg font-bold mb-4 capitalize">
          {{ i18n.t("general.alert-config") }}
        </h2>

        <!-- actions -->
        <div class="grid grid-cols-1 lg:grid-cols-2 items-stretch gap-2 mb-4">
          <div class="block lg:col-span-2 text-xs capitalize">
            {{
              `${selectedVehicles.length} ${i18n.t(
                "general.n-vehicles-selected"
              )}`
            }}
          </div>
          <button
            class="flex justify-between btn btn-primary mt-2 ml-0"
            :disabled="!selectedVehicles.length"
            @click="createAlertMassive()"
          >
            <PlusIcon class="w-8 h-8 mr-2" />
            <span class="flex-grow"> {{ i18n.t("alerts.assign-all") }}</span>
          </button>
          <button
            class="flex justify-between btn btn-danger mt-2"
            :disabled="!selectedVehicles.length"
            @click="removeAllMassive()"
          >
            <Trash2Icon class="w-8 h-8 mr-2" />
            <span class="flex-grow">{{
              i18n.t("alerts.reset-all-config")
            }}</span>
          </button>
        </div>

        <!-- collection -->
        <div v-if="!selectedVehicles.length" class="pt-6 text-lg text-center">
          {{ i18n.t("general.no-selection") }}
        </div>

        <div v-else class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div
            v-for="v in selectedVehicles"
            :key="v.id"
            class="intro-x report-box mb-3"
          >
            <div class="box p-4">
              <div class="flex items-baseline space-x-2 mb-2">
                <div class="text-xs">
                  {{ i18n.t("alerts.vehicle-config") }}
                </div>
                <div class="text-sm font-bold">{{ v.plate }}</div>
              </div>
              <div v-if="!v.configs.length">
                <div class="text-xs mb-2">{{ i18n.t("alerts.no-config") }}</div>
              </div>
              <div v-else>
                <div
                  v-for="c in v.configs"
                  :key="c.id"
                  class="rounded-md p-1 flex text-gray-900 mb-1"
                  :class="
                    c.alert?.severity == 1 ? 'bg-theme-23' : 'bg-theme-24'
                  "
                >
                  <MailIcon
                    v-if="c.channel?.code === 'email'"
                    class="w-8 h-8 flex-none"
                  />
                  <MessageSquareIcon
                    v-if="c.channel?.code === 'sms'"
                    class="w-8 h-8 flex-none"
                  />

                  <div class="overflow-hidden flex-grow">
                    <p class="text-xs font-medium truncate">
                      {{ c.alert?.description }}
                    </p>
                    <div class="text-xs truncate">{{ c.destination }}</div>
                  </div>

                  <div class="w-8 h-8 flex-none">
                    <button
                      class="btn btn-primary p-1"
                      @click="
                        editAlert(
                          c.id,
                          v.id,
                          c.alert?.id,
                          c.channel?.id,
                          c.destination
                        )
                      "
                    >
                      <Edit2Icon />
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex flex-row flex-wrap gap-2">
                <button
                  class="flex-grow flex justify-between btn btn-primary mt-1"
                  @click="createAlert(v.id)"
                >
                  <PlusIcon class="mr-2" />
                  <div class="flex-grow">{{ i18n.t("alerts.add-config") }}</div>
                </button>
                <button
                  class="flex-grow flex justify-between btn btn-danger mt-1"
                  @click="removeAll(v.id)"
                >
                  <Trash2Icon class="mr-2" />
                  <div class="flex-grow">
                    {{ i18n.t("alerts.reset-config") }}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <teleport to="body">
      <div
        v-show="showModal"
        class="fixed inset-0 z-50 h-full w-full bg-gray-900 bg-opacity-70 flex items-center justify-center transition-all p-8 lg:p-20"
      >
        <div class="bg-white rounded-xl shadow-lg p-8 w-full">
          <h1 class="capitalize text-lg font-bold mb-4">
            {{ i18n.t(`alerts.form-title-${editForm.type}`) }}
          </h1>

          <div class="mb-4 space-y-4">
            <div>
              <label>{{ i18n.t("alerts.alert") }}</label>
              <validated-input
                :has-error="editForm$.alertId.$error"
                :errors="editForm$.alertId.$errors"
              >
                <VueMultiselect
                  v-model="editForm$.alertId.$model"
                  :options="
                    alerts.map((a) => ({
                      name: a.description,
                      value: a.id,
                    }))
                  "
                  :multiple="editForm.type != 'edit'"
                  :close-on-select="editForm.type == 'edit'"
                  :placeholder="i18n.t('alerts.alert')"
                  label="name"
                  track-by="name"
                />
              </validated-input>
            </div>

            <div>
              <label>{{ i18n.t("alerts.channel") }}</label>
              <validated-input
                :has-error="editForm$.channelId.$error"
                :errors="editForm$.channelId.$errors"
              >
                <VueMultiselect
                  v-model="editForm$.channelId.$model"
                  :options="
                    channels.map((c) => ({
                      name: c.code,
                      value: c.id,
                    }))
                  "
                  :close-on-select="true"
                  :placeholder="i18n.t('alerts.channel')"
                  label="name"
                  track-by="name"
                />
              </validated-input>
            </div>

            <div>
              <label>{{ i18n.t("alerts.destination") }}</label>
              <div v-if="editForm.type == 'edit'">
                <DestinationInput
                  v-model="editForm$.destination.$model"
                  :disabled="sending"
                  :channel-id="editForm$.channelId.$model?.value"
                  :channels="channels"
                  :required="true"
                />
              </div>
              <div v-else>
                <DestinationInput
                  v-for="(d, i) in destinations"
                  :key="i"
                  v-model="destinations[i]"
                  :disabled="sending"
                  :channel-id="editForm$.channelId.$model?.value"
                  :channels="channels"
                  @input="handleDestinationsChange"
                />
                <validated-input
                  :has-error="editForm$.destination.$error"
                  :errors="editForm$.destination.$errors"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end space-x-4">
            <button
              class="btn btn-secondary"
              :disabled="sending"
              @click="hideModal"
            >
              {{ i18n.t("general.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              :disabled="sending"
              @click="askSendConfig"
            >
              <loading-icon
                v-if="sending && !deleting"
                icon="three-dots"
                color="white"
              />
              <span v-else>
                {{ i18n.t("general.send-config") }}
              </span>
            </button>
            <button
              v-if="editForm.type === 'edit'"
              class="btn btn-danger"
              :disabled="sending"
              @click="askDeleteConfig"
            >
              <loading-icon v-if="deleting" icon="three-dots" color="white" />
              <TrashIcon v-else />
            </button>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useCollectionSearch } from "@/composables/collection-search";
import EventBus from "@/libs/event-bus";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DestinationInput from "./DestinationInput.vue";

export default {
  components: { DestinationInput },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: filteredVehicles,
    } = useCollectionSearch(["plate", "fleet.code"]);
    collectionToFilter.value = [];

    const alerts = store.getters["alerts/collection"];
    const channels = store.getters["alerts/channels"];

    const vehicles = ref([]);
    const fleets = ref([]);
    const alertConfigs = ref([]);
    async function fetchData() {
      const v = store.dispatch("vehicles/getCollection");
      const f = store.dispatch("fleets/getCollection");
      const ac = store.dispatch("alerts/getConfigCollection");
      const results = await Promise.all([v, f, ac]);
      vehicles.value = results[0].validated;
      fleets.value = results[1].validated;
      alertConfigs.value = results[2].validated;
    }
    fetchData();

    async function refreshData() {
      const selectedIds = [...selectedVehicles.value.map((v) => v.id)];
      await fetchData();
      collectionToFilter.value.forEach(
        (v) => (v.selected = selectedIds.includes(v.id))
      );
    }

    collectionToFilter.value = [];
    watch(alertConfigs, () => {
      const vehiclesWithConfigs = [];
      for (let i = 0; i < vehicles.value.length; i++) {
        const vehicle = vehicles.value[i];
        vehiclesWithConfigs.push({
          selected: false,
          ...vehicle,
          fleet: fleets.value.find((f) => f.id === vehicle.fleet_id) ?? {
            code: "",
          },
          configs: alertConfigs.value
            .filter((ac) => ac.vehicle_id === vehicle.id)
            .map((ac) => {
              ac.alert = alerts.find((a) => a.id === ac.alert_id);
              ac.channel = channels.find((c) => c.id === ac.alert_channel_id);
              return ac;
            }),
        });
      }
      collectionToFilter.value = vehiclesWithConfigs;
    });

    // selection
    const allSelected = computed(
      () => collectionToFilter.value.length === selectedVehicles.value.length
    );

    function toggleSelectAll() {
      if (allSelected.value) {
        filteredVehicles.value.forEach((v) => (v.selected = false));
        return;
      }
      filteredVehicles.value.forEach((v) => (v.selected = true));
    }

    const selectedVehicles = computed(() => {
      return filteredVehicles.value.filter((v) => v.selected);
    });

    // create and edit
    const sending = ref(false);
    const deleting = ref(false);
    const showModal = ref(false);
    const editForm = reactive({
      type: "edit",
      configId: null,
      vehicleId: null,
      alertId: null,
      channelId: null,
      destination: null,
    });

    const destinations = ref([""]);

    function handleDestinationsChange() {
      const d = destinations.value.filter(
        (destination) => destination && destination != ""
      );
      d.push("");
      destinations.value = d;
    }

    const validationRules = computed(() => {
      return {
        vehicleId: { required },
        alertId: { required },
        channelId: { required },
        destination: { required },
      };
    });

    const editForm$ = useVuelidate(validationRules, editForm);

    function hideModal() {
      if (sending.value) return;
      showModal.value = false;
    }

    function editAlert(configId, vehicleId, alertId, channelId, destination) {
      editForm.type = "edit";
      editForm.configId = configId;
      editForm.vehicleId = vehicleId;
      editForm.alertId = {
        name: alerts.find((a) => a.id == alertId)?.description,
        value: alertId,
      };
      editForm.channelId = {
        name: channels.find((c) => c.id == channelId)?.code,
        value: channelId,
      };
      editForm.destination = destination;
      showModal.value = true;
    }

    function removeAll(vehicleId) {
      EventBus.emit("confirm-dialog:show", {
        title: i18n.t("alerts.confirm-remove-all-title"),
        callback: async (confirm) => {
          if (confirm) removeAllConfig(vehicleId);
        },
      });
    }

    async function removeAllConfig(vehicleId) {
      sending.value = true;
      deleting.value = true;

      const result = await store.dispatch("alerts/removeAll", vehicleId);

      if (result.success) {
        await refreshData();
        EventBus.emit("notification:show", {
          type: "success",
          title: i18n.t("alerts.delete-config-ok"),
        });
      } else {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("general.server-error"),
        });
      }

      sending.value = false;
      deleting.value = false;
      hideModal();
    }

    function removeAllMassive() {
      EventBus.emit("confirm-dialog:show", {
        title: i18n.t("alerts.confirm-remove-all-massive-title"),
        callback: async (confirm) => {
          if (confirm) removeAllMAssiveConfig();
        },
      });
    }

    async function removeAllMAssiveConfig() {
      sending.value = true;
      deleting.value = true;

      const result = await store.dispatch(
        "alerts/removeAllMassive",
        selectedVehicles.value.map((v) => v.id)
      );

      if (result.success) {
        await refreshData();
        EventBus.emit("notification:show", {
          type: "success",
          title: i18n.t("alerts.delete-config-ok"),
        });
      } else {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("general.server-error"),
        });
      }

      sending.value = false;
      deleting.value = false;
      hideModal();
    }

    function createAlert(vehicle) {
      editForm.type = "create";
      editForm.configId = null;
      editForm.vehicleId = vehicle;
      editForm.alertId = null;
      editForm.channelId = null;
      editForm.destination = null;
      destinations.value = [""];
      showModal.value = true;
    }

    function createAlertMassive() {
      editForm.type = "massive";
      editForm.configId = null;
      editForm.vehicleId = selectedVehicles.value.map((v) => v.id);
      editForm.alertId = null;
      editForm.channelId = null;
      editForm.destination = null;
      destinations.value = [""];
      showModal.value = true;
    }

    watch(
      [() => editForm.alertId, () => editForm.channelId, () => destinations],
      () => fillDestionationFromArray(),
      { deep: true }
    );

    function fillDestionationFromArray() {
      if (editForm.type != "edit")
        editForm.destination = destinations.value.find((d) => d && d != "");
    }

    function askSendConfig() {
      fillDestionationFromArray();

      editForm$.value.$touch();
      if (editForm$.value.$error) {
        return;
      }

      EventBus.emit("confirm-dialog:show", {
        title: i18n.t("alerts.confirm-send-title"),
        callback: async (confirm) => {
          if (confirm) sendConfig();
        },
      });
    }

    async function sendConfig() {
      sending.value = true;

      let result = null;

      if (editForm.type === "edit") {
        result = await store.dispatch("alerts/updateConfig", {
          id: editForm.configId,
          vehicle_id: editForm.vehicleId,
          alert_id: editForm.alertId.value,
          alert_channel_id: editForm.channelId.value,
          destination: editForm.destination,
        });
      }

      if (editForm.type === "create") {
        for (let k = 0; k < destinations.value.length; k++) {
          const destination = destinations.value[k];
          if (destination == "") continue;
          for (let j = 0; j < editForm.alertId.length; j++) {
            const alertId = editForm.alertId[j].value;
            await store.dispatch("alerts/storeConfig", {
              vehicle_id: editForm.vehicleId,
              alert_id: alertId,
              alert_channel_id: editForm.channelId.value,
              destination: destination,
            });
          }
        }
        result = { success: true }; // always true on create
      }

      if (editForm.type === "massive" && editForm.vehicleId.length) {
        await store.dispatch("alerts/storeConfigMassive", {
          vehicles: editForm.vehicleId,
          alerts: editForm.alertId.map((alertItem) => {
            return alertItem.value;
          }),
          alert_channel_id: editForm.channelId.value,
          destinations: destinations.value.filter(function (entry) {
            return /\S/.test(entry);
          }),
        });
        result = { success: true }; // always true on massive
      }

      if (result.success) {
        await refreshData();
        EventBus.emit("notification:show", {
          type: "success",
          title: i18n.t("alerts.add-config-ok"),
        });
      } else {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("general.server-error"),
        });
      }

      sending.value = false;
      hideModal();
    }

    function askDeleteConfig() {
      EventBus.emit("confirm-dialog:show", {
        title: i18n.t("alerts.confirm-delete-title"),
        text: i18n.t("alerts.confirm-delete-text"),
        confirmText: i18n.t("general.delete"),
        confirmColor: "danger",
        cancelText: i18n.t("general.cancel"),
        callback: async (confirm) => {
          if (confirm) deleteConfig();
        },
      });
    }

    async function deleteConfig() {
      sending.value = true;
      deleting.value = true;

      const result = await store.dispatch(
        "alerts/deleteConfig",
        editForm.configId
      );

      if (result.success) {
        await refreshData();
        EventBus.emit("notification:show", {
          type: "success",
          title: i18n.t("alerts.delete-config-ok"),
        });
      } else {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("general.server-error"),
        });
      }

      sending.value = false;
      deleting.value = false;
      hideModal();
    }

    return {
      i18n,
      updateSearchQuery,
      alerts,
      fleets,
      alertConfigs,
      channels,
      allSelected,
      toggleSelectAll,
      filteredVehicles,
      selectedVehicles,
      editForm,
      editForm$,
      showModal,
      hideModal,
      editAlert,
      removeAll,
      removeAllMassive,
      createAlert,
      createAlertMassive,
      destinations,
      handleDestinationsChange,
      sending,
      deleting,
      askSendConfig,
      askDeleteConfig,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-check-input {
  &:focus {
    box-shadow: none !important;
  }

  &:checked {
    background-color: rgba(17, 34, 51, var(--tw-bg-opacity)) !important;
  }
}
</style>
