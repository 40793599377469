import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";
const namespace = "informations";
const api = {
  search: async function (query) {
    try {
      const response = await axios.post("search", { query });
      return handleSuccess({ namespace, action: "search" }, response);
    } catch (error) {
      return handleError({ namespace, action: "search" }, error.response);
    }
  },
  updates: async function (from) {
    try {
      const response = await axios.post("updates", { from });
      const result = handleSuccess({ namespace, action: "updates" }, response);
      result.validated = null;
      if (result.success) {
        result.validated = result.data;
      }
      return result;
    } catch (error) {
      return handleError({ namespace, action: "updates" }, error.response);
    }
  },
};
export { api as default };
