import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export function useFleetFilter(
  { collectionRef, fetchCollectionCallback } = {
    collectionRef: [],
    fetchCollectionCallback: () => {},
  }
) {
  const store = useStore();
  const filter = computed(() => store.getters["main/fleetsFilter"]);

  onMounted(() => {
    if (fetchCollectionCallback) fetchCollection();
  });

  async function fetchCollection() {
    collectionRef.value = await fetchCollectionCallback();
  }

  const filteredCollection = computed(() => {
    if (!collectionRef) return [];
    return collectionRef.value.filter((v) => {
      let include = true;

      // fleet filter
      if (filter.value.length) {
        const ff = filter.value.find((f) => f.id == v.fleet_id);
        if (!ff) include = false;
      }

      return include;
    });
  });

  function filterCollection(collection = []) {
    return collection.filter((v) => {
      let include = true;

      // fleet filter
      if (filter.value.length) {
        const ff = filter.value.find((f) => f.id == v.fleet_id);
        if (!ff) include = false;
      }

      return include;
    });
  }

  function addFleet(fleet) {
    store.dispatch("main/addFleetToFleetsFilter", fleet);
  }

  function removeFleet(fleetId) {
    store.dispatch("main/removeFleetFromFleetsFilter", fleetId);
  }

  return {
    filter,
    addFleet,
    removeFleet,
    fetchCollection,
    filterCollection,
    filteredCollection,
  };
}
