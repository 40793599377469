<template>
  <div
    id="_report_slider_el_id"
    class="modal modal-slide-over"
    data-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;" @click="close">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <!-- BEGIN: Slide Over Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            {{ title }}
          </h2>
        </div>
        <!-- END: Slide Over Header -->
        <!-- BEGIN: Slide Over Body -->
        <div class="modal-body" style="padding-bottom: 80px">
          <div>
            <label for="modal-form-1" class="form-label">{{
              i18n.t("general.report-slide-period")
            }}</label>

            <Eyepicker v-model="daterange" :max-days="31" />
          </div>

          <div class="mt-3">
            <label>Eyegeo</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyegeo.latitude"
                  v-model="actives['eyegeo.latitude']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyegeo.latitude"
                  >Latitudine GPS</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyegeo.longitude"
                  v-model="actives['eyegeo.longitude']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyegeo.longitude"
                  >Longitudine GPS</label
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Eyecold</label>
            <h2 class="mt-3 ml-2">Sonde di temperatura</h2>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold.T1"
                  v-model="actives['eyecold.T1']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold.T1"
                  >Sonda T1</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold.T2"
                  v-model="actives['eyecold.T2']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold.T2"
                  >Sonda T2</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold.T3"
                  v-model="actives['eyecold.T3']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold.T3"
                  >Sonda T3</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold.T4"
                  v-model="actives['eyecold.T4']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold.T4"
                  >Sonda T4</label
                >
              </div>
            </div>

            <h2 class="mt-3 ml-2">Gruppo frigo</h2>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.overall_status"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.overall_status"
                  >Stato frigo</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.engine_status"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.engine_status"
                  >Stato motore/compressore</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operating_mode"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operating_mode"
                  >Modalità frigo</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operating_mode_status"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operating_mode_status"
                  >Stato modalità frigo</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.setpoint_t1"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold_fridge.setpoint_t1"
                  >Setpoint 1</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_status_c1"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_status_c1"
                  >Stato compartimento 1</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_mode_c1"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_mode_c1"
                  >Modalità compartimento 1</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.setpoint_t2"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold_fridge.setpoint_t2"
                  >Setpoint 2</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_status_c2"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_status_c2"
                  >Stato compartimento 2</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_mode_c2"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_mode_c2"
                  >Modalità compartimento 2</label
                >
              </div>
            </div>

            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.setpoint_t3"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold_fridge.setpoint_t3"
                  >Setpoint 3</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_status_c3"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_status_c3"
                  >Stato compartimento 3</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.operation_mode_c3"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.operation_mode_c3"
                  >Modalità compartimento 3</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.alarm_status"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.alarm_status"
                  >Stato allarmi</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.hour_meter_electric"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.hour_meter_electric"
                  >Ore frigo in elettrico</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.hour_meter_fuel"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.hour_meter_fuel"
                  >Ore frigo in diesel</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.current_sensor"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyecold_fridge.current_sensor"
                  >Voltaggio batteria</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.odl_num"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold_fridge.odl_num"
                  >Numero OdL</label
                >
              </div>
            </div>
            <div class="mt-2 ml-2">
              <div class="form-check">
                <input
                  id="eyecold_fridge.odl_t1"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecold_fridge.odl_t1"
                  >Temperatura OdL</label
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Eyedoor</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyedoor.door_1"
                  v-model="actives['eyedoor.door_1']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyedoor.door_1"
                  >Stato porta 1</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyedoor.door_2"
                  v-model="actives['eyedoor.door_2']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyedoor.door_2"
                  >Stato porta 2</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyedoor.siren"
                  v-model="actives['eyedoor.siren']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyedoor.siren"
                  >Stato sirena</label
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Eyefuel</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyefuel.tank_usage"
                  v-model="actives['eyefuel.tank_usage']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyefuel.tank_usage"
                  >Livello serbatoio</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyefuel.cap_status"
                  v-model="actives['eyefuel.cap_status']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyefuel.cap_status"
                  >Stato tappo</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyefuel.alarm_status"
                  v-model="actives['eyefuel.alarm_status']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyefuel.alarm_status"
                  >Stato Allarme</label
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Eyeway</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyeway.odometer"
                  v-model="actives['eyeway.odometer']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyeway.odometer"
                  >Odometro</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyeway.speed"
                  v-model="actives['eyeway.speed']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyeway.speed"
                  >Velocità</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyeway.breaks_status"
                  v-model="actives['eyeway.breaks_status']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyeway.breaks_status"
                  >Stato freni</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyeway.tyres_pressure_status"
                  v-model="actives['eyeway.tyres_pressure_status']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label
                  class="form-check-label"
                  for="eyeway.tyres_pressure_status"
                  >Pressione pneumatici</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyeway.load"
                  v-model="actives['eyeway.load']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyeway.load"
                  >Carico eccessivo</label
                >
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>Eyecontrol</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyecontrol.user"
                  v-model="actives['eyecontrol.user']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecontrol.user"
                  >Utente</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyecontrol.command"
                  v-model="actives['eyecontrol.command']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecontrol.command"
                  >Comando</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="eyecontrol.command_data"
                  v-model="actives['eyecontrol.command_data']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="eyecontrol.command_data"
                  >Paramentri</label
                >
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label>T&T</label>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="tandt.locked"
                  v-model="actives['tandt.locked']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="tandt.locked"
                  >Agganciato</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="tandt.intensity"
                  v-model="actives['tandt.intensity']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="tandt.intensity"
                  >Segnale</label
                >
              </div>
            </div>
            <div class="mt-2">
              <div class="form-check">
                <input
                  id="tandt.beacon_code"
                  v-model="actives['tandt.beacon_code']"
                  class="form-check-switch"
                  type="checkbox"
                />
                <label class="form-check-label" for="tandt.beacon_code"
                  >ID motrice</label
                >
              </div>
            </div>
          </div>
        </div>
        <!-- END: Slide Over Body -->
        <!-- BEGIN: Slide Over Footer -->
        <div class="modal-footer text-right w-full absolute bottom-0">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20 mr-1"
            @click="onClose"
          >
            {{ cancel }}
          </button>
          <button
            data-dismiss="modal"
            type="button"
            class="btn btn-primary w-auto"
            @click="onSave"
          >
            {{ save }}
          </button>
        </div>
        <!-- END: Slide Over Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";

export default {
  name: "ReportSlider",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Report",
    },
    type: {
      type: String,
      default: "custom",
    },
    save: {
      type: String,
      default: "Save",
    },
    cancel: {
      type: String,
      default: "Close",
    },
    enabled: {
      type: [String, Array],
      default: () => [],
    },
    selected: {
      type: [String, Array],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const is_shown = ref(false);
    const actives = ref([]);
    const daterange = ref({
      from: dayjs().startOf("day").toDate(),
      to: dayjs().toDate(),
    });

    function onClose() {
      is_shown.value = false;

      emit("close", actives.value);
      clear();
    }

    function onSave() {
      is_shown.value = false;
      let active_services = [];
      let services = [
        "eyegeo.latitude",
        "eyegeo.longitude",
        "eyecold.T1",
        "eyecold.T2",
        "eyecold.T3",
        "eyecold.T4",
        "eyecold_fridge.overall_status",
        "eyecold_fridge.engine_status",
        "eyecold_fridge.operating_mode",
        "eyecold_fridge.operating_mode_status",
        "eyecold_fridge.setpoint_t1",
        "eyecold_fridge.operation_status_c1",
        "eyecold_fridge.operation_mode_c1",
        "eyecold_fridge.setpoint_t2",
        "eyecold_fridge.operation_status_c2",
        "eyecold_fridge.operation_mode_c2",
        "eyecold_fridge.setpoint_t3",
        "eyecold_fridge.operation_status_c3",
        "eyecold_fridge.operation_mode_c3",
        "eyecold_fridge.alarm_status",
        "eyecold_fridge.hour_meter_electric",
        "eyecold_fridge.hour_meter_fuel",
        "eyecold_fridge.current_sensor",
        "eyecold_fridge.odl_num",
        "eyecold_fridge.odl_t1",
        "eyedoor.door_1",
        "eyedoor.door_2",
        "eyedoor.siren",
        "eyefuel.tank_usage",
        "eyefuel.cap_status",
        "eyefuel.alarm_status",
        "eyeway.odometer",
        "eyeway.speed",
        "eyeway.breaks_status",
        "eyeway.tyres_pressure_status",
        "eyeway.load",
        "eyecontrol.user",
        "eyecontrol.command",
        "eyecontrol.command_data",
        "tandt.beacon_code",
        "tandt.locked",
        "tandt.intensity",
      ];

      for (let key = 0; key < services.length; key++) {
        let check = document.getElementById(services[key]);
        if (check) {
          if (check.checked) {
            active_services.push(services[key]);
          }
        }
      }

      emit(
        "save",
        active_services,
        daterange.value[0], // FROM
        daterange.value[1], // TO
        props.type
      );
      clear();
    }

    // get onchange event from Daterange
    async function onDaterangeChange() {
      console.log("Report onDaterangeChange");
      if (!props.active) return;
    }

    function clear() {
      let services = [
        "eyegeo.latitude",
        "eyegeo.longitude",
        "eyecold.T1",
        "eyecold.T2",
        "eyecold.T3",
        "eyecold.T4",
        "eyecold_fridge.overall_status",
        "eyecold_fridge.engine_status",
        "eyecold_fridge.operating_mode",
        "eyecold_fridge.operating_mode_status",
        "eyecold_fridge.setpoint_t1",
        "eyecold_fridge.operation_status_c1",
        "eyecold_fridge.operation_mode_c1",
        "eyecold_fridge.setpoint_t2",
        "eyecold_fridge.operation_status_c2",
        "eyecold_fridge.operation_mode_c2",
        "eyecold_fridge.setpoint_t3",
        "eyecold_fridge.operation_status_c3",
        "eyecold_fridge.operation_mode_c3",
        "eyecold_fridge.alarm_status",
        "eyecold_fridge.hour_meter_electric",
        "eyecold_fridge.hour_meter_fuel",
        "eyecold_fridge.current_sensor",
        "eyecold_fridge.odl_num",
        "eyecold_fridge.odl_t1",
        "eyedoor.door_1",
        "eyedoor.door_2",
        "eyedoor.siren",
        "eyefuel.tank_usage",
        "eyefuel.cap_status",
        "eyefuel.alarm_status",
        "eyeway.odometer",
        "eyeway.speed",
        "eyeway.breaks_status",
        "eyeway.tyres_pressure_status",
        "eyeway.load",
        "eyecontrol.user",
        "eyecontrol.command",
        "eyecontrol.command_data",
        "tandt.beacon_code",
        "tandt.locked",
        "tandt.intensity",
      ];
      for (let key = 0; key < services.length; key++) {
        let check = document.getElementById(services[key]);
        if (check) {
          check.checked = false;
          check.disabled = "disabled";
          check.parentElement.classList.add("disabled");
        }
      }
    }

    function init() {
      clear();

      let dt_range = useDaterange({ onDaterangeChange });
      daterange.value = [dt_range.from.value, dt_range.to.value];
      if (props.selected) {
        for (let key in props.selected) {
          let check = document.getElementById(props.selected[key]);

          if (check && props.enabled.indexOf(props.selected[key]) >= 0) {
            check.checked = true;
            check.disabled = "";
            check.parentElement.classList.remove("disabled");
          }
        }
      }

      if (props.enabled) {
        for (let key in props.enabled) {
          let check = document.getElementById(props.enabled[key]);
          if (check) {
            check.disabled = "";
            check.parentElement.classList.remove("disabled");
          }
        }
      }

      if (document.querySelector(`#_report_slider_el_id`)) {
        if (!is_shown.value && props.active)
          cash("#_report_slider_el_id").modal("show");
        else if (is_shown.value && !props.active)
          cash("#_report_slider_el_id").modal("hide");
        else console.log("Modal already opened or closed!");
      }
      is_shown.value = props.active;
    }

    watch(() => props, init, { deep: true });
    init();

    return {
      i18n: useI18n(),
      onClose,
      onSave,
      actives,
      daterange,
    };
  },
};
</script>
