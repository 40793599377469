import { createApp } from "vue";
import App from "./App.vue";
import { createVfm } from 'vue-final-modal';
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import globalComponents from "./global-components";
import { ModalsContainer } from 'vue-final-modal';
import utils from "./utils";
import "./libs";

// SASS Theme
import "./assets/sass/app.scss";

// Leaflet
import "leaflet/dist/leaflet";
import "leaflet-textpath/leaflet.textpath";
import "leaflet-ant-path/dist/leaflet-ant-path";
import "leaflet-polylinedecorator/dist/leaflet.polylineDecorator";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "polyline-encoded";
import 'vue-final-modal/style.css'

const vfm = createVfm();

const app = createApp(App).use(store).use(router).use(i18n).use(vfm);

globalComponents(app);
utils(app);

// js initializations
import "./bootstrap";

app.mount("#app");
