<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Reset Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Reyesol"
              class="h-6"
              :src="require(`@/assets/images/reyesol-logo-white.svg`)"
            />
          </a>
          <div class="my-auto">
            <!-- <img alt="Reyesol" class="-intro-x w-1/2 -mt-16" :src="require(`@/assets/images/illustration.svg`)" /> -->
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              {{ $t("reset.welcome") }}
            </div>
          </div>
        </div>
        <!-- END: Reset Info -->
        <!-- BEGIN: Reset Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <div class="intro-x xl:hidden flex justify-center mb-4">
              <img
                alt="Reyesol"
                class="h-8"
                :src="require(`@/assets/images/reyesol-logo-black.svg`)"
              />
            </div>
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              <span>{{ $t("reset.title") }}</span>
            </h2>
            <div class="intro-x mt-2 text-gray-500 text-center">
              {{ $t("reset.resetMessage") }}
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="v$.password.$model"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{ 'border-theme-24': v$.password.$error && hasErrors }"
                placeholder="Password"
              />
              <div v-if="hasErrors">
                <div
                  v-for="error in v$.password.$errors"
                  :key="error.$uid"
                  class="text-theme-24 mt-2"
                >
                  {{ $t(`validation.${error.$validator}`) }}
                </div>
              </div>

              <input
                v-model="v$.password_confirmation.$model"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{
                  'border-theme-24':
                    v$.password_confirmation.$error && hasErrors,
                }"
                placeholder="Re-Type Password"
                @keypress.enter="send"
              />
              <div v-if="hasErrors">
                <div
                  v-for="error in v$.password_confirmation.$errors"
                  :key="error.$uid"
                  class="text-theme-24 mt-2"
                >
                  {{ $t(`validation.${error.$validator}`) }}
                </div>
              </div>
            </div>

            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-50 xl:mr-3 align-top"
                @click="send"
              >
                <loading-icon
                  v-if="loading"
                  icon="three-dots"
                  color="white"
                  class="mr-2"
                />
                {{ $t("reset.reset_btn") }}
              </button>
            </div>
            <!-- <div class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
              By signin up, you agree to our <br />
              <a class="text-theme-17 dark:text-gray-300" href="">Terms and Conditions</a>
              &
              <a class="text-theme-17 dark:text-gray-300" href="">Privacy Policy</a>
            </div> -->
          </div>
        </div>
        <!-- END: Reset Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import EventBus from "@/libs/event-bus";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isReset = ref(true);
    const resetMessage = ref(null);
    const loading = ref(false);
    const hasErrors = ref(false);
    const state = reactive({
      email: "",
      password: "",
      password_confirmation: "",
    });

    // validation
    const rules = {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      password_confirmation: {
        sameAsPassword: function (password) {
          return password === state.password;
        },
      },
    };
    const v$ = useVuelidate(rules, state);

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
      state.email = route.params.email;
    });

    async function send() {
      loading.value = true;
      hasErrors.value = false;

      v$.value.$touch();
      if (v$.value.$invalid) {
        loading.value = false;
        hasErrors.value = true;
        return;
      }

      const result = await store.dispatch("auth/reset", {
        token: route.params.token,
        email: route.params.email,
        password: v$.value.password.$model,
        password_confirmation: v$.value.password_confirmation.$model,
      });

      if (!result.success && result.status >= 400) {
        hasErrors.value = true;
        EventBus.emit("notification:show", {
          type: "error",
          title: result.data?.message || result.errorMessage,
        });
      }

      if (!result.success && result.status == 422) {
        hasErrors.value = true;
        // TODO
      }

      loading.value = false;

      if (result.success) {
        router.replace("/");
      }
    }

    return {
      v$,
      loading,
      hasErrors,
      send,
      resetMessage,
    };
  },
});
</script>
