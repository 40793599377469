import i18n from "../i18n";
import EventBus from "@/libs/event-bus";

const commonResponse = {
  success: null,
  status: null,
  data: null,
  errorMessage: null,
  validated: null,
  meta: null,
};

export function handleSuccess({ namespace, action }, response) {
  const r = { ...commonResponse };
  r.success = true;
  r.status = 200;
  r.data = response.data.data;
  r.meta = response.data.meta;
  // if (process.env.VUE_APP_DEBUG)
  //   console.log(`API success (namespace: ${namespace}, action: ${action})`, r);
  return r;
}

export function handleError({ namespace, action }, response) {
  const r = { ...commonResponse };
  r.success = false;
  r.status = response.status;
  if ("message" in response.data) {
    r.errorMessage = response.data.message;
  } else {
    r.errorMessage = i18n.t(`api.${namespace}.${action}.${response.status}`);
  }
  if ("data" in response.data) {
    r.data = response.data.data;
  } else {
    r.data = response.data;
  }
  // if (process.env.VUE_APP_DEBUG)
  // console.log(`API error (namespace: ${namespace}, action: ${action})`, r);
  EventBus.emit("api:error", { namespace, action, response: r });
  return r;
}
