import { vehicles as vehiclesApi } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s({ key: "plate", direction: "asc" }),
    types: [],
  };
};

// getters
const getters = {
  ...g,
  types: (state) => state.types,
};

// actions
const actions = {
  ...a(vehiclesApi),
  async getTypes({ commit, getters }) {
    const types = getters.types;
    if (types.length) return { validated: types };

    const result = await vehiclesApi.getTypes();
    result.validated = [];
    if (result.success) {
      const types = result.data;
      commit("SET_TYPES", types);
      result.validated = getters.types;
    }
    return result;
  },
  async getTypesStatistics() {
    const result = await vehiclesApi.getTypesStatistics();
    result.validated = [];
    if (result.success) result.validated = result.data;
    return result;
  },
  async getAlertsConfigs(_, vehicleId) {
    const result = await vehiclesApi.getAlertsConfigs(vehicleId);
    result.validated = [];
    if (result.success) result.validated = result.data;
    return result;
  },
  async setFleet(_, { vehicleId, fleetId }) {
    const result = await vehiclesApi.setFleet(vehicleId, fleetId);
    result.validated = null;
    if (result.success) result.validated = result.data;
    return result;
  },
  async setLogInterval(_, { vehicleId, value }) {
    return await vehiclesApi.setLogInterval(vehicleId, value);
  },
};

// mutations
const mutations = {
  ...m,
  SET_TYPES(state, types) {
    state.types = types;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
