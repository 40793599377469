<template>
  <div v-if="item" class="intro-y">
    <div
      class="box py-4 mr-1 mb-3 items-center cursor-pointer bg-gray-100 rounded-l-none hover:bg-white hover:border-theme-10 border-l-2 hover:border-theme-10"
      :class="{ 'border-theme-8': selected }"
      @click="emit('click')"
      @mouseover="emit('mousehover')"
    >
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.start_day") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(item.message_datetime).format("DD/MM/YYYY") }}
        </span>
      </div>
      <div class="font-medium flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.start_time") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(item.message_datetime).format("HH:mm") }}
        </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.duration") }}
        </span>
        <span class="px-2 text-right"> {{ duration }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.stop_duration") }}
        </span>
        <span class="px-2 text-right"> {{ stop_duration }} </span>
      </div>
      <div v-if="item.end" class="font-medium flex justify-between">
        <span class="px-2">
          {{ i18n.t("services.eyegeo.trip.end_time") }}
        </span>
        <span class="px-2 text-right">
          {{ dayjs(item.end.message_datetime).format("HH:mm") }}
        </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2 text-right">
          {{ i18n.t("services.eyegeo.trip.start") }}
        </span>
        <span class="px-2 text-right"> {{ first_position }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2 text-right">
          {{ i18n.t("services.eyegeo.trip.end") }}
        </span>
        <span class="px-2 text-right"> {{ last_position }} </span>
      </div>
      <div class="text-gray-600 text-xs mt-0.5 flex justify-between">
        <span class="px-2"> {{ i18n.t("services.eyegeo.trip.miliage") }} </span>
        <span class="px-2 text-right"> {{ miliage }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref } from "vue";
import { useI18n } from "vue3-i18n";
import dayjs from "dayjs";
import { geoReverseSearch } from "@/services/geocoder";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    vehicle: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const duration = ref(0);
    const stop_duration = ref(0);
    const miliage = ref(0);
    const last_position = ref(null);
    const first_position = ref(null);

    watch(() => props, init, { deep: true });

    async function init() {
      if (!props.item) return;

      const has_eyegeo_geocoding = props.vehicle.active_agreements.find(
        (o) => o.agreement_id == 25
      );

      if (props.item.start) {
        if (props.item.start_position) {
          first_position.value = props.item.start_position;
        } else {
          if (has_eyegeo_geocoding) {
            await new Promise((resolve) =>
              setTimeout(resolve, Math.random() * 10 + 1000)
            );
            let result = await geoReverseSearch(
              props.item.start.latitude,
              props.item.start.longitude
            );
            if (result) {
              first_position.value = result.display_name;
            }
          } else {
            first_position.value =
              "lat: " +
              normalize(props.item.start.latitude) +
              ", lng:" +
              normalize(props.item.start.longitude);
          }
        }
      }

      if (props.item.end) {
        if (props.item.end_position) {
          last_position.value = props.item.end_position;
        } else {
          if (has_eyegeo_geocoding) {
            await new Promise((resolve) =>
              setTimeout(resolve, Math.random() * 10 + 1000)
            );
            let result = await geoReverseSearch(
              props.item.end.latitude,
              props.item.end.longitude
            );
            if (result) {
              last_position.value = result.display_name;
            }
          } else {
            last_position.value =
              "lat: " +
              normalize(props.item.end.latitude) +
              ", lng:" +
              normalize(props.item.end.longitude);
          }
        }
      }

      if (props.item.duration <= 60) {
        duration.value = i18n.t("services.eyegeo.trip.less_minute");
      } else if (props.item.duration <= 120) {
        duration.value = i18n.t("services.eyegeo.trip.minute");
      } else if (props.item.duration < 3600) {
        duration.value =
          Math.floor(props.item.duration / 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else if (props.item.duration < 7200) {
        duration.value = "1 " + i18n.t("services.eyegeo.trip.hour") + " e ";
        duration.value +=
          Math.floor(props.item.duration / 60 - 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else {
        duration.value =
          Math.floor(props.item.duration / 3600) +
          " " +
          i18n.t("services.eyegeo.trip.hours") +
          " e ";
        duration.value +=
          Math.floor(props.item.duration / 60) -
          Math.floor(props.item.duration / 3600) * 60 +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      }

      if (props.item.stop_duration <= 60) {
        stop_duration.value = i18n.t("services.eyegeo.trip.less_minute");
      } else if (props.item.stop_duration <= 120) {
        stop_duration.value = i18n.t("services.eyegeo.trip.minute");
      } else if (props.item.stop_duration < 3600) {
        stop_duration.value =
          Math.floor(props.item.stop_duration / 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else if (props.item.stop_duration < 7200) {
        stop_duration.value =
          "1 " + i18n.t("services.eyegeo.trip.hour") + " e ";
        stop_duration.value +=
          Math.floor(props.item.stop_duration / 60 - 60) +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      } else {
        stop_duration.value =
          Math.floor(props.item.stop_duration / 3600) +
          " " +
          i18n.t("services.eyegeo.trip.hours") +
          " e ";
        stop_duration.value +=
          Math.floor(props.item.stop_duration / 60) -
          Math.floor(props.item.stop_duration / 3600) * 60 +
          " " +
          i18n.t("services.eyegeo.trip.minutes");
      }
      if (props.item.miliage < 0.001) {
        miliage.value = i18n.t("services.eyegeo.trip.less_meter");
      } else if (props.item.miliage < 1) {
        miliage.value =
          Math.floor(props.item.miliage * 1000) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      } else if (props.item.miliage < 2) {
        miliage.value = "1 " + i18n.t("services.eyegeo.trip.km") + " e ";
        miliage.value +=
          Math.floor((props.item.miliage - 1) * 1000) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      } else {
        miliage.value =
          Math.floor(props.item.miliage) +
          " " +
          i18n.t("services.eyegeo.trip.kms") +
          " e ";
        miliage.value +=
          Math.floor(
            (props.item.miliage - Math.floor(props.item.miliage)) * 1000
          ) +
          " " +
          i18n.t("services.eyegeo.trip.meters");
      }
    }

    function normalize(coord) {
      return [parseFloat(coord)];
    }

    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }

    init();
    return {
      i18n,
      dayjs,
      duration,
      stop_duration,
      miliage,
      last_position,
      first_position,
      makeMapsUrl,
      emit,
    };
  },
});
</script>
