import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
const namespace = "pois";
const api = {
  ...getResource(namespace),
  getVehicles: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/vehicles`);
      return handleSuccess({ namespace, action: "getVehicles" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getVehicles" }, error.response);
    }
  },
};
export { api as default };
