import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";

export function getResource(collectionName) {
  const namespace = collectionName;
  return {
    getCollection: async function (params = {}) {
      try {
        const response = await axios.get(`${collectionName}`, { params });
        return handleSuccess({ namespace, action: "getCollection" }, response);
      } catch (error) {
        return handleError(
          { namespace, action: "getCollection" },
          error.response
        );
      }
    },
    postEntity: async function (data) {
      try {
        const response = await axios.post(`${collectionName}`, data);
        return handleSuccess({ namespace, action: "postEntity" }, response);
      } catch (error) {
        return handleError({ namespace, action: "postEntity" }, error.response);
      }
    },
    getEntity: async function (id) {
      try {
        const response = await axios.get(`${collectionName}/${id}`);
        return handleSuccess({ namespace, action: "getEntity" }, response);
      } catch (error) {
        return handleError({ namespace, action: "getEntity" }, error.response);
      }
    },
    putEntity: async function (id, data) {
      try {
        const response = await axios.put(`${collectionName}/${id}`, data);
        return handleSuccess({ namespace, action: "putEntity" }, response);
      } catch (error) {
        return handleError({ namespace, action: "putEntity" }, error.response);
      }
    },
    deleteEntity: async function (id) {
      try {
        const response = await axios.delete(`${collectionName}/${id}`);
        return handleSuccess({ namespace, action: "deleteEntity" }, response);
      } catch (error) {
        return handleError(
          { namespace, action: "deleteEntity" },
          error.response
        );
      }
    },
  };
}
