import { notifications as notificationsApi } from "@/api";

const state = () => {
  return {
    firstPage: [],
  };
};

// getters
const getters = {
  firstPage: (state) => state.firstPage,
};

// actions
const actions = {
  async getNotification(_, id) {
    const result = await notificationsApi.getNotification(id);
    result.validated = [];
    if (result.success) result.validated = result.data;
    return result;
  },
  async markNotification(_, id) {
    const result = await notificationsApi.markNotification(id);
    result.validated = result.success;
    return result;
  },
  async getPage(_, pageNum) {
    const result = await notificationsApi.getAll(pageNum);
    result.validated = [];
    if (result.success) result.validated = result.data;
    return result;
  },
  async fetchFirstPage({ commit, getters }) {
    const result = await notificationsApi.getAll();
    if (result.success) {
      commit("SET_FIRST_PAGE_NOTIFICATIONS", result.data);
    }
    result.validated = getters.firstPage;
    return result;
  },
};

// mutations
const mutations = {
  SET_FIRST_PAGE_NOTIFICATIONS(state, notifications) {
    state.firstPage = notifications;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
