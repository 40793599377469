<template>
  <div class="intro-x flex items-center mt-2">
    <h1 class="text-2xl font-bold mr-auto capitalize">
      <slot />
    </h1>
  </div>
</template>

<script>
export default {};
</script>
