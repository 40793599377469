<template>
  <div v-if="vehicle" id="service-eyecold">
    <div v-if="data">
      <div class="flex flex-wrap justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyecold.last_status") }}
        </div>
        <div class="text-right">
          <div>
            {{ i18n.t("services.eyecold.last_temperature") }}
            <DateAgo
              v-if="data.temperature"
              :datetime="data.temperature.message_datetime"
            ></DateAgo>
            <span v-else>
              {{ i18n.t("services.no_data") }}
            </span>
          </div>
          <div>
            {{ i18n.t("services.eyecold.last_fridge") }}
            <DateAgo
              v-if="data.fridge"
              :datetime="data.fridge.message_datetime"
            ></DateAgo>
            <span v-else>
              {{ i18n.t("services.no_data") }}
            </span>
          </div>
        </div>
      </div>

      <div class="report-box mb-8">
        <div class="box p-5">
          <div class="">
            <div class="flex flex-wrap justify-around">
              <div class="flex flex-col items-center mr-3 py-4">
                <div>
                  {{ i18n.t("services.eyecold.odl_num") }}
                </div>
                <div>
                  <TagIcon />
                </div>
                <div class="flex">
                  <div
                    v-if="
                      vehicle.configurations &&
                      vehicle.configurations.eyecold_fridge_odl_num != null
                    "
                  >
                    {{ vehicle.configurations.eyecold_fridge_odl_num }}
                  </div>
                  <span v-else>
                    {{ i18n.t("services.no_data") }}
                  </span>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 ml-2"
                      aria-expanded="false"
                    >
                      <SlidersIcon class="w-3 h-3" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdText
                          :initial-value="
                            vehicle.configurations.eyecold_fridge_odl_num
                          "
                          :name="i18n.t('services.eyecold.cmd.odl_num')"
                          :description="
                            i18n.t('services.eyecold.cmd.odl_num_description')
                          "
                          :ask="false"
                          @send="
                            (v) =>
                              sendFridgeCommand('odl_num', {
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-around">
                <div class="flex flex-col items-center mr-3 py-4">
                  <div>
                    {{ i18n.t("services.eyecold.odl_t1") }}
                  </div>
                  <div>
                    <ThermometerIcon />
                  </div>
                  <div class="flex">
                    <span
                      v-if="
                        vehicle.configurations &&
                        vehicle.configurations.eyecold_fridge_odl_t1 != null
                      "
                    >
                      {{ vehicle.configurations.eyecold_fridge_odl_t1 }}° C
                    </span>
                    <span v-else>
                      {{ i18n.t("services.no_data") }}
                    </span>
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle btn btn-sm btn-warning p-1 ml-2"
                        aria-expanded="false"
                      >
                        <SlidersIcon class="w-3 h-3" />
                      </button>
                      <div class="dropdown-menu w-80">
                        <div class="dropdown-menu__content box p-4">
                          <CmdRange
                            :initial-value="
                              vehicle.configurations.eyecold_fridge_odl_t1 || 0
                            "
                            :can-null="true"
                            :min="-35"
                            :max="35"
                            :step="1"
                            unit="°c"
                            :name="i18n.t('services.eyecold.cmd.odl_t1')"
                            :description="
                              i18n.t('services.eyecold.cmd.odl_t1_description')
                            "
                            :ask="false"
                            @send="
                              (v) =>
                                sendFridgeCommand('odl_t1', {
                                  value: v,
                                })
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="data.fridge" class="report-box mb-8">
        <div class="box p-5">
          <!-- Battery, fridge hours, alarms -->
          <div class="flex flex-wrap justify-around">
            <div
              v-if="data.fridge.current_sensor != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.current_sensor") }}
              </div>
              <div class="mb-2">
                <BatteryChargingIcon />
              </div>
              <div
                :class="{
                  'bg-theme-24': data.fridge.current_sensor <= 12.5,
                  'bg-theme-10': data.fridge.current_sensor > 12.5,
                }"
                class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.fridge.current_sensor }} V
              </div>
            </div>
            <div
              v-if="data.fridge.alarm_status != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.alarm_status.name") }}
              </div>
              <div class="mb-2">
                <AlertTriangleIcon />
              </div>

              <div
                v-if="data.fridge.alarm_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyecold.alarm_status.none") }}
              </div>
              <div
                v-else-if="data.fridge.alarm_status == 1"
                class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyecold.alarm_status.operative") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyecold.alarm_status.stopped") }}
              </div>
            </div>
            <div class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                {{ i18n.t("services.eyecold.overall_status.name") }}
              </div>
              <div class="mb-2">
                <PowerIcon />
              </div>
              <div class="flex">
                <div
                  v-if="
                    data.fridge.overall_status == null ||
                    data.fridge.overall_status == 0
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.overall_status.off") }}
                </div>
                <div
                  v-else
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.overall_status.on") }}
                </div>
                <div
                  v-show="data.fridge.overall_status_dirty"
                  class="inline p-1 text-xs text-theme-24 h-6 w-6"
                >
                  <loading-icon icon="oval" />
                </div>
              </div>
              <template v-if="false">
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.eyecold_cmd_enabled &&
                    vehicle.configurations.eyecold_cmd_enabled == 'true'
                  "
                  class="dropdown"
                >
                  <template v-if="vehicle.status == 1">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <SlidersIcon class="w-5 h-5" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdOnOff
                          :initial-value="data.fridge.overall_status"
                          :name="i18n.t('services.eyecold.cmd.status_name')"
                          :description="
                            i18n.t('services.eyecold.cmd.status_description')
                          "
                          @send="(v) => sendFridgeCommand('overall_status', v)"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="vehicle.status == 0">
                    <button
                      class="btn btn-sm border-gray-500 p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <WifiOffIcon class="w-5 h-5" />
                    </button>
                  </template>
                </div>
                <div v-else>
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <LockIcon class="w-5 h-5" />
                  </button>
                </div>
              </template>
            </div>
            <div
              v-if="data.fridge.operating_mode != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>{{ i18n.t("services.eyecold.operating_mode.name") }}</div>
              </div>
              <div class="mb-2">
                <WindIcon />
              </div>
              <div class="flex">
                <div
                  v-if="data.fridge.operating_mode == 1"
                  class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operating_mode.continuous") }}
                </div>
                <div
                  v-else
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operating_mode.startstop") }}
                </div>
                <div
                  v-show="data.fridge.operating_mode_dirty"
                  class="inline p-1 text-xs text-theme-24 h-6 w-6"
                >
                  <loading-icon icon="oval" />
                </div>
              </div>
              <div
                v-if="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_cmd_enabled &&
                  vehicle.configurations.eyecold_cmd_enabled == 'true'
                "
                class="dropdown"
              >
                <template v-if="vehicle.status == 1">
                  <button
                    class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <SlidersIcon class="w-5 h-5" />
                  </button>
                  <div class="dropdown-menu w-80">
                    <div class="dropdown-menu__content box p-4">
                      <CmdSelect
                        :options="operating_modes"
                        :initial-value="data.fridge.operating_mode"
                        :name="i18n.t('services.eyecold.cmd.operating_mode')"
                        :description="
                          i18n.t(
                            'services.eyecold.cmd.operating_mode_description'
                          )
                        "
                        @send="(v) => sendFridgeCommand('operating_mode', v)"
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="vehicle.status == 0">
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <WifiOffIcon class="w-5 h-5" />
                  </button>
                </template>
              </div>
              <div v-else>
                <button
                  class="btn btn-sm border-gray-500 p-1 mt-2"
                  aria-expanded="false"
                >
                  <span class="pr-1">{{
                    i18n.t("services.eyecontrol.name")
                  }}</span>
                  <LockIcon class="w-5 h-5" />
                </button>
              </div>
            </div>
            <div
              v-if="data.fridge.engine_status != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.engine_status.name") }}
              </div>
              <div class="mb-2">
                <RefreshCwIcon />
              </div>

              <div
                v-if="data.fridge.engine_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyecold.engine_status.fuel") }}
              </div>
              <div
                v-else-if="data.fridge.engine_status == 1"
                class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyecold.engine_status.electric") }}
              </div>
            </div>
            <div
              v-if="data.fridge.operating_mode_status != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_status.name") }}
                </div>
              </div>
              <div class="mb-2">
                <template v-if="data.fridge.operating_mode_status == 0">
                  <ZapOffIcon />
                </template>
                <template v-else>
                  <ZapIcon />
                </template>
              </div>
              <div class="flex flex-col">
                <div
                  v-if="data.fridge.operating_mode_status == 0"
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.off") }}
                </div>
                <div
                  v-if="(data.fridge.operating_mode_status & 1) == 1"
                  class="mb-1 py-1 px-2 bg-theme-11 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.available") }}
                </div>
                <div
                  v-if="(data.fridge.operating_mode_status & 2) == 2"
                  class="mb-1 py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.on") }}
                </div>
                <div
                  v-if="(data.fridge.operating_mode_status & 4) == 4"
                  class="mb-1 py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.defrosting") }}
                </div>
                <div
                  v-if="(data.fridge.operating_mode_status & 8) == 8"
                  class="mb-1 py-1 px-2 bg-theme-21 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.pretrip") }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              data.fridge.hour_meter_electric != null ||
              data.fridge.hour_meter_fuel != null
            "
            class="pt-4 mt-4 border-t border-gray-400"
          >
            <div class="flex flex-wrap justify-around">
              <div
                v-if="data.fridge.hour_meter_electric != null"
                class="text-md text-gray-600 text-center"
              >
                {{ i18n.t("services.eyecold.hour_meter_electric") }}:
                {{ data.fridge.hour_meter_electric }}
              </div>
              <div
                v-if="data.fridge.hour_meter_fuel != null"
                class="text-md text-gray-600 text-center"
              >
                {{ i18n.t("services.eyecold.hour_meter_fuel") }}:
                {{ data.fridge.hour_meter_fuel }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t1 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 1
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex flex-wrap justify-around">
            <div
              v-if="data.temperature.T1 != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 1
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T1 }}° {{ data.temperature.T1_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T2 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 2
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T2 }}° {{ data.temperature.T2_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 2 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 3
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T3 }}° {{ data.temperature.T3_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 3 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T4 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 4
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T4 }}° {{ data.temperature.T4_unit }}
              </div>
            </div>
            <div v-if="false" class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_status.name") }}
                </div>
              </div>
              <div class="mb-2">
                <template v-if="data.fridge.operation_status_c1 == 0">
                  <ZapOffIcon />
                </template>
                <template v-else>
                  <ZapIcon />
                </template>
              </div>
              <div class="flex flex-col">
                <div
                  v-if="
                    data.fridge.operation_status_c1 == null ||
                    data.fridge.operation_status_c1 == 0
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.off") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c1 & 1) == 1"
                  class="mb-1 py-1 px-2 bg-theme-11 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.available") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c1 & 2) == 2"
                  class="mb-1 py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.on") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c1 & 4) == 4"
                  class="mb-1 py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.defrosting") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c1 & 8) == 8"
                  class="mb-1 py-1 px-2 bg-theme-21 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.pretrip") }}
                </div>
              </div>
            </div>
            <div
              v-if="
                data.fridge.operation_mode_c1 != null &&
                data.fridge.operation_mode_c1 < 6
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_mode.name") }}
                </div>
              </div>
              <div class="mb-2">
                <HelpCircleIcon />
              </div>
              <div class="flex">
                <div
                  v-if="data.fridge.operation_mode_c1 == 0"
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.off") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c1 == 1"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.heating") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c1 == 2"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.cooling") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c1 == 3"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.idle") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c1 == 4"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.defrost") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c1 == 5"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.pretrip") }}
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>{{ i18n.t("services.eyecold.setpoint") }} 1</div>
              </div>
              <div class="mb-2">
                <TargetIcon />
              </div>
              <div class="flex">
                <div
                  class="inline py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                >
                  {{ data.fridge.setpoint_t1 }} C°
                </div>
                <div
                  v-show="data.fridge.setpoint_t1_dirty"
                  class="inline p-1 text-xs text-theme-24 h-6 w-6"
                >
                  <loading-icon icon="oval" />
                </div>
              </div>
              <div
                v-if="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_cmd_enabled &&
                  vehicle.configurations.eyecold_cmd_enabled == 'true'
                "
                class="dropdown"
              >
                <template v-if="vehicle.status == 1">
                  <button
                    class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <SlidersIcon class="w-5 h-5" />
                  </button>
                  <div class="dropdown-menu w-80">
                    <div class="dropdown-menu__content box p-4">
                      <CmdRange
                        :initial-value="data.fridge.setpoint_t1"
                        :min="-35"
                        :max="35"
                        :step="1"
                        unit="°c"
                        :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                        :description="
                          i18n.t('services.eyecold.cmd.setpoint_description')
                        "
                        @send="
                          (v) =>
                            sendFridgeCommand('setpoint', {
                              compartment: 1,
                              value: v,
                            })
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="vehicle.status == 0">
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <WifiOffIcon class="w-5 h-5" />
                  </button>
                </template>
              </div>
              <div v-else>
                <button
                  class="btn btn-sm border-gray-500 p-1 mt-2"
                  aria-expanded="false"
                >
                  <span class="pr-1">{{
                    i18n.t("services.eyecontrol.name")
                  }}</span>
                  <LockIcon class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 1
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t2 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 2
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <div
              v-if="
                vehicle.status == 1 &&
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T2 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 2
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T2 }}° {{ data.temperature.T2_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 3
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T3 }}° {{ data.temperature.T3_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number == 2 &&
                vehicle.configurations.fridge_temperature_sensors_number == 4 &&
                data.temperature.T4 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 4
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T4 }}° {{ data.temperature.T4_unit }}
              </div>
            </div>
            <div v-if="false" class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_status.name") }}
                </div>
              </div>
              <div class="mb-2">
                <template v-if="data.fridge.operation_status_c2 == 0">
                  <ZapOffIcon />
                </template>
                <template v-else>
                  <ZapIcon />
                </template>
              </div>
              <div class="flex flex-col">
                <div
                  v-if="
                    data.fridge.operation_status_c2 == null ||
                    data.fridge.operation_status_c2 == 0
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.off") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c2 & 1) == 1"
                  class="mb-1 py-1 px-2 bg-theme-11 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.available") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c2 & 2) == 2"
                  class="mb-1 py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.on") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c2 & 4) == 4"
                  class="mb-1 py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.defrosting") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c2 & 8) == 8"
                  class="mb-1 py-1 px-2 bg-theme-21 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.pretrip") }}
                </div>
              </div>
            </div>
            <div
              v-if="data.fridge.operation_mode_c2 != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_mode.name") }}
                </div>
              </div>
              <div class="mb-2">
                <HelpCircleIcon />
              </div>
              <div class="flex">
                <div
                  v-if="data.fridge.operation_mode_c2 == 0"
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.off") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c2 == 1"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.heating") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c2 == 2"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.cooling") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c2 == 3"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.idle") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c2 == 4"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.defrost") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c2 == 5"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.pretrip") }}
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>{{ i18n.t("services.eyecold.setpoint") }} 2</div>
              </div>
              <div class="mb-2">
                <TargetIcon />
              </div>
              <div class="flex">
                <div
                  class="inline py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                >
                  {{ data.fridge.setpoint_t2 }} C°
                </div>
                <div
                  v-show="data.fridge.setpoint_t2_dirty"
                  class="inline p-1 text-xs text-theme-24 h-6 w-6"
                >
                  <loading-icon icon="oval" />
                </div>
              </div>
              <div
                v-if="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_cmd_enabled &&
                  vehicle.configurations.eyecold_cmd_enabled == 'true'
                "
                class="dropdown"
              >
                <template v-if="vehicle.status == 1">
                  <button
                    class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <SlidersIcon class="w-5 h-5" />
                  </button>
                  <div class="dropdown-menu w-80">
                    <div class="dropdown-menu__content box p-4">
                      <CmdRange
                        :initial-value="data.fridge.setpoint_t2"
                        :min="-35"
                        :max="35"
                        :step="1"
                        unit="°c"
                        :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                        :description="
                          i18n.t('services.eyecold.cmd.setpoint_description')
                        "
                        @send="
                          (v) =>
                            sendFridgeCommand('setpoint', {
                              compartment: 2,
                              value: v,
                            })
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="vehicle.status == 0">
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <WifiOffIcon class="w-5 h-5" />
                  </button>
                </template>
              </div>
              <div v-else>
                <button
                  class="btn btn-sm border-gray-500 p-1 mt-2"
                  aria-expanded="false"
                >
                  <span class="pr-1">{{
                    i18n.t("services.eyecontrol.name")
                  }}</span>
                  <LockIcon class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 2
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t3 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 3
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 3
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T3 }}° {{ data.temperature.T3_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number == 3 &&
                vehicle.configurations.fridge_temperature_sensors_number > 3 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T4 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 4
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T4 }}° {{ data.temperature.T4_unit }}
              </div>
            </div>
            <div v-if="false" class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_status.name") }}
                </div>
              </div>
              <div class="mb-2">
                <template v-if="data.fridge.operation_status_c3 == 0">
                  <ZapOffIcon />
                </template>
                <template v-else>
                  <ZapIcon />
                </template>
              </div>
              <div v-if="false" class="flex flex-col">
                <div
                  v-if="
                    data.fridge.operation_status_c3 == null ||
                    data.fridge.operation_status_c3 == 0
                  "
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.off") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c3 & 1) == 1"
                  class="mb-1 py-1 px-2 bg-theme-11 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.available") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c3 & 2) == 2"
                  class="mb-1 py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.on") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c3 & 4) == 4"
                  class="mb-1 py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.defrosting") }}
                </div>
                <div
                  v-if="(data.fridge.operation_status_c3 & 8) == 8"
                  class="mb-1 py-1 px-2 bg-theme-21 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_status.pretrip") }}
                </div>
              </div>
            </div>
            <div
              v-if="data.fridge.operation_mode_c3 != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>
                  {{ i18n.t("services.eyecold.operation_mode.name") }}
                </div>
              </div>
              <div class="mb-2">
                <HelpCircleIcon />
              </div>
              <div class="flex">
                <div
                  v-if="data.fridge.operation_mode_c3 == 0"
                  class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.off") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c3 == 1"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.heating") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c3 == 2"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.cooling") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c3 == 3"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.idle") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c3 == 4"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.defrost") }}
                </div>
                <div
                  v-else-if="data.fridge.operation_mode_c3 == 5"
                  class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                >
                  {{ i18n.t("services.eyecold.operation_mode.pretrip") }}
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center mr-3 py-4">
              <div class="py-1 px-2 text-xs font-medium flex space-x-1">
                <div>{{ i18n.t("services.eyecold.setpoint") }} 3</div>
              </div>
              <div class="mb-2">
                <TargetIcon />
              </div>
              <div class="flex">
                <div
                  class="inline py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                >
                  {{ data.fridge.setpoint_t3 }} C°
                </div>
                <div
                  v-show="data.fridge.setpoint_t3_dirty"
                  class="inline p-1 text-xs text-theme-24 h-6 w-6"
                >
                  <loading-icon icon="oval" />
                </div>
              </div>
              <div
                v-if="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_cmd_enabled &&
                  vehicle.configurations.eyecold_cmd_enabled == 'true'
                "
                class="dropdown"
              >
                <template v-if="vehicle.status == 1">
                  <button
                    class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <SlidersIcon class="w-5 h-5" />
                  </button>
                  <div class="dropdown-menu w-80">
                    <div class="dropdown-menu__content box p-4">
                      <CmdRange
                        :initial-value="data.fridge.setpoint_t3"
                        :min="-35"
                        :max="35"
                        :step="1"
                        unit="°c"
                        :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                        :description="
                          i18n.t('services.eyecold.cmd.setpoint_description')
                        "
                        @send="
                          (v) =>
                            sendFridgeCommand('setpoint', {
                              compartment: 3,
                              value: v,
                            })
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="vehicle.status == 0">
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <WifiOffIcon class="w-5 h-5" />
                  </button>
                </template>
              </div>
              <div v-else>
                <button
                  class="btn btn-sm border-gray-500 p-1 mt-2"
                  aria-expanded="false"
                >
                  <span class="pr-1">{{
                    i18n.t("services.eyecontrol.name")
                  }}</span>
                  <LockIcon class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 3
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t1 == null &&
          data.fridge.setpoint_t2 == null &&
          data.fridge.setpoint_t3 == null &&
          data.temperature &&
          (data.temperature.T1 != null ||
            data.temperature.T2 != null ||
            data.temperature.T3 != null ||
            data.temperature.T4 != null)
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <div
              v-if="data.temperature.T1 != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 1
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T1 }}° {{ data.temperature.T1_unit }}
              </div>
            </div>

            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_temperature_sensors_number > 1 &&
                data.temperature.T2 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 2
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T2 }}° {{ data.temperature.T2_unit }}
              </div>
            </div>

            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_temperature_sensors_number > 2 &&
                data.temperature.T3 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 3
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T3 }}° {{ data.temperature.T3_unit }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.fridge_temperature_sensors_number > 3 &&
                data.temperature.T4 != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyecold.temperature") }} 4
              </div>
              <div class="mb-2">
                <ThermometerIcon />
              </div>
              <div
                class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
              >
                {{ data.temperature.T4 }}° {{ data.temperature.T4_unit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyecold.temperatures_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.temperature &&
            history.list.temperature.length > 0 &&
            history.list.fridge &&
            history.list.fridge.length > 0
          "
        >
          <TemperatureChart
            :active="active"
            :height="200"
            :width="200"
            :temperature="history.list.temperature"
            :fridge="history.list.fridge"
          />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
        <div class="grid gap-2 grid-cols-2 mt-3">
          <div class="btn btn-elevated-rounded-primary" @click="requestReport">
            <FileTextIcon />
            <span class="truncate">{{ i18n.t("general.request-report") }}</span>
          </div>
          <div
            class="btn btn-elevated-rounded-secondary"
            @click="requestTemperatureReport"
          >
            <FileTextIcon />
            <span class="truncate">{{
              i18n.t("general.temperature-report")
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
    </div>
  </div>
</template>

<script>
import TemperatureChart from "@/components/temperature-chart/TemperatureChart.vue";
import CmdOnOff from "@/components/commands/CmdOnOff.vue";
import CmdRange from "@/components/commands/CmdRange.vue";
import CmdSelect from "@/components/commands/CmdSelect.vue";
import CmdText from "@/components/commands/CmdText.vue";
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";

export default {
  components: {
    TemperatureChart,
    CmdOnOff,
    CmdRange,
    CmdSelect,
    CmdText,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });
    const operating_modes = [
      { key: "1", label: "Start & Stop" },
      { key: "0", label: "Continuo" },
    ];

    function updateTemps(temp) {
      if (data.value == null) return;
      data.value.temperature = temp;

      if (
        dayjs().isSame(dayjs(historyTo.value), "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.temperature.length
      )
        history.value.list.temperature.unshift(temp);
    }
    function updateFridge(fridge) {
      if (data.value == null) return;
      data.value.fridge = fridge;

      if (
        dayjs().isSame(dayjs(historyTo.value), "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.fridge.length
      )
        history.value.list.fridge.unshift(fridge);
    }

    EventBus.on("vehicle:updates:temperature", updateTemps);
    EventBus.on("vehicle:updates:fridge", updateFridge);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyecold/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      getHistory();
    }

    // destroy service
    function destroyService() {}

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyecold", "master");
    }

    // requestReport service
    async function requestTemperatureReport() {
      let temps = [];
      if (
        vehicle.value.configurations &&
        vehicle.value.configurations.fridge_temperature_sensors_number
      ) {
        switch (
          vehicle.value.configurations.fridge_temperature_sensors_number
        ) {
          case 4:
          case "4":
            temps.push("eyecold.T4");
          // eslint-disable-next-line no-fallthrough
          case 3:
          case "3":
            temps.push("eyecold.T3");
          // eslint-disable-next-line no-fallthrough
          case 2:
          case "2":
            temps.push("eyecold.T2");
          // eslint-disable-next-line no-fallthrough
          case 1:
          case "1":
            temps.push("eyecold.T1");
        }
      } else {
        temps = ["eyecold.T1", "eyecold.T2", "eyecold.T3", "eyecold.T4"];
      }
      let doors = [];
      if (vehicle.value.configurations) {
        if (vehicle.value.configurations.door1_active) {
          doors.push("eyedoor.door_1");
        }
        if (vehicle.value.configurations.door2_active) {
          doors.push("eyedoor.door_2");
        }
        if (vehicle.value.configurations.door3_active) {
          doors.push("eyedoor.door_3");
        }
        if (vehicle.value.configurations.door4_active) {
          doors.push("eyedoor.door_4");
        }
      }
      emit("report-request", [...temps, ...doors], "temp");
    }

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyecold/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }
    // fridge commands
    async function sendFridgeCommand(command, cmdData) {
      switch (command) {
        case "setpoint":
          {
            const result = await store.dispatch("eyecold/setSetpoint", {
              vehicleId: vehicle.value.id,
              compartment: cmdData.compartment,
              value: cmdData.value,
            });
            if (result.success) {
              switch (cmdData.compartment) {
                case 1:
                  data.value.fridge.setpoint_t1_dirty = true;
                  data.value.fridge.setpoint_t1 = cmdData.value;
                  break;
                case 2:
                  data.value.fridge.setpoint_t2_dirty = true;
                  data.value.fridge.setpoint_t2 = cmdData.value;
                  break;
                case 3:
                  data.value.fridge.setpoint_t3_dirty = true;
                  data.value.fridge.setpoint_t3 = cmdData.value;
                  break;
              }
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "overall_status":
          {
            const result = await store.dispatch("eyecold/setOverallStatus", {
              vehicleId: vehicle.value.id,
              compartment: cmdData.compartment,
              value: cmdData.value,
            });
            if (result.success) {
              data.value.fridge.overall_status_dirty = true;
              data.value.fridge.overall_status = cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;

        case "operating_mode":
          {
            const result = await store.dispatch("eyecold/setOperatingMode", {
              vehicleId: vehicle.value.id,
              mode: cmdData,
            });
            if (result.success) {
              data.value.fridge.operating_mode_dirty = true;
              data.value.fridge.operating_mode = cmdData;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "odl_num":
          {
            const result = await store.dispatch("eyecold/setOdlNum", {
              vehicleId: vehicle.value.id,
              value: cmdData.value,
            });
            if (result.success) {
              vehicle.value.configurations.eyecold_fridge_odl_num =
                cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "odl_t1":
          {
            const result = await store.dispatch("eyecold/setOdlT1", {
              vehicleId: vehicle.value.id,
              value: cmdData.value,
            });
            if (result.success) {
              vehicle.value.configurations.eyecold_fridge_odl_t1 =
                cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
      }
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      loadingHistory,
      daterange,
      requestReport,
      requestTemperatureReport,
      sendFridgeCommand,
      operating_modes,
    };
  },
};
</script>

<style></style>
