<template>
  <DragCard
    :drag-active="dragActive"
    :card-id="entity.vehicle_id"
    drop-key="fleet-drop"
    class="relative drag-card"
  >
    <div
      v-if="showRemove"
      class="remove-btn absolute -top-2 -right-2 h-6 w-6 flex items-center justify-center rounded-full bg-reyesol-red cursor-pointer opacity-0 transition-opacity"
      @click.stop="removeCallback()"
    >
      <XIcon class="h-4 w-4 text-white" />
    </div>
    <div class="p-4">
      <div class="mb-2 grid place-items-center">
        <img
          :src="
            require(`@/assets/images/vehicles/${entity.vehicle_type}-primary.svg`)
          "
          class="block h-6 opacity-95"
        />
      </div>
      <div class="rounded-full px-2 bg-gray-300">{{ entity.plate }}</div>
    </div>
  </DragCard>
</template>

<script>
import DragCard from "@/components/drag-card/Main.vue";

export default {
  components: { DragCard },

  props: {
    entity: {
      type: Object,
      required: true,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    removeCallback: {
      type: Function,
      default: () => {},
    },
    dragActive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.drag-card:hover .remove-btn {
  opacity: 1;
}
</style>
