<template>
  <div
    class="rounded-full py-1 px-4 text-white text-lg font-bold text-center select-none"
    :class="classes"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["color"],

  setup(props) {
    const classes = {
      "bg-gray-700": !props.color || props.color == "gray",
      "bg-reyesol-green": props.color == "green",
      "bg-reyesol-red": props.color == "red",
      "bg-reyesol-blue": props.color == "blue",
      "bg-reyesol-yellow": props.color == "yellow",
    };

    return { classes };
  },
};
</script>
