<template>
  <div
    class="box p-4 cursor-pointer fleet-drop"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive">
      <div class="flex items-center justify-between">
        <div class="flex-1">
          <!-- name -->
          <div class="text-2xl font-bold mb-1">{{ customer.name }}</div>

          <!-- vat_id -->
          <div class="text-xs">
            {{ i18n.t("customers.vat_id") }}: {{ customer.vat_id }}
          </div>

          <!-- address -->
          <div class="text-lg my-1">{{ fullAddress(customer) }}</div>

          <!-- contacts -->
          <div class="text-xs mb-1">{{ customer.email }}</div>
          <div class="text-xs">{{ customer.phone }}</div>
        </div>

        <div class="flex">
          <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
            <EditIcon class="w-5 h-5" />
          </button>
          <button class="btn btn-danger p-1" @click.stop="deleteEntity(form)">
            <TrashIcon class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>

    <!-- form -->
    <div v-else>
      <div class="grid lg:grid-cols-2 gap-4 mb-4">
        <!-- name -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.name")
          }}</label>
          <validated-input
            :has-error="v$.name.$error"
            :errors="v$.name.$errors"
          >
            <input v-model="form.name" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- vat_id -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.vat_id")
          }}</label>
          <validated-input
            :has-error="v$.vat_id.$error"
            :errors="v$.vat_id.$errors"
          >
            <input v-model="form.vat_id" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- address -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.address")
          }}</label>
          <validated-input
            :has-error="v$.address.$error"
            :errors="v$.address.$errors"
          >
            <input v-model="form.address" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- city -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.city")
          }}</label>
          <validated-input
            :has-error="v$.city.$error"
            :errors="v$.city.$errors"
          >
            <input v-model="form.city" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- zip_code -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.zip_code")
          }}</label>
          <validated-input
            :has-error="v$.zip_code.$error"
            :errors="v$.zip_code.$errors"
          >
            <input v-model="form.zip_code" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- region -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.region")
          }}</label>
          <validated-input
            :has-error="v$.region.$error"
            :errors="v$.region.$errors"
          >
            <input v-model="form.region" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- email -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.email")
          }}</label>
          <validated-input
            :has-error="v$.email.$error"
            :errors="v$.email.$errors"
          >
            <input v-model="form.email" type="text" class="form-control" />
          </validated-input>
        </div>

        <!-- phone -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("customers.phone")
          }}</label>
          <validated-input
            :has-error="v$.phone.$error"
            :errors="v$.phone.$errors"
          >
            <input v-model="form.phone" type="text" class="form-control" />
          </validated-input>
        </div>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import _ from "lodash";

export default {
  components: {},

  props: {
    formOnly: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated", "updated:vehicles", "selected", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();

    // select
    function select() {
      if (!formActive.value) emit("selected", props.customer);
    }

    // form
    const formActive = ref(false);
    const formBk = ref(null);
    const form = ref(props.customer);
    if (!props.customer.id) showForm();

    function showForm() {
      formActive.value = true;
      formBk.value = _.cloneDeep(form.value);
    }

    function cancelForm() {
      formActive.value = false;
      form.value = _.cloneDeep(formBk.value);
      emit("canceled", props.customer);
    }

    // validation
    const rules = {
      vat_id: { required, numeric },
      name: { required },
      address: {},
      city: {},
      zip_code: {},
      region: {},
      email: { required, email },
      phone: { numeric },
    };

    const v$ = useVuelidate(rules, form);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      let result = null;

      if ("id" in form.value) {
        result = await store.dispatch("customers/update", form.value);
      } else {
        result = await store.dispatch("customers/store", form.value);
      }

      if (!result.success) {
        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        formActive.value = false;
        emit("updated", result.validated);
      }

      loading.value = false;
    }

    // delete
    async function deleteEntity(customer) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("customers.confirm-delete-title")} ${customer.name}?`,
        text: i18n.t("customers.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch(
              "customers/delete",
              customer.id
            );
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    function fullAddress(customer) {
      return `${customer.address}, ${customer.zip_code}, ${customer.city}, ${customer.region}`;
    }

    return {
      i18n,

      // select
      select,

      // form
      form,
      v$,
      formActive,
      showForm,
      cancelForm,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // utils
      fullAddress,
    };
  },
};
</script>

<style></style>
