import { carbontax as api } from "@/api";

const state = () => {
  return {
    data: [],
  };
};

// getters
const getters = {
  vehicleData: (state) => (vehicleId) => {
    return state.data.find((d) => d.vehicle_id == vehicleId);
  },
};

// actions
const actions = {
  async getVehicleData({ dispatch }, vehicleId) {
    // const cachedData = getters.vehicleData(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleData", vehicleId);
  },

  async fetchVehicleData({ commit, getters }, vehicleId) {
    const result = await api.getVehicleData(vehicleId);
    if (result.success) {
      commit("REMOVE_VEHICLE_DATA", vehicleId);
      commit("PUSH_VEHICLE_DATA", { vehicle_id: vehicleId, ...result.data });
    }
    result.validated = getters.vehicleData(vehicleId);
    return result;
  },
};

// mutations
const mutations = {
  PUSH_VEHICLE_DATA(state, vehicleData) {
    state.data.push(vehicleData);
  },
  REMOVE_VEHICLE_DATA(state, vehicleId) {
    const i = state.data.findIndex((d) => d.vehicle_id == vehicleId);
    if (i != -1) state.data.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
