<template>
  <div v-if="vehicle" id="service-general">
    <div class="service-tabs">
      <button
        class="truncate hover:bg-gray-300"
        :class="
          activeTab === 'general' &&
          'active bg-gray-100 border-b-4 border-green-400'
        "
        @click="activeTab = 'general'"
      >
        {{ i18n.t("services.general.tabs.general") }}
      </button>
      <button
        class="truncate flex flex-row justify-center gap-1 hover:bg-gray-300"
        :class="
          activeTab === 'events' &&
          'active bg-gray-100 border-b-4 border-green-400'
        "
        @click="activeTab = 'events'"
      >
        <span class="truncate">{{
          i18n.t("services.general.tabs.events")
        }}</span>
        <span
          v-if="active_alerts && active_alerts.length > 0"
          class="rounded-full bg-yellow-400 px-2"
          >{{ active_alerts.length }}</span
        >
      </button>
      <button
        class="truncate hover:bg-gray-300"
        :class="
          activeTab === 'contracts' &&
          'active bg-gray-100 border-b-4 border-green-400'
        "
        @click="activeTab = 'contracts'"
      >
        {{ i18n.t("services.general.tabs.contracts") }}
      </button>
      <button
        class="truncate hover:bg-gray-300"
        :class="
          activeTab === 'config' &&
          'active bg-gray-100 border-b-4 border-green-400'
        "
        @click="activeTab = 'config'"
      >
        {{ i18n.t("services.general.tabs.config") }}
      </button>
    </div>

    <!-- general -->
    <div v-show="activeTab === 'general'" class="report-box">
      <div class="box p-3 text-center">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col items-center mr-1 md:mr-3">
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("vehicles.plate") }}
            </div>
            <div class="mb-2">
              <CreditCardIcon />
            </div>
            <div
              class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
            >
              {{ vehicle.plate }}
            </div>
          </div>
          <div class="flex flex-col items-center mr-1 md:mr-3">
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("vehicles.model") }}
            </div>
            <div class="mb-2">
              <TruckIcon />
            </div>
            <div
              class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
            >
              {{ vehicle.model }}
            </div>
          </div>
          <div class="flex flex-col items-center mr-1 md:mr-3">
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("vehicles.serial") }}
            </div>
            <div class="mb-2">
              <HashIcon />
            </div>
            <div
              class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
            >
              {{ vehicle.serial }}
            </div>
          </div>
          <div class="flex flex-col items-center mr-1 md:mr-3">
            <div class="py-1 px-2 text-xs font-medium">
              {{ i18n.t("vehicles.type") }}
            </div>
            <div class="mb-2">
              <img
                :src="
                  require(`@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`)
                "
                class="block h-6 opacity-95"
              />
            </div>
            <div
              class="py-1 px-2 rounded text-xs text-white cursor-pointer font-medium bg-theme-10"
            >
              {{ i18n.t(`vehicles.types.${vehicle.vehicle_type}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- events -->
    <div v-show="activeTab === 'events'" class="report-box">
      <div v-if="active_alerts.length == 0" class="box p-5">
        <h2>{{ i18n.t("services.general.events.no-events") }}</h2>
      </div>
      <div v-else>
        <div class="flex flex-col gap-3">
          <div
            v-for="alert in active_alerts"
            :key="alert.id"
            class="report-box-2"
          >
            <div class="box p-6 space-y-2">
              <div class="text-lg">
                {{ alert.description }}
              </div>
              <div
                class="text-sm"
                :class="{
                  'text-gray-700': !alert.severity || alert.severity == 0,
                  'text-reyesol-red': alert.severity == 2,
                  'text-reyesol-yellow': alert.severity == 1,
                }"
              >
                {{ i18n.t(`alerts.severity.${alert.severity}`) }}
              </div>
              <div class="text-base font-light">
                {{ dayjs(alert.created_at).format("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- contracts -->
    <div v-show="activeTab === 'contracts'" class="report-box">
      <div class="flex-cols">
        <div
          v-for="av in active_agreements"
          :key="av.id"
          class="report-box-2 my-6"
        >
          <div class="box p-6 space-y-2">
            <div class="text-lg">
              {{ i18n.t(`services.${av.code}.name`) }}
            </div>
            <div
              class="text-sm"
              :class="{
                'text-gray-700': !av.color || av.color == 'gray',
                'text-reyesol-green': av.color == 'green',
                'text-reyesol-red': av.color == 'red',
                'text-reyesol-blue': av.color == 'blue',
                'text-reyesol-yellow': av.color == 'yellow',
              }"
            >
              {{ i18n.t(`agreements.state.${av.color}`) }}
            </div>
            <div class="text-base font-light">
              {{ dayjs(av.active_from).format("YYYY-MM-DD") }} -
              {{ dayjs(av.active_to).format("YYYY-MM-DD") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- config -->
    <div v-show="activeTab === 'config'" class="report-box">
      <div class="box p-5 flex flex-col overflow-hidden">
        <div class="dropdown p-2">
          <button
            class="dropdown-toggle w-full btn btn-sm btn-warning px-4 mt-2"
            aria-expanded="false"
          >
            {{ i18n.t("services.general.config.logging_interval_button") }}
          </button>
          <div class="dropdown-menu w-80">
            <div class="dropdown-menu__content box p-4">
              <CmdSelect
                :options="intervals_list"
                :initial-value="actual_interval"
                :name="i18n.t('services.general.config.logging_interval')"
                :description="
                  i18n.t('services.general.config.logging_interval_description')
                "
                @send="(v) => sendInterval(v)"
              />
            </div>
          </div>
        </div>
        <div
          class="btn btn-elevated-primary w-full mt-5"
          @click="requestReport"
        >
          <FileTextIcon /> {{ i18n.t("general.request-report") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import CmdSelect from "@/components/commands/CmdSelect.vue";

export default {
  components: {
    CmdSelect,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const active_alerts = ref([]);
    const active_agreements = ref([]);
    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function initService() {
      active_alerts.value = vehicle.value.alerts
        .map((active_alert) => {
          let alerts = store.getters["alerts/collection"];
          let alert = alerts.find((a) => a.id == active_alert.alert_id);
          active_alert.severity = alert.severity;
          active_alert.description = alert.description;
          active_alert.time = dayjs(active_alert.created_at);
          return active_alert;
        })
        .sort((a, b) => {
          if (a.time.isSame(b, "minute")) return 0;
          if (a.time.isBefore(b, "minute")) return -1;
          return 0;
        });

      active_agreements.value = vehicle.value.active_agreements.map(
        (agreement) => {
          let agreements = store.getters["agreements/collection"];
          const from = dayjs(agreement.active_from);
          const to = dayjs(agreement.active_to);

          let color = "gray";
          if (from.diff(dayjs(), "day") <= 0) {
            color = "green";
            const toDiff = to.diff(dayjs(), "day");
            if (toDiff <= 0) color = "red";
            if (toDiff > 0 && toDiff <= 30) color = "yellow";
          }
          agreement.color = color;
          agreement.code = agreements.find(
            (a) => a.id == agreement.agreement_id
          ).code;
          return agreement;
        }
      );
    }

    function destroyService() {
      // destroy service
    }

    // Tabs
    const activeTab = ref("general");

    // requestReport service
    async function requestReport() {
      console.log("requestReport");
      emit("report-request", []);
    }

    async function sendInterval(interval) {
      actual_interval.value = null;
      const result = await store.dispatch("vehicles/setLogInterval", {
        vehicleId: vehicle.value.id,
        value: interval,
      });
      if (result.success) {
        actual_interval.value = interval;
        EventBus.emit("notification:show", {
          type: "info",
          title: i18n.t("services.eyecontrol.command_submitted"),
        });
      }
    }
    const actual_interval = ref(null);

    const intervals_list = [
      { key: "900", label: "15 minuti" },
      { key: "1800", label: "30 minuti" },
      { key: "2700", label: "45 minuti" },
      { key: "3600", label: "60 minuti" },
    ];

    return {
      i18n,
      vehicle,
      active_alerts,
      active_agreements,
      dayjs,
      actual_interval,
      intervals_list,
      sendInterval,
      requestReport,
      activeTab,
    };
  },
};
</script>
