import Toastify from "toastify-js";
const defaults = {
  duration: 3000,
  close: true,
  gravity: "top",
  position: "right",
  stopOnFocus: true,
  className: "toastify-content",
};
const toast = {
  info: function (message) {
    Toastify({
      ...defaults,
      text: message,
    }).showToast();
  },
  success: function (message) {
    Toastify({
      ...defaults,
      text: message,
      style: {
        color: "#ffffff",
        backgroundColor: "#13B176",
      },
    }).showToast();
  },
  error: function (message) {
    Toastify({
      ...defaults,
      text: message,
      style: {
        color: "#ffffff",
        backgroundColor: "#D32929",
      },
    }).showToast();
  },
};

export default toast;
