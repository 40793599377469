<template>
  <div class="grid grid-cols-12 gap-6 mx-auto pt-2">
    <VueFinalModal
      v-model="newsModal"
      @closed="newsClosed"
      class="flex justify-center items-center"
      content-class="flex flex-col max-w-3xl min-h-fit w-4/5 h-5/6 mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2"
    >
      <div class="flex-grow bg-gray-900"><iframe class="w-full h-full" :src="news_link.url"></iframe></div>
      <div class="text-center">
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="newsModal = false">
          Close
        </button>
      </div>
    </VueFinalModal>

    <!-- BEGIN: Map -->
    <div class="col-span-12 md:col-span-8 mt-2 flex flex-col">
      <div class="intro-y flex items-center">
        <h2 class="text-lg font-medium truncate mr-5">
          {{ i18n.t("dashboard.map.title") }}
        </h2>
        <a
          class="ml-auto flex items-center text-theme-1 cursor-pointer"
          @click="forceReload"
        >
          <RefreshCcwIcon class="w-4 h-4 mr-3" />{{
            i18n.t("dashboard.reload")
          }}
        </a>
      </div>
      <div class="intro-y box mt-2 p-5 flex-grow">
        <Map
          class="aspect-w-4 aspect-h-3 md:relative md:h-full md:w-full report-maps bg-gray-200 rounded-md"
          @init="handleMapInit"
          @marker:click="handleMarkerClick"
        ></Map>
      </div>
    </div>
    <!-- END: Map -->

    <div class="col-span-12 md:col-span-4 mt-2 flex flex-col">
      <!-- BEGIN: Status chart -->
      <!--<div>
        <div class="intro-y flex items-center">
          <h2 class="text-lg font-medium truncate mr-5">
            {{ i18n.t("dashboard.vechicles_status.title") }}
          </h2>
        </div>
        <div v-if="dashboardData" class="intro-y box p-5 mt-2 flex flex-col">
          <h3 class="text-lg font-medium truncate mr-5">
            {{ i18n.t("dashboard.vechicles_status.total") }}
            <span class="px-2 bg-theme-1 rounded-full text-white mr-1">
              {{ vehicles_total }}
            </span>
          </h3>
          <div class="w-full md:w-1/2 self-center">
            <DashboardStatusPie
              :height="300"
              class="mt-3"
              :statuses="dashboardData.status"
              @chart_click="handleStatusClick"
            />
          </div>
          <div class="mt-2h">
            <div
              v-for="s in dashboardData.status"
              :key="s.status"
              :class="
                s.status == 0
                  ? 'text-red-500'
                  : s.status == 1
                  ? 'text-green-500'
                  : 'text-gray-500'
              "
              class="flex items-center cursor-pointer"
              @click="selectStatus(s.status)"
            >
              <div
                class="w-2 h-2 rounded-full mr-3"
                :class="{
                  'bg-red-500': s.status == 0,
                  'bg-green-500': s.status == 1,
                }"
              ></div>
              <span v-if="s.status == 0" class="truncate text-red-500">{{
                i18n.t("dashboard.vechicles_status.status.disconnected")
              }}</span>
              <span v-else-if="s.status == 1" class="truncate text-green-500">{{
                i18n.t("dashboard.vechicles_status.status.connected")
              }}</span>
              <span v-else class="truncate text-grey-500">{{
                i18n.t("dashboard.vechicles_status.status.alarm")
              }}</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <span class="font-medium xl:ml-auto"
                >{{
                  Number(
                    (s.total / dashboardData.vehicles.total) * 100
                  ).toFixed(0)
                }}%
                <span
                  class="text-xs px-2 rounded-full text-white mr-1"
                  :class="
                    s.status == 0
                      ? 'bg-red-500'
                      : s.status == 1
                      ? 'bg-green-500'
                      : 'bg-gray-500'
                  "
                  >{{ s.total }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>-->
      <!-- END: Status chart -->
      <div>
        <div
          class="h-screen md:h-screen max-h-screen flex flex-col overflow-auto"
        >
          <FilterDetail
            :active="true"
            :service="null"
            :feature="null"
            :vehicles="map_vehicles"
          />
        </div>
      </div>
      <!-- BEGIN: Alerts chart -->
      <!--<div>
        <div v-if="dashboardData" class="intro-y box p-8 mt-2 flex flex-col">
          <h3 class="text-lg font-medium truncate mr-5">
            {{ i18n.t("dashboard.alarms_status.total") }}
            <span class="px-2 bg-theme-1 rounded-full text-white mr-1">
              {{ dashboardData.total_alerts }}
            </span>
          </h3>
          <div class="w-full md:w-1/2 self-center">
            <DashboardAlertsDonut
              :height="300"
              class="mt-3"
              :colors="alarm_color"
              :alerts="dashboardData.alerts"
              @chart_click="handleAlertClick"
            />
          </div>

          <div class="mt-2">
            <h2
              v-if="!dashboardData.alerts || dashboardData.alerts.length == 0"
            >
              {{ i18n.t("dashboard.alarms_status.empty") }}
            </h2>
            <div
              v-for="(a, index) in dashboardData.alerts"
              :key="a.alert_id"
              class="flex items-center cursor-pointer mt-1"
              :style="'color:' + alarm_color[index]"
              @click="selectAlert(a)"
            >
              <div
                class="w-2 h-2 rounded-full mr-3"
                :style="'background-color:' + alarm_color[index]"
              ></div>
              <span class="truncate">{{ alertDescription(a) }}</span>
              <div
                class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
              ></div>
              <div class="font-medium xl:ml-auto flex flex-row gap-1">
                <span
                  >{{
                    Number(
                      (a.total / dashboardData.total_alerts) * 100
                    ).toFixed(0)
                  }}%
                </span>
                <span
                  class="text-xs px-2 rounded-full text-white mr-1"
                  :style="'background-color:' + alarm_color[index]"
                  >{{ a.total }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <!-- END: Alerts chart -->
    </div>
    <!-- BEGIN: Alert Status -->
    <div class="col-span-12 mt-2 hidden md:grid">
      <div class="intro-y flex items-center">
        <h2 class="text-lg font-medium truncate mr-5">
          {{ i18n.t("dashboard.services.title") }}
        </h2>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <AlertsBox
          v-for="b in boxes"
          :key="b.title"
          :items="b.items"
          :title="b.title"
          :icon="b.icon"
          :service="b.service"
          @click="handleAlertBoxClick"
          @title_click="handleAlertBoxTitleClick"
        />
      </div>
    </div>
    <!-- END: Alert Status -->
  </div>
</template>

<script>
import {
  ref,
  watch,
  provide,
  reactive,
  onBeforeUnmount,
  computed,
  createApp,
} from "vue";
import { ModalsContainer, VueFinalModal } from 'vue-final-modal'
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import AlertsBox from "@/components/alerts-box/Main.vue";
import DashboardStatusPie from "@/components/dashboard-status-pie/Main.vue";
import DashboardAlertsDonut from "@/components/dashboard-alerts-donut/Main.vue";
import Map from "@/components/map/Map.vue";
import FilterDetail from "@/views/global-map/FilterDetail.vue";
import { useFleetFilter } from "@/composables/filters";

import PopupHubRenderer from "@/components/popup-rendering/PopupHubRenderer.vue";
import PopupPoiRenderer from "@/components/popup-rendering/PopupPoiRenderer.vue";
import PopupTruckRenderer from "@/components/popup-rendering/PopupTruckRenderer.vue";

// eslint-disable-next-line vue/one-component-per-file
export default {
  components: {
    AlertsBox,
    DashboardStatusPie,
    DashboardAlertsDonut,
    Map,
    FilterDetail,
    VueFinalModal,
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const dashboardData = reactive(ref(null));
    const vehicles_total = ref(0);
    const newsModal = ref(false);
    const news_link = ref(null);
    const vehicles_increment = ref(0);
    const users_total = ref(0);
    const users_increment = ref(0);
    const reports_total = ref(0);
    const reports_increment = ref(0);
    const customers_total = ref(0);
    const customers_increment = ref(0);
    const intervalHandle = setInterval(fetchDashboardData, 60000);
    const alerts = store.getters["alerts/collection"];
    const agreements = store.getters["agreements/collection"];
    const map_vehicles = ref([]);

    const alarm_color = ref([
      "#FF6633",
      "#FFB399",
      "#FF33FF",
      "#CCAA99",
      "#00B3E6",
      "#E6B333",
      "#3366E6",
      "#999966",
      "#99FF99",
      "#B34D4D",
      "#80B300",
      "#809900",
      "#E6B3B3",
      "#6680B3",
      "#66991A",
      "#FF99E6",
      "#CCFF1A",
      "#FF1A66",
      "#E6331A",
      "#33FFCC",
      "#66994D",
      "#B366CC",
      "#4D8000",
      "#B33300",
      "#CC80CC",
      "#66664D",
      "#991AFF",
      "#E666FF",
      "#4DB3FF",
      "#1AB399",
      "#E666B3",
      "#33991A",
      "#CC9999",
      "#B3B31A",
      "#00E680",
      "#4D8066",
      "#809980",
      "#E6FF80",
      "#1AFF33",
      "#999933",
      "#FF3380",
      "#CCCC00",
      "#66E64D",
      "#4D80CC",
      "#9900B3",
      "#E64D66",
      "#4DB380",
      "#FF4D4D",
      "#99E6E6",
      "#6666FF",
    ]);

    const boxes = reactive(
      ref([
        {
          icon: "MapPinIcon",
          title: i18n.t("services.eyegeo.name"),
          service: "eyegeo",
          items: [
            {
              title: i18n.t("features.speed_limit.name"),
              tooltip: "i18n.t('features.speed_limit.name')",
              service: "eyegeo",
              severity: 2,
              feature: "speed_limit",
              icon: "speedometer.svg",
            },
            {
              title: i18n.t("features.hub_enter.name"),
              tooltip: "i18n.t('features.hub_enter.name')",
              service: "eyegeo",
              severity: 0,
              feature: "hub_enter",
              icon: "home.svg",
            },
            {
              title: i18n.t("features.hub_exit.name"),
              tooltip: "i18n.t('features.hub_exit.name')",
              service: "eyegeo",
              severity: 1,
              feature: "hub_exit",
              icon: "earth.svg",
            },
            {
              title: i18n.t("features.poi_enter.name"),
              tooltip: "i18n.t('features.poi_enter.name')",
              service: "eyegeo",
              severity: 1,
              feature: "poi_enter",
              icon: "inside.svg",
            },
            {
              title: i18n.t("features.poi_exit.name"),
              tooltip: "i18n.t('features.poi_exit.name')",
              service: "eyegeo",
              severity: 1,
              feature: "poi_exit",
              icon: "outside.svg",
            },
          ],
        },
        {
          icon: "DropletIcon",
          title: i18n.t("services.eyecold.name"),
          service: "eyecold",
          items: [
            {
              title: i18n.t("features.cooling_system_off.name"),
              tooltip: "i18n.t('features.cooling_system_off.name')",
              service: "eyecold",
              severity: 1,
              feature: "cooling_system_off",
              icon: "zap-off.svg",
            },
            {
              title: i18n.t("features.temperature_out_of_range.name"),
              tooltip: "i18n.t('features.temperature_out_of_range.name')",
              service: "eyecold",
              severity: 2,
              feature: "temperature_out_of_range",
              icon: "low-temperature-thermometer.svg",
            },
            {
              title: i18n.t("features.setpoint_out_of_range.name"),
              tooltip: "i18n.t('features.setpoint_out_of_range.name')",
              service: "eyecold",
              severity: 2,
              feature: "setpoint_out_of_range",
              icon: "temperature.svg",
            },
            {
              title: i18n.t("features.local_unit_override.name"),
              tooltip: "i18n.t('features.local_unit_override.name')",
              service: "eyecold",
              severity: 1,
              feature: "local_unit_override",
              icon: "hand.svg",
            },
            {
              title: i18n.t("features.cooling_system_alarm.name"),
              tooltip: "i18n.t('features.cooling_system_alarm.name')",
              service: "eyecold",
              severity: 2,
              feature: "cooling_system_alarm",
              icon: "cold.svg",
            },
          ],
        },
        {
          icon: "LogInIcon",
          title: i18n.t("services.eyedoor.name"),
          service: "eyedoor",
          items: [
            {
              title: i18n.t("features.door_open.name"),
              tooltip: "i18n.t('features.door_open.name')",
              service: "eyedoor",
              severity: 1,
              feature: "door_open",
              icon: "truck.svg",
            },
            {
              title: i18n.t("features.door_siren.name"),
              tooltip: "i18n.t('features.door_siren.name')",
              service: "eyedoor",
              severity: 2,
              feature: "door_siren",
              icon: "siren-siren.svg",
            },
            {
              title: i18n.t("features.door_locking.name"),
              tooltip: "i18n.t('features.door_locking.name')",
              service: "eyedoor",
              severity: 1,
              feature: "door_locking",
              icon: "broken-padlock.svg",
            },
            {
              title: i18n.t("features.cooling_door_open.name"),
              tooltip: "i18n.t('features.cooling_door_open.name')",
              service: "eyedoor",
              severity: 1,
              feature: "cooling_door_open",
              icon: "door-of-opportunity-option-opportunity-chance.svg",
            },
          ],
        },
        {
          icon: "TrendingDownIcon",
          title: i18n.t("services.eyefuel.name"),
          service: "eyefuel",
          items: [
            {
              title: i18n.t("features.cap_open.name"),
              tooltip: "i18n.t('features.cap_open.name')",
              service: "eyefuel",
              severity: 1,
              feature: "cap_open",
              icon: "gas-station-fuel.svg",
            },
            {
              title: i18n.t("features.fuel_alarm.name"),
              tooltip: "i18n.t('features.fuel_alarm.name')",
              service: "eyefuel",
              severity: 2,
              feature: "fuel_alarm",
              icon: "fuel-counter.svg",
            },
            {
              title: i18n.t("features.fuel_running_low.name"),
              tooltip: "i18n.t('features.fuel_running_low.name')",
              service: "eyefuel",
              severity: 2,
              feature: "fuel_running_low",
              icon: "low-tide.svg",
            },
          ],
        },
        {
          icon: "ToolIcon",
          title: i18n.t("services.eyeservice.name"),
          service: "eyeservice",
          items: [
            {
              title: i18n.t("features.service.name"),
              tooltip: "i18n.t('features.service.name')",
              service: "eyeservice",
              severity: 0,
              feature: "service",
              icon: "repairing-service.svg",
            },
            {
              title: i18n.t("features.fgas.name"),
              tooltip: "i18n.t('features.fgas.name')",
              service: "eyeservice",
              severity: 0,
              feature: "fgas",
              icon: "gas.svg",
            },
            {
              title: i18n.t("features.recorder.name"),
              tooltip: "i18n.t('features.recorder.name')",
              service: "eyeservice",
              severity: 0,
              feature: "recorder",
              icon: "crono.svg",
            },
          ],
        },
        {
          icon: "MapIcon",
          title: i18n.t("services.eyeway.name"),
          service: "eyeway",
          items: [
            {
              title: i18n.t("features.brake.name"),
              tooltip: "i18n.t('features.brake.name')",
              service: "eyeway",
              severity: 2,
              feature: "brake",
              icon: "break.svg",
            },
            {
              title: i18n.t("features.tyres.name"),
              tooltip: "i18n.t('features.tyres.name')",
              service: "eyeway",
              severity: 2,
              feature: "tyres",
              icon: "tire.svg",
            },
            {
              title: i18n.t("features.overload.name"),
              tooltip: "i18n.t('features.overload.name')",
              service: "eyeway",
              severity: 2,
              feature: "overload",
              icon: "weight.svg",
            },
          ],
        },
        {
          icon: "Share2Icon",
          title: i18n.t("services.eyeshare.name"),
          service: "eyeshare",
          items: [
            {
              title: i18n.t("features.sub_agreement_ends.name"),
              tooltip: "i18n.t('features.sub_agreement_ends.name')",
              service: "eyeshare",
              severity: 0,
              feature: "sub_agreement_ends",
              icon: "share-info.svg",
            },
          ],
        },
        {
          icon: "CrosshairIcon",
          title: i18n.t("services.tandt.name"),
          service: "tandt",
          items: [
            {
              title: i18n.t("features.tandt_locked.name"),
              tooltip: "i18n.t('features.tandt_locked.name')",
              service: "tandt",
              severity: 1,
              feature: "tandt_locked",
              icon: "link_done.svg",
            },
            {
              title: i18n.t("features.tandt_unlocked.name"),
              tooltip: "i18n.t('features.tandt_unlocked.name')",
              service: "tandt",
              severity: 1,
              feature: "tandt_unlocked",
              icon: "link_unlock.svg",
            },
            {
              title: i18n.t("features.tandt_unauthorized.name"),
              tooltip: "i18n.t('features.tandt_unauthorized.name')",
              service: "tandt",
              severity: 2,
              feature: "tandt_unauthorized",
              icon: "link_alarm.svg",
            },
          ],
        },
      ])
    );

    let mapController = null;

    onBeforeUnmount(() => {
      clearInterval(intervalHandle);
    });

    // watch(() => props, { deep: true });

    async function fetchDashboardData() {
      dashboardData.value = (
        await store.dispatch("dashboard/getDashboardData")
      ).validated;
      dashboardData.value.total_alerts = dashboardData.value.alerts.reduce(
        (p, i) => p + i.total,
        0
      );
      vehicles_total.value = dashboardData.value.vehicles.total;
      vehicles_increment.value = dashboardData.value.vehicles.increment;
      users_total.value = dashboardData.value.users.total;
      users_increment.value = dashboardData.value.users.increment;
      reports_total.value = dashboardData.value.reports.total;
      reports_increment.value = dashboardData.value.reports.increment;
      customers_total.value = dashboardData.value.customers.total;
      customers_increment.value = dashboardData.value.customers.increment;
      
      if(dashboardData.value.news){
        news_link.value = dashboardData.value.news.latest;

        if(localStorage[news_link.value.id] === undefined){
          newsModal.value = true;
        }
      }
    }

    function newsClosed(){
      localStorage[news_link.value.id] = false;
    }

    fetchDashboardData();

    const salesReportFilter = ref();
    const importantNotesRef = ref();

    provide("bind[importantNotesRef]", (el) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo("next");
    };

    function handleAlertBoxClick(item) {
      store.dispatch("globalMap/setRequestedService", item.service);
      store.dispatch("globalMap/setRequestedFeature", item.feature);
      router.push({
        path: "/global-map",
        query: { f: item.feature, s: item.service },
      });
    }

    function handleAlertBoxTitleClick(service) {
      store.dispatch("globalMap/setRequestedService", service);
      store.dispatch("globalMap/setRequestedFeature", "active");
      router.push({
        path: "/global-map",
        query: { f: "active", s: service },
      });
    }

    function handleAlertClick({ selected }) {
      let obj = alerts.find((o) => o.id == selected.alert_id);
      let agreement = agreements.find((o) => o.id == obj.agreement_id);

      store.dispatch("globalMap/setRequestedService", agreement.code);
      store.dispatch("globalMap/setRequestedFeature", obj.name);
      router.push({
        path: "/global-map",
        query: { f: obj.name, s: agreement.code },
      });
    }

    function handleStatusClick({ selected }) {
      let feature =
        selected.status == 1
          ? "connected"
          : selected.status == 0
          ? "disconnected"
          : "alarm";

      store.dispatch("globalMap/setRequestedService", "general");
      store.dispatch("globalMap/setRequestedFeature", feature);
      router.push({
        path: "/global-map",
        query: { f: feature, s: "general" },
      });
    }

    async function forceReload() {
      fetchDashboardData();
    }

    function alertDescription(alert) {
      let obj = alerts.find((o) => o.id == alert.alert_id);
      if (!obj) return "";
      return obj.description;
    }

    function selectAlert(alert) {
      let obj = alerts.find((o) => o.id == alert.alert_id);
      if (!obj) router.forceReload();
      let agreement = agreements.find((o) => o.id == obj.agreement_id);
      if (!agreement) router.forceReload();

      store.dispatch("globalMap/setRequestedService", agreement.code);
      store.dispatch("globalMap/setRequestedFeature", obj.name);
      router.push({
        path: "/global-map",
        query: { f: obj.name, s: agreement.code },
      });
    }

    function selectStatus(status) {
      let feature =
        status == 1 ? "connected" : status == 0 ? "disconnected" : "alarm";
      store.dispatch("globalMap/setRequestedService", "general");
      store.dispatch("globalMap/setRequestedFeature", feature);
      router.push({
        path: "/global-map",
        query: { f: feature, s: "general" },
      });
    }

    function handleMapInit(controller) {
      mapController = controller;
    }

    function handleMarkerClick({ marker }) {
      router.push({ path: "/global-map", query: { v: marker.id } });
    }

    // pins
    const pins = ref([]);
    const { filteredCollection: filteredPins } = useFleetFilter({
      collectionRef: pins,
      fetchCollectionCallback: async () => {
        const result = await store.dispatch("globalMap/getPins");
        return result.validated;
      },
    });

    watch(filteredPins, drawMarkers);
    async function drawMarkers() {
      const map_pins = ref(filteredPins);
      map_vehicles.value = map_pins.value;
      boxes.value = boxes.value.map((box) => {
        box.items = box.items.map((item) => {
          const alert = alerts.find((a) => a.name == item.feature);
          switch (item.feature) {
            case "all":
              item.count = map_pins.value.length;
              break;
            case "disconnected":
              item.count = map_pins.value.filter(
                (pin) => pin.status == 0
              ).length;
              break;
            case "connected":
              item.count = map_pins.value.filter(
                (pin) => pin.status == 1
              ).length;
              break;
            case "alarm":
              item.count = map_pins.value.filter(
                (pin) => pin.active_alerts.length > 0
              ).length;
              break;
            default:
              if (alert == undefined) return item;
              item.count = map_pins.value.reduce(function (count, pin) {
                var s = pin.active_alerts.find((a) => a.alert_id == alert.id);
                if (s) return count + 1;
                return count;
              }, 0);
              break;
          }
          return item;
        });

        return box;
      });

      if (!mapController) return;
      mapController.clearGroup("pins");
      const fleets = ref(store.getters["fleets/collection"]);
      filteredPins.value.forEach(async (p) => {
        const fleet = fleets.value.find((f) => f.id == p.fleet_id);
        const markerColor = fleet ? fleet.color : "#000000";
        mapController.addMarker({
          id: p.vehicle_id,
          latitude: p.latitude ?? 0,
          longitude: p.longitude ?? 0,
          datetime: p.message_datetime,
          icon: p.vehicle_type,
          color: markerColor,
          group: "pins",
          popup: {
            content: function () {
              var app = createApp(PopupTruckRenderer, { vehicle: p })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
          additionalData: {
            plate: p.plate,
            direction: p.direction,
            status: p.status,
            moving: p.moving,
          },
        });
      });
    }

    // hubs
    const hubs = ref([]);
    async function fetchHubs() {
      const response = await store.dispatch("hubs/getCollection");
      hubs.value = response.validated;
    }
    fetchHubs();

    watch(hubs, drawHubs);
    async function drawHubs() {
      if (!mapController) return;

      mapController.clearCircles();
      hubs.value.forEach((h) => {
        mapController.addCircle({
          id: h.id,
          latitude: h.latitude,
          longitude: h.longitude,
          radius: h.radius,
          color: h.color,
          popup: {
            content: function () {
              var app = createApp(PopupHubRenderer, { hub: h })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
        });
      });
    }

    // pois
    const pois = ref([]);
    async function fetchPois() {
      const response = await store.dispatch("pois/getCollection");
      pois.value = response.validated;
    }
    fetchPois();

    watch(pois, drawPois);
    async function drawPois() {
      if (!mapController) return;

      mapController.clearCircles();
      pois.value.forEach((h) => {
        mapController.addCircle({
          id: h.id,
          group: "pois",
          latitude: h.latitude,
          longitude: h.longitude,
          radius: h.radius,
          color: h.color,
          popup: {
            content: function () {
              var app = createApp(PopupPoiRenderer, { poi: h })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
        });
      });
    }

    function goTo(path) {
      router.push(path);
    }

    function refresh() {
      router.push({ path: "/global-map" });
      store.dispatch("globalMap/clear");
    }
    EventBus.on("global-map:refresh", refresh);

    return {
      dayjs,
      i18n,
      boxes,
      goTo,
      news_link,
      newsClosed,
      refresh,
      newsModal,
      alarm_color,
      salesReportFilter,
      handleAlertBoxClick,
      handleAlertBoxTitleClick,
      handleAlertClick,
      handleStatusClick,
      selectStatus,
      selectAlert,
      handleMapInit,
      handleMarkerClick,
      prevImportantNotes,
      nextImportantNotes,
      forceReload,
      alertDescription,
      dashboardData,
      vehicles_total,
      vehicles_increment,
      users_total,
      users_increment,
      reports_total,
      reports_increment,
      customers_total,
      customers_increment,
      map_vehicles,
    };
  },
};
</script>
