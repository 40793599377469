<template>
  <div id="alerts-dropdown" class="intro-x dropdown mx-2">
    <div
      class="dropdown-toggle notification cursor-pointer"
      role="button"
      aria-expanded="false"
      @click="isactive == true"
    >
      <AlertTriangleIcon class="alert__icon" />
    </div>
    <div class="notification-content pt-2 dropdown-menu">
      <div
        class="notification-content__box dropdown-menu__content box h-screen mb-4 overflow-y-auto"
      >
        <div
          class="fixed top-3 left-0 w-full h-14 p-2 flex flex-row bg-white rounded-md shadow-sm z-50"
        >
          <div class="font-medium capitalize ml-2 mr-auto">
            {{ i18n.t("general.alerts") }}

            <div
              class="text-theme-1 text-xs cursor-pointer"
              @click="goToAlerts()"
            >
              {{ i18n.t("general.see-all-alerts") }}
            </div>
          </div>

          <div class="text-theme-1 my-2 text-sm cursor-pointer mr-2">
            <input
              id="alerts_active"
              :checked="notify"
              class="form-check-switch"
              type="checkbox"
              @input="$emit('update:notify', $event.target.checked)"
            />
          </div>
        </div>
        <br />
        <div
          v-for="(a, index) in alerts"
          :key="index"
          class="cursor-pointer overflow-y-auto relative mt-5 py-2 hover:bg-gray-200 rounded-md"
          @click="goToVehicle(a)"
        >
          <div>
            <div class="grid grid-cols-6">
              <div class="col-span-1 flex items-center justify-center">
                <component :is="getIcon(a)" />
              </div>
              <div class="col-span-3">
                <div class="font-medium">{{ getDescription(a) }}</div>
                <div class="text-gray-600 text-xs mt-0.5">
                  {{ dayjs(a.created_at).format("DD/MM/YYYY hh:mm:ss") }}
                </div>
              </div>
              <div class="col-span-2 flex items-center justify-center">
                {{ getVehiclePlate(a) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";
import dayjs from "dayjs";
import { useRouter } from "vue-router";

export default {
  props: {
    notify: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:notify"],
  setup(props) {
    const isactive = ref(false);
    const store = useStore();
    const router = useRouter();

    const alerts = computed(() => store.getters["alerts/active"]);
    const alert_descriptions = store.getters["alerts/collection"];

    const alerts_active = ref(props.notify);

    let interval = null;
    onMounted(() => {
      store.dispatch("alerts/getActive");
      console.log("alerts/getActive");
      interval = setInterval(() => store.dispatch("alerts/getActive"), 60000);
    });
    onUnmounted(() => {
      clearInterval(interval);
    });

    function goToAlerts() {
      hideDropdown();
      router.push({ path: "/active-alerts" });
    }
    function goToVehicle(active_alert) {
      hideDropdown();
      store.dispatch("globalMap/clear");
      store.dispatch("globalMap/setExternalVehicle", {
        id: active_alert.pivot.vehicle_id,
      });
      router.push({
        path: "/global-map",
        query: { v: active_alert.pivot.vehicle_id },
      });
    }
    function getDescription(active_alert) {
      let alert = alert_descriptions.find(
        (o) => o.id === active_alert.alert_id
      );
      if (!alert) return "";
      return alert.description;
    }
    function getVehiclePlate(active_alert) {
      const pins = store.getters["globalMap/pins"];
      let vehicle = pins.find(
        (o) => o.vehicle_id === active_alert.pivot.vehicle_id
      );
      if (vehicle) return vehicle.plate;
      else return "N/A";
    }
    function getIcon(active_alert) {
      let alert = alert_descriptions.find(
        (o) => o.id === active_alert.alert_id
      );
      if (!alert) return "AlertTriangleIcon";
      switch (alert.severity) {
        case 1:
          return "AlertTriangleIcon";
        case 2:
          return "AlertCircleIcon";

        default:
          return "AlertTriangleIcon";
      }
    }

    function hideDropdown() {
      cash("#alerts-dropdown").dropdown("hide");
    }

    return {
      i18n: useI18n(),
      dayjs,
      alerts,
      alerts_active,
      getIcon,
      getDescription,
      getVehiclePlate,
      goToVehicle,
      goToAlerts,
    };
  },
};
</script>
