<template>
  <Datepicker
    v-model="state"
    range
    :partial-range="false"
    multi-calendars="true"
    format="dd/MM/yyyy HH:mm"
    auto-apply
    :close-on-auto-apply="false"
    show-week-numbers
    :max-date="new Date()"
    :min-date="new Date(new Date().setFullYear(new Date().getFullYear() - 1))"
    locale="it-IT"
    :clearable="false"
    class="w-full"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { ref, watch } from "vue";
export default {
  components: { Datepicker },
  props: {
    modelValue: {
      //omitted type for compatibility for now
      type: [String, Array, Date],
      default: null,
    },
    maxDays: {
      type: Number,
      default: 5,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = ref(props.modelValue);
    watch(
      () => props.modelValue,
      (v) => (state.value = v)
    );
    watch(state, (v) => {
      if (v.length >= 2) {
        let start_date = new Date(v[0]);
        if (isNaN(start_date.getTime())) start_date = new Date();
        let end_date = new Date(v[1]);
        if (isNaN(end_date.getTime())) end_date = new Date();
        let days_diff = date_diff_indays(start_date, end_date);
        if (days_diff <= props.maxDays) {
          emit("update:modelValue", v);
        } else {
          end_date.setDate(start_date.getDate() + props.maxDays);
          state.value = [start_date, end_date];
          emit("update:modelValue", state.value);
        }
      } else {
        console.log("date not an array");

        emit("update:modelValue", state.value);
      }
    });

    function date_diff_indays(dt1, dt2) {
      return Math.abs(
        Math.floor(
          (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
            Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        )
      );
    }
    return {
      state,
    };
  },
};
</script>

<style></style>
