<template>
  <div class="intro-x flex flex-wrap items-center mt-2 w-full">
    <h1 class="text-2xl font-bold mr-4 mb-4 md:mb-0 w-full md:w-auto">
      <slot />
    </h1>

    <div class="relative w-full sm:w-auto">
      <input
        v-model="query"
        type="text"
        class="form-control pr-8"
        :placeholder="placeholder"
      />
      <div
        class="absolute right-0 top-0 bottom-0 w-8 flex items-center justify-center"
      >
        <SearchIcon v-if="query == ''" class="h-4" />
        <XIcon v-else class="h-4 cursor-pointer" @click="clear" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["search"],
  setup(_, { emit }) {
    const query = ref("");

    function clear() {
      query.value = "";
    }

    watch(query, () => {
      emit("search", query.value);
    });

    return { query, clear };
  },
};
</script>
