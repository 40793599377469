<template>
  <div class="h-full dropdown" style="height: 35px">
    <div
      class="bg-gray-100 h-full dropdown-toggle flex cursor-pointer rounded-full xl:rounded-md px-4"
    >
      <button
        :class="{
          'pr-1': fleetsFilter.length > 0,
        }"
        aria-expanded="false"
      >
        <span class="hidden lg:inline">{{ i18n.t("fleets.filter") }}</span>
        <span class="lg:hidden">{{ i18n.t("fleets.filter_mobile") }}</span>
      </button>
      <span
        v-for="fleet in fleetsFilter"
        :key="fleet.id"
        :style="{
          'background-color': fleet.color,
        }"
        class="w-4"
        >&nbsp;</span
      >
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-menu__content box">
        <div class="px-4 py-2 border-b border-gray-200 font-medium">
          {{ i18n.t("fleets.toggle-filter") }}
        </div>
        <div v-for="fleet in fleets" :key="fleet.id" class="p-2 pb-0 last:pb-2">
          <button
            class="btn w-full border-2 focus:ring-0"
            :class="{
              'btn-primary': filterContainsFleet(fleet.id),
              'btn-secondary': !filterContainsFleet(fleet.id),
            }"
            :style="{
              'border-color': filterContainsFleet(fleet.id)
                ? fleet.color
                : '#112233',
            }"
            @click="toggleFleet(fleet)"
          >
            {{ fleet.code }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useFleetFilter } from "@/composables/filters";

export default {
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const { filter: fleetsFilter, addFleet, removeFleet } = useFleetFilter();

    const fleets = ref([]);

    async function fetchFleets() {
      const result = await store.dispatch("fleets/getCollection");
      if (result.success) fleets.value = result.validated;
    }
    fetchFleets();

    function filterContainsFleet(fleetId) {
      return fleetsFilter.value.map((f) => f.id).includes(fleetId);
    }

    function toggleFleet(fleet) {
      if (filterContainsFleet(fleet.id)) return removeFleet(fleet.id);
      addFleet(fleet);
    }

    return {
      i18n,
      fleets,
      fleetsFilter,
      filterContainsFleet,
      toggleFleet,
    };
  },
};
</script>

<style></style>
