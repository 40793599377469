import { alerts as api } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
    active: [],
    active_meta: null,
    channels: [],
    configCollection: [],
  };
};

// getters
const getters = {
  ...g,
  active: (state) => state.active,
  active_meta: (state) => state.active_meta,
  channels: (state) => state.channels,
  configCollection: (state) => state.configCollection,
};

// actions
const actions = {
  getCollection: a(api).getCollection,
  async getEntity(_, entityId) {
    const result = await api.getEntity(entityId);
    result.validated = null;
    if (result.success) {
      result.validated = result.data;
    }
    return result;
  },
  async getChannels({ commit, getters }) {
    const result = await api.getChannels();
    result.validated = [];
    if (result.success) {
      commit("SET_CHANNELS", result.data);
      result.validated = getters["channels"];
    }
    return result;
  },
  async getActive({ commit, getters }, meta) {
    const result = await api.getActive(meta);
    result.validated = [];
    if (result.success) {
      commit("SET_ACTIVE", result);
      result.validated = getters["active"];
    }
    return result;
  },
  async getVehicles(_, alertId) {
    const result = await api.getVehicles(alertId);
    result.validated = [];
    if (result.success) {
      result.validated = result.data;
    }
    return result;
  },
  async getConfigCollection({ commit, getters }) {
    const result = await api.getConfigCollection();
    result.validated = [];
    if (result.success) {
      commit("SET_CONFIG_COLLECTION", result.data);
      result.validated = getters["configCollection"];
    }
    return result;
  },
  async storeConfig({ commit }, entity) {
    const result = await api.postConfigEntity(entity);
    result.validated = null;
    if (result.success) {
      commit("PUSH_CONFIG_ENTITY", result.data);
      result.validated = result.data;
    }
    return result;
  },
  async storeConfigMassive({ commit }, entity) {
    const result = await api.postConfigMassive(entity);
    result.validated = null;
    if (result.success) {
      result.data.forEach((element) => {
        commit("PUSH_CONFIG_ENTITY", element);
      });
      result.validated = result.data;
    }
    return result;
  },
  async updateConfig({ commit }, entity) {
    const result = await api.putConfigEntity(entity.id, entity);
    result.validated = null;
    if (result.success) {
      commit("REMOVE_CONFIG_ENTITY", entity.id);
      commit("PUSH_CONFIG_ENTITY", result.data);
      result.validated = result.data;
    }
    return result;
  },
  async deleteConfig({ commit, getters }, entityId) {
    const result = await api.deleteConfigEntity(entityId);
    result.validated = getters["configCollection"];
    if (result.success) {
      commit("REMOVE_CONFIG_ENTITY", entityId);
      result.validated = getters["configCollection"];
    }
    return result;
  },
  async removeAll({ commit, getters }, vehicleId) {
    const result = await api.removeAll(vehicleId);
    result.validated = getters["configCollection"];
    if (result.success) {
      commit("REMOVE_ALL_CONFIG_ENTITY", vehicleId);
      result.validated = getters["configCollection"];
    }
    return result;
  },
  async removeAllMassive({ commit, getters }, vehicles) {
    const result = await api.removeAllMassive(vehicles);
    result.validated = getters["configCollection"];
    if (result.success) {
      vehicles.forEach((element) => {
        commit("REMOVE_ALL_CONFIG_ENTITY", element);
      });
      result.validated = getters["configCollection"];
    }
    return result;
  },
};

// mutations
const mutations = {
  ...m,
  SET_CHANNELS(state, channels) {
    state.channels = channels;
  },
  SET_ACTIVE(state, result) {
    state.active = result.data;
    state.active_meta = result.meta;
  },
  SET_CONFIG_COLLECTION(state, configCollection) {
    state.configCollection = configCollection;
  },
  PUSH_CONFIG_ENTITY(state, entity) {
    state.configCollection.push(entity);
  },
  REMOVE_CONFIG_ENTITY(state, entityId) {
    const i = state.configCollection.findIndex((e) => e.id == entityId);
    if (i != -1) state.configCollection.splice(i, 1);
  },
  REMOVE_ALL_CONFIG_ENTITY(state, entityId) {
    state.configCollection = state.configCollection.filter(
      (e) => e.vehicle_id != entityId
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
