<template>
  <div v-if="vehicle" id="service-eyefuel">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyefuel.last_status") }}
        </div>
        <DateAgo
          v-if="data.fuel"
          :datetime="data.fuel.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.fuel" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <!-- fuel, cap, alarm -->
          <div class="flex flex-wrap justify-around">
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.cap_status_active == 'true' &&
                data.fuel.cap_status != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyefuel.cap_status.name") }}
              </div>
              <div class="mb-2">
                <ArchiveIcon />
              </div>
              <div
                v-if="data.fuel.cap_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.cap_status.closed") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.cap_status.opened") }}
              </div>
            </div>
            <div
              v-if="data.fuel.alarm_status != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyefuel.alarm_status.name") }}
              </div>
              <div class="mb-2">
                <AlertTriangleIcon />
              </div>
              <div
                v-if="data.fuel.alarm_status == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.alarm_status.none") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.alarm_status.theft") }}
              </div>
            </div>
            <div
              v-if="
                vehicle.configurations &&
                vehicle.configurations.siren_active == 'true' &&
                data.siren?.armed != null
              "
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium">
                {{ i18n.t("services.eyefuel.armed.name") }}
              </div>
              <div class="mb-2">
                <BellOffIcon v-if="data.siren?.armed == 0" />
                <BellIcon v-else />
              </div>
              <div
                v-if="data.siren?.armed == 0"
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.armed.none") }}
              </div>
              <div
                v-else-if="data.fuel.cap_status == 1"
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.armed.active") }}
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ i18n.t("services.eyefuel.armed.armed") }}
              </div>
            </div>
            <div
              v-if="data.fuel.tank_usage != null"
              class="flex flex-col items-center mr-3 py-4"
            >
              <div class="py-1 px-2 text-xs font-medium text-center">
                {{ i18n.t("services.eyefuel.tank_usage.name") }}
              </div>
              <div class="mb-2">
                <TrendingDownIcon />
              </div>
              <div
                v-if="data.fuel.tank_usage <= 15"
                class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.fuel.tank_usage }} %
              </div>
              <div
                v-else-if="data.fuel.tank_usage <= 50"
                class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.fuel.tank_usage }} %
              </div>
              <div
                v-else-if="data.fuel.tank_usage <= 75"
                class="py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.fuel.tank_usage }} %
              </div>
              <div
                v-else
                class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
              >
                {{ data.fuel.tank_usage }} %
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyefuel.fuel_history") }}
        <Eyepicker v-model="daterange" />
      </div>

      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.fuel &&
            history.list.fuel.length > 0
          "
        >
          <FuelChart :active="active" :height="200" :fuel="history.list.fuel" />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyefuel.fuel_event_history") }}
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.fuel" :key="h.id">
            <div class="intro-y">
              <div
                class="box px-4 py-4 mb-3 flex flex-wrap justify-between cursor-pointer"
                @click="handleHistoryClick(h)"
              >
                <div class="flex w-full md:w-auto items-center">
                  <div
                    class="w-10 h-10 justify-center image-cover rounded-md overflow-hidden"
                  >
                    <img
                      alt="pin image"
                      :src="require('@/assets/images/marker-icon-small.png')"
                    />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{
                        dayjs(h.message_datetime).format("DD/MM/YYYY HH:mm:ss")
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="h.tank_usage != null"
                  class="flex flex-col items-center py-4"
                >
                  <div class="py-1 px-2 text-xs font-medium text-center">
                    {{ i18n.t("services.eyefuel.tank_usage.name") }}
                  </div>
                  <div class="mb-2">
                    <TrendingDownIcon />
                  </div>
                  <div
                    v-if="h.tank_usage <= 15"
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ h.tank_usage }} %
                  </div>
                  <div
                    v-else-if="h.tank_usage <= 50"
                    class="py-1 px-2 bg-theme-23 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ h.tank_usage }} %
                  </div>
                  <div
                    v-else-if="h.tank_usage <= 75"
                    class="py-1 px-2 bg-theme-22 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ h.tank_usage }} %
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ h.tank_usage }} %
                  </div>
                </div>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.cap_status_active == 'true' &&
                    h.cap_status != null
                  "
                  class="flex flex-col items-center py-4"
                >
                  <div class="py-1 px-2 text-xs font-medium">
                    {{ i18n.t("services.eyefuel.cap_status.name") }}
                  </div>
                  <div class="mb-2">
                    <ArchiveIcon />
                  </div>
                  <div
                    v-if="h.cap_status == 0"
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyefuel.cap_status.closed") }}
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyefuel.cap_status.opened") }}
                  </div>
                </div>
                <div
                  v-if="h.alarm_status != null"
                  class="flex flex-col items-center py-4"
                >
                  <div class="py-1 px-2 text-xs font-medium">
                    {{ i18n.t("services.eyefuel.alarm_status.name") }}
                  </div>
                  <div class="mb-2">
                    <AlertTriangleIcon />
                  </div>
                  <div
                    v-if="h.alarm_status == 0"
                    class="py-1 px-2 bg-theme-10 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyefuel.alarm_status.none") }}
                  </div>
                  <div
                    v-else
                    class="py-1 px-2 bg-theme-24 rounded-full text-xs text-white cursor-pointer font-medium"
                  >
                    {{ i18n.t("services.eyefuel.alarm_status.theft") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div
        class="btn btn-primary rounded-full w-full mt-5"
        @click="requestReport"
      >
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import FuelChart from "@/components/fuel-chart/Main.vue";
import { watch, ref, computed, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";

export default {
  components: {
    FuelChart,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      }
    );

    function updateFuel(fuel) {
      if (data.value == null) return;
      data.value.fuel = fuel;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.fuel.length
      )
        history.value.list.fuel.unshift(fuel);
    }

    function updateSiren(siren) {
      if (data.value == null) return;
      data.value.siren = siren;

      if (
        history.value != null &&
        history.value.list &&
        history.value.list.siren?.length
      )
        history.value.list.siren?.unshift(siren);
    }

    EventBus.on("vehicle:updates:fuel", updateFuel);
    EventBus.on("vehicle:updates:siren", updateSiren);

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyefuel/getVehicleData",
        vehicle.value.id
      );
      data.value = dataResult.validated;

      getHistory();
    }

    function handleHistoryClick(history) {
      if (mapController.countMarkers("service") == 0) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("services.open_eyegeo_before"),
        });
      } else {
        mapController.flyToDate(history.message_datetime, "service");
      }
    }

    // requestReport service
    async function requestReport() {
      emit("report-request", "eyefuel", "eyefuel");
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyefuel/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      handleHistoryClick,
      daterange,
    };
  },
};
</script>

<style></style>
