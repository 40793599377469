<template>
  <div
    class="box p-4 mb-3 cursor-pointer"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive" class="flex items-center justify-between">
      <div class="flex items-center">
        <div
          class="w-10 h-10 rounded-md"
          :style="{ 'background-color': poi.color }"
        ></div>
        <div class="ml-4 flex-1 mr-auto">
          <div class="font-medium">{{ getPoiName(poi) }}</div>
          <div class="text-gray-600 text-xs mt-0.5">
            {{ getPoiAddress(poi) }}
          </div>
        </div>
      </div>
      <div class="flex">
        <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
          <EditIcon class="w-5 h-5" />
        </button>
        <button class="btn btn-danger p-1" @click.stop="deleteEntity(poi)">
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>
    </div>

    <!-- form -->
    <div v-else>
      <!-- name -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("general.name")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.name.$error"
          :errors="v$.name.$errors"
        >
          <input v-model="poi.name" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- code -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("general.code") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.code.$error || code_error"
          :errors="[...v$.code.$errors, ...code_error_array]"
        >
          <input v-model="poi.code" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- color -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("general.color")
        }}</label>
        <input
          v-model="poi.color"
          type="color"
          class="form-control mr-4 h-9 w-5/12 p-0"
        />
        <select v-model="poi.color" class="form-select w-5/12">
          <option
            v-for="c in colors"
            :key="c.key"
            :value="c.value"
            class="capitalize"
          >
            {{ i18n.t(`colors.${c.key}`) }}
          </option>
        </select>
      </div>

      <!-- radius -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4">{{ i18n.t("pois.radius") }}</label>
        <div class="input-group w-5/12 mr-4">
          <input
            v-model="poi.radius"
            type="number"
            class="form-control"
            min="1"
            max="1000"
            aria-label="Radius"
            aria-describedby="radius-unit"
          />
          <div id="radius-unit" class="input-group-text">m</div>
        </div>
        <input
          v-model="poi.radius"
          type="range"
          min="1"
          max="1000"
          class="form-control w-5/12"
        />
      </div>

      <!-- position -->
      <div class="mb-6">
        <label class="block w-2/12 mr-4 mb-1 capitalize">{{
          i18n.t("general.position")
        }}</label>
        <small class="block text-xs text-gray-600 mb-2">{{
          getPoiAddress(poi)
        }}</small>
        <div class="grid grid-cols-2 gap-4">
          <validated-input
            :has-error="v$.latitude.$error"
            :errors="v$.latitude.$errors"
          >
            <input
              v-model="poi.latitude"
              type="number"
              class="form-control"
              :placeholder="h.capitalizeFirstLetter(i18n.t('general.latitude'))"
            />
          </validated-input>
          <validated-input
            :has-error="v$.longitude.$error"
            :errors="v$.longitude.$errors"
          >
            <input
              v-model="poi.longitude"
              type="number"
              class="form-control"
              :placeholder="
                h.capitalizeFirstLetter(i18n.t('general.longitude'))
              "
            />
          </validated-input>
        </div>
        <div class="text-center my-2">{{ i18n.t("general.or") }}</div>
        <geocoding-input
          :placeholder="i18n.t('map.search-by-text')"
          @point="handlePointSearch"
        ></geocoding-input>
        <div class="text-center my-2">{{ i18n.t("general.or") }}</div>
        <button class="btn btn-outline-primary w-full" @click="mapSelect()">
          <span> {{ i18n.t("map.search-map") }} </span>
        </button>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click.stop="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helper } from "@/utils/helper";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import uniqid from "uniqid";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    poi: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated", "selected", "map:select", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();

    const code_error = ref(false);
    const code_error_array = ref([]);
    // select
    function select() {
      if (!formActive.value) emit("selected", props.poi);
    }

    // form
    const formActive = ref(false);

    if (!props.poi.id) showForm();

    function showForm() {
      formActive.value = true;
    }

    function cancelForm() {
      formActive.value = false;
      emit("canceled", props.poi);
    }

    // validation
    const rules = {
      name: { required },
      code: { required },
      color: { required },
      radius: { required },
      latitude: { required },
      longitude: { required },
      address: {},
      city: {},
      zip_code: {},
    };

    const v$ = useVuelidate(rules, props.poi);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      let result = null;

      if ("id" in props.poi) {
        result = await store.dispatch("pois/update", props.poi);
      } else {
        result = await store.dispatch("pois/store", props.poi);
      }

      if (!result.success) {
        if (result.data["code"] != undefined) {
          code_error.value = true;
          code_error_array.value = result.data["code"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        formActive.value = false;
        emit("updated", result.validated);
      }

      loading.value = false;
    }

    // delete
    async function deleteEntity(poi) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("pois.confirm-delete-title")} ${poi.code}?`,
        text: i18n.t("pois.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("pois/delete", poi.id);
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    const colors = store.getters["main/colors"];

    function getPoiName(poi) {
      if (poi.code != null) return `${poi.name} (${poi.code})`;
      return poi.name;
    }

    function getPoiAddress(poi) {
      const arr = [];
      if (poi.address != null) arr.push(poi.address);
      if (poi.city != null) arr.push(poi.city);
      if (poi.zip_code != null) arr.push(poi.zip_code);
      return arr.join(", ");
    }

    // map
    const selectUniqid = ref(null);
    function mapSelect() {
      selectUniqid.value = uniqid();
      EventBus.emit("map:select", selectUniqid.value);
    }

    function handlePointSelected({ uniqid, point }) {
      if (uniqid != selectUniqid.value) return;
      props.poi.latitude = point.latitude;
      props.poi.longitude = point.longitude;
      props.poi.address = point.address;
      props.poi.city = point.city;
      props.poi.zip_code = point.zip_code;
    }

    EventBus.on("map:point:selected", handlePointSelected);

    function handlePointSearch(point) {
      props.poi.latitude = point.latitude;
      props.poi.longitude = point.longitude;
      props.poi.address = point.address;
      props.poi.city = point.city;
      props.poi.zip_code = point.zip_code;
    }

    return {
      i18n,

      code_error,
      code_error_array,
      // select
      select,

      // form
      v$,
      formActive,
      showForm,
      cancelForm,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // utils
      h: helper,
      colors,
      getPoiName,
      getPoiAddress,

      // map
      mapSelect,
      handlePointSearch,
    };
  },
};
</script>

<style></style>
