import XLSX from "xlsx";

export const arrayToXlsx = async function (arr) {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(arr);
  XLSX.utils.book_append_sheet(wb, ws, "EXAMPLE");
  XLSX.writeFile(wb, "download.xlsx");
};

export const xlsxToArray = async function (file) {
  var reader = new FileReader();
  const splittedName = file.name.split(".");
  const ext = splittedName[splittedName.length - 1];
  if (ext.toLowerCase() != "xlsx") return [];
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });

      const tmp = {};
      const retVal = [];

      try {
        for (const sheetKey in workbook.Sheets) {
          for (const key in workbook.Sheets[sheetKey]) {
            const value = workbook.Sheets[sheetKey][key].v;
            const number = String(key).substring(1);
            if (number in tmp) {
              tmp[number].push(value);
            } else {
              tmp[number] = [value];
            }
          }
          break;
        }

        const headings = tmp["1"];
        for (const key in tmp) {
          if (key == "margins" || key == "ref" || key == "1") continue;
          const obj = {};
          for (let i = 0; i < headings.length; i++) {
            const k = headings[i];
            obj[k] = tmp[key][i];
          }
          retVal.push(obj);
        }

        resolve(retVal);
      } catch (error) {
        resolve(null);
      }

      reject();
    };
    reader.readAsArrayBuffer(file);
  });
};
