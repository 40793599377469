import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
const namespace = "vehicles";
const api = {
  ...getResource(namespace),
  getStatistics: async function () {
    try {
      const response = await axios.get(`${namespace}/statistics`);
      return handleSuccess({ namespace, action: "getStatistics" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getStatistics" },
        error.response
      );
    }
  },
  getTypes: async function () {
    try {
      const response = await axios.get(`${namespace}/types`);
      return handleSuccess({ namespace, action: "getTypes" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getTypes" }, error.response);
    }
  },
  getTypesStatistics: async function () {
    try {
      const response = await axios.get(`${namespace}/types/statistics`);
      return handleSuccess(
        { namespace, action: "getTypesStatistics" },
        response
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getTypesStatistics" },
        error.response
      );
    }
  },
  getTypeImage: async function (vehicleType) {
    try {
      const response = await axios.get(
        `${namespace}/type/${vehicleType}/image`
      );
      return handleSuccess({ namespace, action: "getTypeImage" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getTypeImage" }, error.response);
    }
  },
  getAlertsConfigs: async function (vehicleId) {
    try {
      const response = await axios.get(
        `${namespace}/${vehicleId}/alerts_configurations`
      );
      return handleSuccess({ namespace, action: "getAlertsConfigs" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getAlertsConfigs" },
        error.response
      );
    }
  },
  setFleet: async function (vehicleId, fleetId) {
    try {
      const response = await axios.put(`${namespace}/${vehicleId}/fleet`, {
        fleet_id: fleetId,
      });
      return handleSuccess({ namespace, action: "setFleet" }, response);
    } catch (error) {
      return handleError({ namespace, action: "setFleet" }, error.response);
    }
  },
  setLogInterval: async function (id, value) {
    try {
      const response = await axios.put(`${namespace}/${id}/log_interval`, {
        value,
      });
      return handleSuccess({ namespace, action: "setLogInterval" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "setLogInterval" },
        error.response
      );
    }
  },
  updates: async function (vehicleId, from) {
    try {
      const response = await axios.get(`${namespace}/${vehicleId}/updates`, {
        params: { from: from },
      });
      const result = handleSuccess({ namespace, action: "updates" }, response);
      result.validated = null;
      if (result.success) {
        result.validated = result.data;
      }
      return result;
    } catch (error) {
      return handleError({ namespace, action: "updates" }, error.response);
    }
  },
  requestReport: async function (vehicleId, services, from, to, type) {
    try {
      const response = await axios.put(`${namespace}/${vehicleId}/report`, {
        services: services,
        period_from: from,
        period_to: to,
        type: type,
      });
      return handleSuccess({ namespace, action: "requestReport" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "requestReport" },
        error.response
      );
    }
  },
};
export { api as default };
