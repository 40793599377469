<template>
  <teleport to="body">
    <div
      v-show="showRef"
      class="fixed top-0 left-0 h-full w-full p-8 bg-gray-900 bg-opacity-70 flex items-center justify-center transition-all"
      @click.self="hide"
    >
      <div class="bg-white rounded-xl shadow-lg p-8">
        <h1 class="capitalize text-lg font-bold mb-4">{{ titleRef }}</h1>
        <p class="mb-4">{{ textRef }}</p>
        <div v-if="doubleCheckRef" class="flex mb-4">
          <div class="form-check">
            <input
              id="confirm-dialog-double-check-toggle"
              v-model="doubleCheckToggle"
              class="form-check-switch"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="confirm-dialog-double-check-toggle"
              >{{ doubleCheckTextRef }}</label
            >
          </div>
        </div>
        <div class="flex justify-end">
          <button
            class="btn btn-secondary mr-4"
            :disabled="loading"
            @click="hide"
          >
            {{ cancelTextRef }}
          </button>
          <button
            class="btn"
            :class="[`btn-${confirmColorRef}`]"
            :disabled="loading || (doubleCheckRef && !doubleCheckToggle)"
            @click="confirm"
          >
            {{ confirmTextRef }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from "vue";
import EventBus from "../../libs/event-bus";
import { useI18n } from "vue3-i18n";
export default {
  emits: ["confirm"],
  setup() {
    const i18n = useI18n();

    const loading = ref(false);

    const showRef = ref(false);
    const doubleCheckRef = ref(false);
    const doubleCheckToggle = ref(false);
    const doubleCheckTextRef = ref("");
    const titleRef = ref(i18n.t("general.confirm"));
    const textRef = ref("");
    const confirmTextRef = ref(i18n.t("general.yes"));
    const confirmColorRef = ref("primary");
    const cancelTextRef = ref(i18n.t("general.no"));
    const callbackRef = ref(null);

    EventBus.on("confirm-dialog:show", show);

    function show({
      doubleCheck = false,
      doubleCheckText = "",
      title = "",
      text = "",
      confirmText = i18n.t("general.yes"),
      confirmColor = "primary",
      cancelText = i18n.t("general.no"),
      callback = () => {},
    }) {
      loading.value = false;
      showRef.value = true;
      doubleCheckRef.value = doubleCheck;
      doubleCheckTextRef.value = doubleCheckText;
      titleRef.value = title;
      textRef.value = text;
      confirmTextRef.value = confirmText;
      confirmColorRef.value = confirmColor;
      cancelTextRef.value = cancelText;
      callbackRef.value = callback;
    }

    function hide(emit = true) {
      loading.value = false;
      showRef.value = false;
      doubleCheckRef.value = false;
      doubleCheckTextRef.value = "";
      titleRef.value = "";
      textRef.value = "";
      confirmTextRef.value = "";
      confirmColorRef.value = "primary";
      cancelTextRef.value = "";
      if (emit) callbackRef.value(false);
    }

    async function confirm() {
      loading.value = true;
      if (callbackRef.value) await callbackRef.value(true);
      hide(false);
    }

    return {
      loading,
      showRef,
      doubleCheckToggle,
      doubleCheckRef,
      doubleCheckTextRef,
      titleRef,
      textRef,
      confirmTextRef,
      confirmColorRef,
      cancelTextRef,
      hide,
      confirm,
    };
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  z-index: 10000;
}
</style>
