<template>
  <div class="validated-input" :class="{ error: hasError }">
    <slot />
    <div v-if="hasError" class="text-sm text-reyesol-red mt-2">
      {{ activeError }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue3-i18n";
export default {
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Array, null],
      default: null,
    },
  },

  setup(props) {
    const i18n = useI18n();
    const activeError = computed(() => {
      if (props.errors != null && props.errors.length > 0) {
        if (props.errors[0].$validator != undefined)
          return i18n.t(`validation.${props.errors[0].$validator}`);
        return props.errors[0];
      }
      return "";
    });

    return { i18n, activeError };
  },
};
</script>

<style lang="scss">
.validated-input {
  &.error {
    input,
    textarea,
    select {
      border-color: #d32929;
    }
  }

  input,
  textarea,
  select {
    width: 100%;
  }
}
</style>
