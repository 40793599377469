import { computed, ref } from "vue";
import _ from "lodash";

export function useCollectionSearch(keys, clone = false) {
  const searchQuery = ref("");
  const collectionToFilter = ref([]);

  function updateSearchQuery(value) {
    searchQuery.value = value;
  }

  const filteredCollection = computed(() => {
    if (!searchQuery.value || searchQuery.value == "")
      return collectionToFilter.value;
    const splittedQuery = searchQuery.value.split(" ");
    const filtered = collectionToFilter.value.filter((entity) => {
      let found = false;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let splittedKey = [];
        let resolver = null;
        if (typeof key == "object") {
          splittedKey = key.key.split(".");
          resolver = key.resolve;
        } else {
          splittedKey = key.split(".");
        }
        if (recursivelySearch(entity, splittedKey, splittedQuery, resolver))
          found = true;
      }
      return found;
    });
    return clone ? _.cloneDeep(filtered) : filtered;
  });

  function recursivelySearch(
    object,
    splittedKey,
    splittedQuery,
    resolver = null
  ) {
    if (object == null || splittedKey.length == 0) return false;

    let o = null;
    try {
      const nextKey = splittedKey[0];

      if (nextKey == "[]") {
        splittedKey = splittedKey.splice(1);
        let found = false;
        for (let i = 0; i < keys.length; i++) {
          const element = object[i];
          if (recursivelySearch(element, splittedKey, splittedQuery, resolver))
            found = true;
        }
        return found;
      } else {
        o = object[nextKey];
        if (typeof o == "string" || typeof o == "number") {
          for (let i = 0; i < splittedQuery.length; i++) {
            const queryWord = splittedQuery[i];
            let stringData = typeof o == "string" ? o : String(0);
            if (resolver) stringData = resolver(stringData);
            if (stringData.toLowerCase().includes(queryWord.toLowerCase()))
              return true;
          }
        }
      }

      splittedKey = splittedKey.splice(1);
    } catch (error) {
      return false;
    }

    return recursivelySearch(o, splittedKey, splittedQuery, resolver);
  }

  return {
    searchQuery,
    updateSearchQuery,
    collectionToFilter,
    filteredCollection,
  };
}
