<template>
  <div class="flex flex-row h-full mx-auto">
    <div v-if="vehicle" class="h-12 flex self-start md:self-center -ml-7 mr-1">
      <button @click="$emit('close')">
        <ChevronRightIcon
          class="w-7 h-7 h-full bg-gray-300 mr-2 rounded shadow-md hover:bg-gray-400 shadow-xs"
        />
      </button>
    </div>

    <div v-if="vehicle" class="h-full flex flex-col flex-none w-full">
      <!-- heading -->
      <div
        class="flex flex-row w-full rounded-sm shadow-md p-1 bg-white border-b-2 border-gray-300"
      >
        <div
          class="flex flex-col p-2 px-3 justify-center border-r-2 border-gray-500"
        >
          <img
            :src="
              require(`@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`)
            "
            class="h-5 opacity-95 transform-gpu translate-y-1 mb-2"
          />
          <template v-if="vehicle.status == 0">
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-disconnected text-reyesol-disconnected-contrast"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.disconnected')"
                :alt="i18n.t('services.general.status.disconnected')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
          <template v-else-if="vehicle.status == 1">
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-connected text-reyesol-connected-contrast px-1"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.connected')"
                :alt="i18n.t('services.general.status.connected')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
          <template v-else-if="vehicle.status == 2">
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-alarm text-reyesol-alarm-contrast"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.alarm')"
                :alt="i18n.t('services.general.status.alarm')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
          <template v-else-if="vehicle.status == 3">
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-powersafe text-reyesol-powersafe-contrast"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.powersafe')"
                :alt="i18n.t('services.general.status.powersafe')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
          <template v-else-if="vehicle.status == 4">
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-moving text-reyesol-moving-contrast"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.moving')"
                :alt="i18n.t('services.general.status.moving')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
          <template v-else>
            <div
              class="rounded-md mt-3 h-3 bg-reyesol-alarm text-reyesol-alarm-contrast"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="i18n.t('services.general.status.alarm')"
                :alt="i18n.t('services.general.status.alarm')"
              >
                &nbsp;
              </Tippy>
            </div>
          </template>
        </div>
        <div class="flex flex-col mr-auto gap-1 ml-2 my-auto">
          <span class="text-sm p-1 bg-white"
            ><CreditCardIcon /> &nbsp; <b>{{ vehicle.plate }}</b></span
          >
          <span v-if="vehicle.fleet_id" class="text-sm p-1 bg-white"
            ><BookmarkIcon /> &nbsp; <b>{{ vehicleFleet(vehicle.fleet_id) }}</b>
          </span>
        </div>
      </div>

      <!-- services tabs -->
      <div class="flex flex-wrap w-full flex-row border-b">
        <ul class="flex flex-wrap w-full gap-1">
          <template v-for="(s, index) in actualservices">
            <li
              v-if="s.active & (index <= 6)"
              :key="s.key"
              class="flex-auto"
              :class="{
                'text-black bg-white border-b-4 border-gray-400 hover:bg-gray-200':
                  openedService !== s.key,
                'text-white bg-gray-100 border-b-6 border-green-400':
                  openedService === s.key,
              }"
              @click="s.active ? toggleTabs(s.key) : null"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="s.name"
                :alt="s.name"
              >
                <div class="w-full">
                  <img
                    :src="require(`@/assets/images/services/${s.name}.svg`)"
                    class="max-h-6 max-w-sm mx-auto my-2"
                    :alt="`${s.name}`"
                  />
                </div>
              </Tippy>
            </li>
          </template>
        </ul>
      </div>

      <div class="flex flex-wrap w-full flex-row border-b">
        <ul class="flex flex-wrap w-full">
          <template v-for="(s, index) in actualservices">
            <li
              v-if="s.active & (index > 6)"
              class="flex-auto"
              :class="{
                'text-black bg-white border-b-4 border-gray-400 hover:bg-gray-200':
                  openedService !== s.key,
                'text-white bg-gray-100 border-b-6 border-green-400':
                  openedService === s.key,
              }"
              @click="s.active ? toggleTabs(s.key) : null"
            >
              <Tippy
                tag="div"
                class="tooltip w-full"
                :content="s.name"
                :alt="s.name"
              >
                <div class="w-full">
                  <img
                    :src="require(`@/assets/images/services/${s.name}.svg`)"
                    class="max-h-6 max-w-sm mx-auto my-2"
                    :alt="`${s.name}`"
                  />
                </div>
              </Tippy>
            </li>
          </template>
        </ul>
      </div>

      <!--services card & Report Modal-->
      <div
        class="relative flex w-full flex-col bg-white mb-6 shadow-md overflow-auto rounded-sm"
      >
        <div class="p-4">
          <div class="tab-content tab-space">
            <!-- Services Containers-->
            <template v-for="s in services">
              <div
                class="w-full"
                :class="{
                  hidden: openedService !== s.key,
                  block: openedService == s.key,
                }"
              >
                <div>
                  <!--Services-->
                  <VehicleServiceGeneral
                    v-if="s.key == 'general'"
                    :active="openedService == 'general'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyegeo
                    v-if="s.key == 'eyegeo'"
                    :active="openedService == 'eyegeo'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyecold
                    v-if="s.key == 'eyecold'"
                    :active="openedService == 'eyecold'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyedoor
                    v-if="s.key == 'eyedoor'"
                    :active="openedService == 'eyedoor'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyefuel
                    v-if="s.key == 'eyefuel'"
                    :active="openedService == 'eyefuel'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyeway
                    v-if="s.key == 'eyeway'"
                    :active="openedService == 'eyeway'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyeservice
                    v-if="s.key == 'eyeservice'"
                    :active="openedService == 'eyeservice'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceCarbontax
                    v-if="s.key == 'carbontax'"
                    :active="openedService == 'carbontax'"
                  />
                  <VehicleServiceEyecontrol
                    v-if="s.key == 'eyecontrol'"
                    :active="openedService == 'eyecontrol'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceEyeshare
                    v-if="s.key == 'eyeshare'"
                    :active="openedService == 'eyeshare'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceTandT
                    v-if="s.key == 'tandt'"
                    :active="openedService == 'tandt'"
                    @report-request="newReportRequest"
                  />
                  <VehicleServiceDirections
                    v-if="s.key == 'eyegeo.directions'"
                    :active="openedService == 'eyegeo.directions'"
                  />
                </div>
              </div>
            </template>

            <!--Old Report slider-->
            <ReportSlider
              :active="reportSelectorOpened"
              :save="i18n.t('global-map.report.save')"
              :cancel="i18n.t('global-map.report.cancel')"
              :selected="report_options"
              :enabled="report_enabled"
              :type="report_type"
              @close="report_close"
              @save="onReportSave"
            />

            <!-- Report Modal-->
            <Dropover ref="dropoverid" :show="dropoverOpen" :fullscreen="true">
              <template #header>
                <div class="flex flex-col">
                  <div class="flex text-lg mb-4 self-center">
                    {{ i18n.t("general.request-report") }}
                  </div>
                  <div class="flex flex-col bg-white">
                    <span class="text-md">{{
                      i18n.t("general.report-slide-period")
                    }}</span>
                    <div class="flex relative">
                      <Eyepicker v-model="daterange" :max-days="31" />
                    </div>
                  </div>
                </div>
              </template>
              <template #body>
                <div class="flex flex-col">
                  <div class="flex text-md mb-2 -mt-2">
                    {{ i18n.t("navigation.services") }}:
                  </div>
                </div>
                <table class="flex w-full justify-center">
                  <div class="flex flex-col w-full">
                    <template v-for="(item, index) in categories">
                      <tr
                        v-if="
                          services.some(
                            (e) => e.key == item.key && e.active == true
                          )
                        "
                        :key="item.key"
                      >
                        <td v-if="item.show == true" class="flex w-full">
                          <div
                            class="flex bg-gray-200 border border-gray-300 p-2 w-full rounded-md mb-2"
                          >
                            <div class="flex custom-control custom-switch">
                              <input
                                :id="'toggle-' + item.key"
                                v-model="categories[index].selected"
                                type="checkbox"
                                class="form-check-switch"
                              />
                              <label
                                class="custom-control-label ml-2 self-center"
                                :for="'toggle-' + item.key"
                              >
                                {{ item.key }}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </div>
                </table>
              </template>
              <template #footer>
                <div class="flex flex-row gap-4 justify-center">
                  <button
                    class="modal-default-button btn btn-secondary"
                    @click="closeDropover"
                  >
                    {{ i18n.t("general.cancel") }}
                  </button>
                  <button
                    class="modal-default-button btn btn-primary"
                    :disabled="!categories.some((e) => e.selected == true)"
                    @click="generateReport"
                  >
                    {{ i18n.t("reports.generate-report") }}
                  </button>
                </div>
              </template>
            </Dropover>

            <!-- Temps Modal -->
            <!-- Not used yet
            <dropover
            :active="false"
            @keydown.esc="dropoverOpen = false">
            <template #header>
              <div class="flex flex-col">
                  <div class="flex text-lg mb-4 self-center">
                    Genera Report
                  </div>
                  <div class="flex flex-col bg-white">
                    <span class="text-md">Seleziona intervallo:</span>
                    <div class="flex relative">
                      <Eyepicker v-model="daterange" :max-days="31" />
                    </div>
                  </div>
                </div>
            </template>
            <template #body>
              <div class="flex flex-col">
                <div class="flex text-md mb-2 -mt-2">
                Seleziona Parametri:
                </div>
              </div>
              <table class="flex w-full justify-center">
                <div class="flex flex-col w-full">
                  <template v-for="(item, index) in tempsarray" :key="item.name">
                    <tr>
                      <td class="flex w-full">
                        <div class="flex bg-gray-200 border border-gray-300 p-2 w-full rounded-md mb-2">
                          <div class="flex custom-control custom-switch">
                            <input
                              :id="'toggle-'+item.name"
                              type="checkbox"
                              class="form-check-switch"
                              v-model="tempsarray[index].state"
                            >
                            <label class="custom-control-label ml-2 self-center" :for="'toggle-'+item.name">
                              {{ getSensorName(item.name) }}
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </div>
              </table>
            </template>
            <template #footer>
              <div class="flex flex-row gap-4 justify-center">
                  <button
                    class="modal-default-button btn btn-secondary"
                    @click=""
                  >Cancella</button>
                  <button
                    class="modal-default-button btn btn-primary"
                    @click="generateReport"
                  >Genera Report</button>
                </div>
            </template>
            </dropover>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, ref, computed, watchEffect, reactive, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import { useDaterange } from "@/composables/daterange";
import VehicleServiceGeneral from "./VehicleServiceGeneral";
import VehicleServiceEyegeo from "./VehicleServiceEyegeo";
import VehicleServiceEyecold from "./VehicleServiceEyecold";
import VehicleServiceEyedoor from "./VehicleServiceEyedoor";
import VehicleServiceEyefuel from "./VehicleServiceEyefuel";
import VehicleServiceEyeway from "./VehicleServiceEyeway";
import VehicleServiceEyeservice from "./VehicleServiceEyeservice";
import VehicleServiceEyeshare from "./VehicleServiceEyeshare";
import VehicleServiceCarbontax from "./VehicleServiceCarbontax";
import VehicleServiceEyecontrol from "./VehicleServiceEyecontrol";
import VehicleServiceTandT from "./VehicleServiceTandT";
import VehicleServiceDirections from "./VehicleServiceDirections";

import ReportSlider from "@/components/report-slide/Main.vue";
import Dropover from "@/components/dropover/Main.vue";
import {
  BookmarkIcon,
  MapIcon,
  TruckIcon,
  UsersIcon,
} from "@zhuowenli/vue-feather-icons";

export default {
  name: "SlateTabs",
  components: {
    VehicleServiceGeneral,
    VehicleServiceEyegeo,
    VehicleServiceEyecontrol,
    VehicleServiceEyecold,
    VehicleServiceEyedoor,
    VehicleServiceEyefuel,
    VehicleServiceEyeway,
    VehicleServiceEyeservice,
    VehicleServiceEyeshare,
    VehicleServiceCarbontax,
    VehicleServiceTandT,
    VehicleServiceDirections,
    Dropover,
    ReportSlider,
    MapIcon,
    TruckIcon,
    UsersIcon,
    BookmarkIcon,
  },
  props: {
    service: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const user = store.getters["auth/user"];

    // vehicle and services init

    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);

    const services = ref([]);
    const actualservices = [];
    const dropoverid = ref(null);
    const agreements = store.getters["agreements/collection"];
    let openedService = ref(null);

    //old reporting
    const report_options = ref([]);
    const report_enabled = ref([]);
    const report_type = ref("custom");
    const reportSelectorOpened = ref(false);

    //new reporting
    const tempsarray = reactive([]);
    const dropoverOpen = ref(false);
    const categories = ref([]);
    let servicetype = "";
    categories.value = [
      { key: "eyegeo", selected: false, show: false },
      { key: "eyecold", selected: false, show: false },
      { key: "eyedoor", selected: false, show: false },
      { key: "eyecontrol", selected: false, show: false },
      { key: "eyefuel", selected: false, show: false },
      { key: "tandt", selected: false, show: false },
      { key: "eyeway", selected: false, show: false },
    ];
    const daterange = ref({
      from: dayjs().startOf("day").toDate(),
      to: dayjs().toDate(),
    });

    function closePanel() {
      router.back();
    }

    // services

    function loadServices(vehicle) {
      vehicle.active_agreements.map((agreement) => {
        agreement.code = agreements.find(
          (a) => a.id == agreement.agreement_id
        ).code;
        return agreement;
      });

      const s = store.getters["main/services"];
      services.value = s.map((service) => {
        return {
          visible: !service.key.startsWith("type_"),
          active:
            !!vehicle.active_agreements.find(
              (a) => a.customer_id == user.customer_id && a.code == service.key
            ) || service.key == "general",
          ...service,
        };
      });
      //servicearray()
      report_enabled.value = [
        "eyegeo.latitude",
        "eyegeo.longitude",
        "eyecold.T1",
        "eyecold.T2",
        "eyecold.T3",
        "eyecold.T4",
        "eyecold_fridge.overall_status",
        "eyecold_fridge.engine_status",
        "eyecold_fridge.operating_mode",
        "eyecold_fridge.operating_mode_status",
        "eyecold_fridge.setpoint_t1",
        "eyecold_fridge.operation_status_c1",
        "eyecold_fridge.operation_mode_c1",
        "eyecold_fridge.setpoint_t2",
        "eyecold_fridge.operation_status_c2",
        "eyecold_fridge.operation_mode_c2",
        "eyecold_fridge.setpoint_t3",
        "eyecold_fridge.operation_status_c3",
        "eyecold_fridge.operation_mode_c3",
        "eyecold_fridge.alarm_status",
        "eyecold_fridge.hour_meter_electric",
        "eyecold_fridge.hour_meter_fuel",
        "eyecold_fridge.current_sensor",
        "eyecold_fridge.odl_num",
        "eyecold_fridge.odl_t1",
        "eyedoor.door_1",
        "eyedoor.door_2",
        "eyedoor.siren",
        "eyefuel.tank_usage",
        "eyefuel.cap_status",
        "eyefuel.alarm_status",
        "eyeway.odometer",
        "eyeway.speed",
        "eyeway.breaks_status",
        "eyeway.tyres_pressure_status",
        "eyeway.load",
        "eyecontrol.user",
        "eyecontrol.command",
        "eyecontrol.command_data",
        "tandt.beacon_code",
        "tandt.locked",
        "tandt.intensity",
      ];

      if (vehicle.configurations) {
        switch (vehicle.configurations.fridge_compartments_number) {
          case undefined:
          case 0:
          case "0":
            report_enabled.value.splice(
              report_enabled.value.indexOf(
                "eyecold_fridge.hour_meter_electric"
              ),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.setpoint_t1"),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf(
                "eyecold_fridge.operation_status_c1"
              ),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.operation_mode_c1"),
              1
            );
          case 1:
          case "1":
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.setpoint_t2"),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf(
                "eyecold_fridge.operation_status_c2"
              ),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.operation_mode_c2"),
              1
            );
          case 2:
          case "2":
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.setpoint_t3"),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf(
                "eyecold_fridge.operation_status_c3"
              ),
              1
            );
            report_enabled.value.splice(
              report_enabled.value.indexOf("eyecold_fridge.operation_mode_c3"),
              1
            );
        }
        if (vehicle.configurations.fridge_temperature_sensors_number) {
          switch (vehicle.configurations.fridge_temperature_sensors_number) {
            case 1:
            case "1":
              report_enabled.value.splice(
                report_enabled.value.indexOf("eyecold.T2"),
                1
              );
            case 2:
            case "2":
              report_enabled.value.splice(
                report_enabled.value.indexOf("eyecold.T3"),
                1
              );
            case 3:
            case "3":
              report_enabled.value.splice(
                report_enabled.value.indexOf("eyecold.T4"),
                1
              );
          }
        }
        if (
          vehicle.configurations.door1_active == undefined ||
          vehicle.configurations.door1_active == "false"
        ) {
          report_enabled.value.splice(
            report_enabled.value.indexOf("eyedoor.door_1"),
            1
          );
        }
        if (
          vehicle.configurations.door2_active == undefined ||
          vehicle.configurations.door2_active == "false"
        ) {
          report_enabled.value.splice(
            report_enabled.value.indexOf("eyedoor.door_2"),
            1
          );
        }
        if (
          vehicle.configurations.siren_active == undefined ||
          vehicle.configurations.siren_active == "false"
        ) {
          report_enabled.value.splice(
            report_enabled.value.indexOf("eyedoor.siren"),
            1
          );
        }

        if (
          vehicle.configurations.cap_status_active == undefined ||
          vehicle.configurations.cap_status_active == "false"
        ) {
          report_enabled.value.splice(
            report_enabled.value.indexOf("eyefuel.cap_status"),
            1
          );
        }
        if (
          vehicle.configurations.tank_usage_active == undefined ||
          vehicle.configurations.tank_usage_active == "false"
        ) {
          report_enabled.value.splice(
            report_enabled.value.indexOf("eyefuel.tank_usage"),
            1
          );
        }
      }

      report_enabled.value = report_enabled.value.filter((service) => {
        let base_service = service.split(".")[0];
        return services.value.some(
          (item) =>
            item.active && item.visible && base_service.startsWith(item.key)
        );
      });
    }

    function toggleTabs(Tab) {
      openedService.value = Tab;
    }

    function updateVehicle(vehicle) {
      if (vehicle.value == null) return;

      if (vehicle) store.dispatch("globalMap/getVehicle", vehicle.id);
      return;
      /*
      if (vehicle.value.status !== v.status) {
        switch (v.status) {
          case 0:
            EventBus.emit("notification:show", {
              type: "warning",
              title: i18n.t("services.notify.disconnected"),
              text:
                i18n.t("alerts.notify.vehicle") +
                ": " +
                v.plate +
                " " +
                i18n.t("alerts.notify.datetime") +
                ": " +
                v.updated_at
            });
            break;
          case 1:
            EventBus.emit("notification:show", {
              type: "success",
              title: i18n.t("services.notify.connected"),
              text:
                i18n.t("alerts.notify.vehicle") +
                ": " +
                v.plate +
                " " +
                i18n.t("alerts.notify.datetime") +
                ": " +
                v.updated_at
            });
            break;
          case 2:
            EventBus.emit("notification:show", {
              type: "danger",
              title: i18n.t("services.notify.alarm"),
              text:
                i18n.t("alerts.notify.vehicle") +
                ": " +
                v.plate +
                " " +
                i18n.t("alerts.notify.datetime") +
                ": " +
                v.updated_at
            });
            break;
        }
      }
      store.dispatch("globalMap/getVehicle", v.id);*/
    }
    EventBus.on("vehicle:updates:vehicle", updateVehicle);

    function setOpenedService(serviceKey) {
      if (openedService.value == serviceKey)
        return (openedService.value = null);
      openedService.value = serviceKey;
    }

    //Old Reporting
    function onReportRequest(service, type, enabled) {
      console.log("onReportRequest", service, type, enabled);
      report_options.value.splice(0, report_options.value.length);
      if (service) report_options.value.push(...service);

      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      report_type.value = type == "temp" ? "temp" : "custom";
      if (reportSelectorOpened.value == true)
        reportSelectorOpened.value = false;
      nextTick(() => (reportSelectorOpened.value = true));
    }

    function onReportSave(services, from, to, type) {
      console.log("onReportSave", services, from, to, type);
      store.dispatch("globalMap/requestReport", {
        vehicleId: vehicle.value.id,
        services: services.join(),
        from: dayjs(from).format(),
        to: dayjs(to).format(),
        type: type,
      });

      EventBus.emit("notification:show", {
        type: "success",
        title: i18n.t("reports.notify.submitted"),
      });
    }

    function report_close(service) {
      console.log("report_close", service);
    }

    //New Reporting
    function closeDropover() {
      categories.value.forEach((e) => {
        e.show = false;
        e.selected = false;
      });
      dropoverOpen.value = false;
    }

    function get_configuration(service) {
      if (service == "eyecold") {
        let temps = [];
        if (
          vehicle.value.configurations &&
          vehicle.value.configurations.fridge_temperature_sensors_number
        ) {
          switch (
            vehicle.value.configurations.fridge_temperature_sensors_number
          ) {
            case 4:
            case "4":
              temps.push("eyecold.T4");
            // eslint-disable-next-line no-fallthrough
            case 3:
            case "3":
              temps.push("eyecold.T3");
              temps.push("eyecold_fridge.setpoint_t3");
            // eslint-disable-next-line no-fallthrough
            case 2:
            case "2":
              temps.push("eyecold.T2");
              temps.push("eyecold_fridge.setpoint_t2");
            // eslint-disable-next-line no-fallthrough
            case 1:
            case "1":
              temps.push("eyecold.T1");
              temps.push("eyecold_fridge.setpoint_t1");
          }
        } else {
          temps = ["eyecold.T1", "eyecold.T2", "eyecold.T3", "eyecold.T4"];
        }
        temps.push(
          "eyecold_fridge.alarm_status",
          "eyecold_fridge.overall_status"
        );
        return [...temps];
      }
      if (service == "eyefuel") {
        let report_features = ["eyefuel.alarm_status"];

        if (
          vehicle.value.configurations &&
          vehicle.value.configurations.tank_usage_active == "true"
        ) {
          report_features.push("eyefuel.tank_usage");
        }
        if (
          vehicle.value.configurations &&
          vehicle.value.configurations.cap_status_active == "true"
        ) {
          report_features.push("eyefuel.cap_status");
        }
        return report_features;
      }
      if (service == "eyedoor") {
        let doors = ["eyedoor.siren"];
        if (vehicle.value.configurations) {
          if (vehicle.value.configurations.door1_active) {
            doors.push("eyedoor.door_1");
          }
          if (vehicle.value.configurations.door2_active) {
            doors.push("eyedoor.door_2");
          }
          if (vehicle.value.configurations.door3_active) {
            doors.push("eyedoor.door_3");
          }
          if (vehicle.value.configurations.door4_active) {
            doors.push("eyedoor.door_4");
          }
        }
        /*
        let data = reactive(ref(null));
        const dataResult = await store.dispatch(
        "eyedoor/getVehicleData",
        vehicle.value.id
        );

        data.value = dataResult.validated;
        
        if (data.value.door != null) {
          if (data.value.door.door_1 != null) {
          door.push('eyedoor.door_1')
          }
          if (data.value.door.door_2 != null) {
            door.push('eyedoor.door_2')
          }
        }
        */
        return [...doors];
      }
      if (service == "eyeway") {
        return [
          "eyeway.odometer",
          "eyeway.speed",
          "eyeway.breaks_status",
          "eyeway.tyres_pressure_status",
          "eyeway.load",
        ];
      }
      if (service == "eyecontrol") {
        return [
          "eyecontrol.user",
          "eyecontrol.command",
          "eyecontrol.command_data",
        ];
      }
      if (service == "eyegeo") {
        return ["eyegeo.latitude", "eyegeo.longitude"];
      }
      if (service == "tandt") {
        return ["tandt.beacon_code", "tandt.locked", "tandt.intensity"];
      }
    }

    function newReportRequest(service, type) {
      console.log("NewReportRequest: ", service, "type: ", type);

      //hacky(?) way to put the modal on top of the page
      // TODO: use native teleport method of vue
      let overlay = document.createElement("div");
      try {
        overlay = dropoverid.value._value.$el;
      } catch {}
      document.body.appendChild(overlay);

      servicetype = type;
      let base = service;
      if (base == "eyegeo") {
        categories.value.forEach((e) => {
          if (e.key == "eyegeo") {
            e.selected = true;
            e.show = true;
          } else {
            e.selected = false;
            e.show = false;
          }
        });
        if (dropoverOpen.value == true) {
          dropoverOpen.value = false;
        }
        nextTick(() => (dropoverOpen.value = true));
        return;
      }
      if (type == "temp") {
        onReportRequest(service, servicetype);
        return;
      }
      if (type == "eyegeo") {
        let base = service[0].split(".")[0];
        categories.value.forEach((e) => {
          if ((e.key == service) | (e.key == base)) {
            e.show = true;
          } else {
            e.show = false;
          }
        });
      }
      if (type == "eyefuel") {
        let base = service[0].split(".")[0];
        categories.value.forEach((e) => {
          if ((e.key == service) | (e.key == base)) {
            e.show = true;
          } else {
            e.show = false;
          }
        });
      }
      if (type == "tandt") {
        let base = service[0].split(".")[0];
        categories.value.forEach((e) => {
          if ((e.key == service) | (e.key == base)) {
            e.show = true;
          } else {
            e.show = false;
          }
        });
      }
      if (type == "master") {
        categories.value.forEach((e) => {
          if (
            e.key == "eyefuel" ||
            e.key == "eyeway" ||
            e.key == "tandt" ||
            e.key == "eyegeo"
          ) {
            e.show = false;
          } else {
            e.show = true;
          }
        });
      }
      if (service) {
        categories.value.forEach((e) => {
          if (e.key == service) {
            e.selected = true;
          } else {
            e.selected = false;
          }
        });
      }
      if (dropoverOpen.value == true) {
        dropoverOpen.value = false;
      }
      nextTick(() => (dropoverOpen.value = true));
    }

    function getSensorName(sensor) {
      let name = sensor.split(".")[1];
      try {
        name = name.replace("_", " ");
      } catch {}
      return name;
    }

    function vehicleFleet(fleet_id) {
      const fleets = store.getters["fleets/collection"];
      let obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }

    async function get_dat() {
      let configs = [];
      categories.value.forEach(async (e) => {
        if (e.selected == true) {
          console.log("element:", e);
          let config = get_configuration(e.key);
          config.forEach((e) => {
            configs.push(e);
          });
        }
      });
      return configs;
    }

    async function generateReport() {
      dropoverOpen.value = false;
      let configs = [];
      let from = daterange.value[0];
      let to = daterange.value[1];

      if (categories.value.some((e) => e.selected == true)) {
        categories.value.forEach((e) => {
          if (e.selected == true) {
            let config = get_configuration(e.key);
            config.forEach((e) => {
              configs.push(e);
            });
          }
        });

        //onReportSave(configs.join(),  dayjs(daterange.value[0]).format(), dayjs(daterange.value[1]).format(), servicetype)

        store.dispatch("globalMap/requestReport", {
          vehicleId: vehicle.value.id,
          services: configs.join(),
          from: dayjs(from).format(),
          to: dayjs(to).format(),
          type: servicetype,
        });
        console.log(
          "Generate Report:",
          vehicle.value.id,
          configs,
          daterange.value[0],
          daterange.value[1],
          servicetype
        );
        EventBus.emit("notification:show", {
          type: "success",
          title: i18n.t("reports.notify.submitted"),
        });
      } else {
        EventBus.emit("notification:show", {
          type: "error",
          title: "Selezionare Almeno 1 Opzione",
        });
        return;
      }
    }

    //helpers & interactive
    async function onDaterangeChange() {
      console.log("Report onDaterangeChange");
      if (!props.active) return;
    }

    function init() {
      let dt_range = useDaterange({ onDaterangeChange });
      daterange.value = [dt_range.from.value, dt_range.to.value];
    }

    function servicearray() {
      actualservices.splice(0);
      services.value.forEach((e) => {
        if (e.key.startsWith("type_") | (e.active == false)) {
        } else {
          actualservices.push(e);
        }
      });
    }

    watchEffect(async () => {
      if (vehicle.value) {
        loadServices(vehicle.value);
        servicearray();
        dropoverOpen.value = false;
        servicetype = "";
        await nextTick();
        const serviceKey = props.service ?? "general";
        openedService.value = serviceKey;
      }
      if (openedService.value) {
        return openedService.value;
      }
    });

    init();

    return {
      daterange,
      dropoverOpen,
      tempsarray,
      reportSelectorOpened,
      dayjs,
      i18n,
      vehicle,
      services,
      openedService,
      actualservices,
      dropoverid,
      getSensorName,
      toggleTabs,
      setOpenedService,
      onReportRequest,
      newReportRequest,
      closePanel,
      closeDropover,
      pino: ref(5),
      report_options,
      report_enabled,
      report_type,
      categories,
      report_close,
      onReportSave,
      generateReport,
      vehicleFleet,
    };
  },
};
</script>

<style></style>
