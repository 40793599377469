<template>
  <div
    v-if="!dropped"
    class="select-none"
    :class="{
      'cursor-move': dragActive,
    }"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <div
      ref="placeholder"
      :class="{ box: !dragging, 'border-2 border-dashed': dragging }"
    >
      <div :class="{ 'opacity-0': dragging }">
        <slot />
      </div>
    </div>

    <teleport to="body">
      <div
        v-show="dragging"
        ref="draggable"
        class="box shadow-md fixed cursor-move select-none"
      >
        <slot />
      </div>
    </teleport>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    dragActive: {
      type: Boolean,
      default: true,
    },
    cardId: {
      type: [Number, String],
      required: true,
    },
    dropKey: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const startDrag = ref(false);
    const dragging = ref(false);
    const canDrop = ref(false);
    const dropped = ref(false);
    const placeholder = ref(null);
    const draggable = ref(null);
    const collidingDropzone = ref(null);

    let draggableWidth = 0;
    let draggableHeight = 0;

    onMounted(() => {
      const positionInfo = placeholder.value.getBoundingClientRect();
      draggableWidth = positionInfo.width;
      draggableHeight = positionInfo.height;
    });

    function handleMouseDown() {
      startDrag.value = props.dragActive;
    }

    document.addEventListener("mouseup", () => {
      if (dragging.value && canDrop.value) {
        EventBus.emit("draggable:drop", {
          dropUid: collidingDropzone.value.dataset.uniqid,
          cardId: props.cardId,
        });
        dropped.value = true;
      }
      EventBus.emit("draggable:cannot");
      startDrag.value = false;
      dragging.value = false;
      canDrop.value = false;
    });

    document.addEventListener("mousemove", (ev) => {
      if (startDrag.value) {
        dragging.value = true;
        startDrag.value = false;
      }
      if (dragging.value) {
        keepDragging(ev);
        checkDrop(ev);
      }
    });

    function keepDragging(ev) {
      const x = ev.x - draggableWidth / 2;
      const y = ev.y - draggableHeight / 2;
      draggable.value.style.left = x + "px";
      draggable.value.style.top = y + "px";
    }

    function checkDrop(ev) {
      let cdz = null;

      const dropzones = document.querySelectorAll(`.${props.dropKey}`);
      dropzones.forEach((dz) => {
        const colliding = checkCollision(ev.target, dz);
        if (colliding) cdz = dz;
      });

      if (cdz) {
        collidingDropzone.value = cdz;
        canDrop.value = true;
        EventBus.emit("draggable:can", {
          dropUid: cdz.dataset.uniqid,
          cardId: props.cardId,
        });
      } else {
        canDrop.value = false;
        EventBus.emit("draggable:cannot");
      }
    }

    function checkCollision(a, b) {
      const aPos = a.getBoundingClientRect();
      const bPos = b.getBoundingClientRect();
      return !(
        aPos.y + aPos.height < bPos.y ||
        aPos.y > bPos.y + bPos.height ||
        aPos.x + aPos.width < bPos.x ||
        aPos.x > bPos.x + bPos.width
      );
    }

    return { placeholder, draggable, dragging, dropped, handleMouseDown };
  },
};
</script>

<style></style>
