<template>
  <div>
    <div class="mb-2">
      <div class="font-bold text-lg">{{ name }}</div>
      <div class="text-sm text-gray-500">{{ description }}</div>
    </div>
    <div class="flex flex-col space-y-2">
      <select v-model="state" :disabled="sending" class="form-select">
        <option v-for="o in options" :key="o.key" :value="o.key">
          {{ o.label }}
        </option>
      </select>
      <button class="btn btn-primary" :disabled="sending" @click="trySend()">
        <span v-if="!sending">
          {{ i18n.t("general.send") }}
        </span>
        <span v-else class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import EventBus from "@/libs/event-bus";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    initialValue: {
      type: [String, Number, null],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    ask: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["send"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const sending = ref(false);
    watch(
      () => props.initialValue,
      (v) => {
        sending.value = false;
        state.value = v;
      }
    );

    const state = ref(props.initialValue);

    function trySend() {
      if (props.ask) {
        EventBus.emit("confirm-dialog:show", {
          title: i18n.t("services.eyecontrol.confirm-title"),
          text: i18n.t("services.eyecontrol.confirm-text"),
          doubleCheck: true,
          doubleCheckText: i18n.t("services.eyecontrol.confirm-toggle-text"),
          callback: async (confirm) => {
            if (confirm) send();
          },
        });
      } else {
        send();
      }
    }

    function send() {
      if (state.value == null) return;
      sending.value = true;
      emit("send", state.value);
    }

    return { i18n: useI18n(), state, trySend, send, sending };
  },
};
</script>

<style></style>
